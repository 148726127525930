<template>
    <div class="page-table haberTanim mainDiv scrollable" id="affix-container">
        <div class="animated fadeInUp card-base baslikBg">{{$t("src.views.apps.haberler.guncelleme.pageBaslik")}}
        </div>
        <el-row :gutter="10">
            <el-form status-icon :model="haberlerForm" :rules="rulesHaberler" ref="haberlerForm"
                     class="demo-ruleForm">
                <el-col :lg="18" :md="18" :sm="24" :xs="24">
                    <div class="card-base card-shadow--medium demo-box bg-white animated fadeInLeft">
                        <el-row>
                            <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                <el-form-item
                                        :label='$t("src.views.apps.haberler.tanimlama.baslik")'
                                        prop="baslik">:
                                    <el-input size="small"

                                              v-model="haberlerForm.baslik"
                                              :placeholder='$t("src.views.apps.haberler.tanimlama.baslikPlace")'></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                <el-form-item :label='$t("src.views.apps.haberler.tanimlama.icerik")'
                                              prop="icerik">:
                                    <ckeditor :editor="editor"
                                              :config="editorConfig"
                                              v-model="haberlerForm.icerik"
                                    ></ckeditor>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>
                    <el-collapse style="margin-top: 10px" v-model="activeCollapse">
                        <el-collapse-item class="card-shadow--medium" title="Resim Ayarları" name="3">
                            <resim :imageList="imageListMain" :modulName="'haber'" :link="haberlerForm.baslik" v-on:imageListMain="handleResim"></resim>
                        </el-collapse-item>
                        <el-collapse-item class="card-shadow--medium" title="Belge Ayarları" name="4">
                            <belge :fileList="fileListMain" :modulName="'haber'" v-on:fileListMain="handleBelge"></belge>
                        </el-collapse-item>
                    </el-collapse>
                </el-col>
                <el-col :lg="6" :md="6" :sm="24" :xs="24">
                    <el-collapse class="yanForm animated fadeInUp" v-model="activeCollapse">
                        <el-collapse-item class="card-shadow--medium" name="5"
                                          :title='$t("src.views.apps.haberler.tanimlama.durum")'>
                            <el-row>
                                <el-button
                                        size="mini" v-on:click="haberGuncelle('haberlerForm',haberlerForm.durum)"
                                        class="onayBtn"
                                        type="primary">
                                    <label v-if="haberlerForm.durum === '1'">
                                        Güncelle</label>
                                    <label v-else>Kaydet</label>
                                </el-button>
                                &nbsp;
                                <el-popover
                                        placement="bottom"
                                        width="300"
                                        v-model="visibleSil">
                                    <p>{{$t('src.views.apps.yonetici.tanimlama.formAlertP')}}</p>
                                    <div style="text-align: right; margin: 0">
                                        <el-button size="mini" type="primary" @click="resetForm('haberlerForm')">
                                            {{$t('src.views.apps.yonetici.tanimlama.evet')}}
                                        </el-button>
                                        <el-button size="mini" @click="visibleSil = false;">
                                            {{$t('src.views.apps.yonetici.tanimlama.hayir')}}
                                        </el-button>
                                    </div>
                                    <el-button size="mini" slot="reference">
                                        {{$t('src.views.apps.yonetici.tanimlama.temizle')}}
                                    </el-button>
                                </el-popover>
                                <el-tooltip content='Çöp Kutusuna At' :open-delay="500" placement="bottom">
                                    <el-button class="cop" v-on:click="haberGuncelle('haberlerForm','2')"
                                               type="text"
                                               icon="el-icon-delete"></el-button>
                                </el-tooltip>
                            </el-row>
                        </el-collapse-item>
                        <el-collapse-item class="card-shadow--medium"
                                          :title='$t("src.views.apps.haberler.tanimlama.kategori")' name="6">
                            <el-row>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item prop="kategori">
                                        <el-select
                                                size="small"

                                                v-model="haberlerForm.kategori"
                                                :placeholder='$t("src.views.apps.haberler.tanimlama.kategoriPlace")'
                                                style="width: 100%">
                                            <el-option v-for="item in kategoriListe"
                                                       :key="item.hkategoriId"
                                                       :label="item.baslik"
                                                       :value="item.hkategoriId"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <div style="font-style: italic">
                                        {{$t("src.views.apps.haberler.tanimlama.kategoriAciklama")}}
                                    </div>
                                </el-col>

                            </el-row>
                        </el-collapse-item>
                        <el-collapse-item class="card-shadow--medium"
                                          title='SMS' name="7">
                            <el-row>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item label="SMS" prop="gizlilik">
                                        <el-switch
                                                v-model="haberlerForm.sms"
                                                active-text="SMS At"
                                                inactive-text="SMS Atma"
                                                active-value="1"
                                                inactive-value="0">
                                        </el-switch>
                                    </el-form-item>
                                    <div style="font-style: italic">
                                        {{"* SMS; Eklenecek duyuru SMS olarak bilgilendirilsin mi?"}}
                                    </div>
                                </el-col>
                            </el-row>
                        </el-collapse-item>
                        <el-collapse-item class="card-shadow--medium"
                                          title='Gizlilik' name="8">
                            <el-row>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item label="Gizlilik" prop="gizlilik">
                                        <el-switch
                                                v-model="haberlerForm.gizlilik"
                                                active-text="Belirlenmiş Kişilere"
                                                inactive-text="Tüm Okula"
                                                active-value="1"
                                                inactive-value="0">
                                        </el-switch>
                                    </el-form-item>
                                    <div style="font-style: italic">
                                        {{"* Genel; Eklenecek duyuruyu herkes görebilir."}}
                                    </div>
                                    <div style="font-style: italic">
                                        {{"* Özel; Eklenecek duyuruyu yalnızca seçilen kullanıcılar görebilir."}}
                                    </div>
                                </el-col>

                            </el-row>
                        </el-collapse-item>
                        <el-collapse-item v-if="haberlerForm.gizlilik==='1'" class="card-shadow--medium"
                                          title='Kullanıcılar' name="9">
                            <el-row>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item prop="okulId">
                                        <el-select
                                                size="small"
                                                :disabled="okulKilit"
                                                v-on:change="getKullaniciTree"
                                                v-model="haberlerForm.okulId"
                                                placeholder='Okul Seçiniz'
                                                style="width: 100%">
                                            <el-option v-for="item in okulList"
                                                       :key="item.okulId"
                                                       :label="item.okulAdi"
                                                       :value="item.okulId"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>

                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item label="Kullanıcılar" prop="gizlilik">
                                        <el-input
                                                class="mb-10"
                                                size="small"
                                                placeholder="Kullanıcı Ara"
                                                v-model="filterText">
                                        </el-input>
                                        <el-tree class="filter-tree"
                                                 :data="data"
                                                 ref="tree"
                                                 :auto-expand-parent="false"
                                                 :check-strictly="true"
                                                 @check="handleNodeClick"
                                                 :filter-node-method="filterNode"
                                                 show-checkbox
                                                 node-key="id"
                                                 :props="defaultProps">
                                        </el-tree>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-collapse-item>
                    </el-collapse>
                </el-col>
            </el-form>
        </el-row>
    </div>
</template>

<script>
    import JQuery from 'jquery';
    import ClassicEditor from '../../../../node_modules/ckeditor5-build-classic';
    import EventBus from '@/components/event-bus'
    import Resim from '@/components/Resim.vue'
    import Belge from '@/components/Belge.vue'

    let $ = JQuery;
    import kullaniciService from '../../../WSProvider/KullaniciService'
    import haberService from '../../../WSProvider/HaberService'
    import haberKategoriService from '../../../WSProvider/HaberKategoriService'
    import notification from '../../../notification'
    import functions from '../../../functions'
    import okulService from "../../../WSProvider/OkulService";

    let base64 = require('base64_js');
    export default {
        name: "HaberlerDuzenle",
        components: {
            Resim,
            Belge,
        },
        watch: {

        },
        mounted() {
            if (localStorage.getItem("userDataBatiKoleji")) {
                this.userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
            }
            if (this.userDataBatiKoleji.okulId) {
                this.okulId = this.userDataBatiKoleji.okulId;
                this.okulKilit = true;
            } else {
                this.okulId = '';
                this.okulKilit = false;
            }
            this.getKategoriListe();
            this.getOkulList();
            this.updateHaberKodu = this.$store.getters.getUpdateKodu
            this.getHaberDetay(this.updateHaberKodu);
        },
        data() {
            return {
                path: haberService.path,
                visibleSil: false,
                activeCollapse: ['1', '2', '3', '4', '5', '6', '7', '8','9'],
                editor: ClassicEditor,
                editorConfig: {
                    language: 'tr'
                },
                data: [],
                filterText: '',
                defaultProps: {
                    children: 'children',
                    label: 'label'
                },
                checkedKeysKullanici: [],
                userDataBatiKoleji: [],
                orjinalResimList: [],
                orjinalFileList: [],
                imageListMain: [],
                fileListMain: [],
                duzenleBtnVisible: true,
                dialogServerVisible: false,
                haberDetayListKlon: [],
                updateData: [],
                kategoriListe: [],
                oldKullaniciList: [],
                updateDataKul: [],
                okulList: [],
                okulId: '',
                okulKilit: false,
                updateHaberKodu: '',
                haberlerForm: {
                    kategori: '',
                    baslik: '',
                    icerik: '',
                    resim: [],
                    belge: [],
                    durum: '1',
                    gizlilik: '0',
                    sms: '0',
                    kullanici: [],
                },
                dateRangeOptions: {
                    firstDayOfWeek: 1
                },
                rulesHaberler: {
                    baslik: [{
                        required: true,
                        message: "Lütfen Haber Başlığı Giriniz",
                        trigger: 'blur'
                    }],
                    seoBaslik: [{
                        max: 70,
                        message: "Tavsiye edilen SEO Başlık limitini aştınız.",
                        trigger: 'change'
                    }],
                    seoIcerik: [{
                        max: 160,
                        message: "Tavsiye edilen SEO İçerik limitini aştınız.",
                        trigger: 'change'
                    }],
                },
            }
        },
        methods: {
            handleNodeClick(data, checked, indeterminate) {
                if(checked.checkedKeys){
                    this.haberlerForm.kullanici = checked.checkedKeys;
                }
            },
            filterNode(value, data) {
                if (!value) return true;
                return data.label.toLowerCase().indexOf(value) !== -1;
            },
            handleResim(data) {
                this.imageListMain = data
            },
            handleBelge(data) {
                this.fileListMain = data
            },
            getOkulList() {
                okulService.okulListesi("1", "", "").then((response) => {
                    if (response.status === 200) {
                        this.okulList = response.data;
                        if (this.okulList.length > 0) {
                            if (this.okulId !== '') {
                                this.haberlerForm.okulId = this.okulId;
                                this.getKullaniciTree();
                            } else {
                                this.haberlerForm.okulId = response.data[0].okulId;
                            }
                        }
                    } else {
                        notification.Status(response.status, this)
                    }
                });
            },
            getKullaniciTree(val){
                if (!this.userDataBatiKoleji.tip) {
                    kullaniciService.kullaniciTree(val).then((response) => {
                        if (response.status === 200) {
                            this.data = response.data
                        } else {
                            notification.Status(response.status, this)
                        }
                    });
                } else {
                    kullaniciService.mudurkullaniciTree().then((response) => {
                        if (response.status === 200) {
                            this.data = response.data
                        } else {
                            notification.Status(response.status, this)
                        }
                    });
                }
            },
            getKategoriListe() {
                haberKategoriService.kategoriList(1).then(response => {
                    if (response.status === 200) {
                        if (response.data.length === 0) {
                            notification.Status('warning', this, 'Kategori listesi boş! Lütfen önce kategori tanımlayınız.');
                        } else {
                            this.kategoriListe = response.data;
                        }
                        if (response.data.length > 0) {
                            this.haberlerForm.kategori = response.data[0].hkategoriId;
                        }
                    } else {
                        notification.Status(response.status, this)
                    }
                })
            },
            getHaberDetay(haberKodu) {
                let self = this;
                if (!this.userDataBatiKoleji.tip) {
                    haberService.haberDetay2(haberKodu).then((response) => {
                        if (response.status === 200) {
                            if (response.data.anaResim) {
                                self.orjinalResimList.push(response.data.anaResim);
                                self.imageListMain.push({
                                    name: response.data.anaResim,
                                    url: haberService.imageUrlOrta + response.data.anaResim
                                });
                            }
                            if (response.data['resim']) {
                                response.data['resim'].forEach(function (e) {
                                    if (e) {
                                        self.orjinalResimList.push(e);
                                        self.imageListMain.push({
                                            name: e,
                                            url: haberService.imageUrlOrta + e
                                        });
                                    }
                                });
                            }
                            if (response.data['belge']) {
                                response.data['belge'].forEach(function (e) {
                                    if (e) {
                                        self.orjinalFileList.push(e);
                                        self.fileListMain.push({
                                            name: e.belgeAdi,
                                            url: haberService.imageUrlOrta + e.link
                                        });
                                    }
                                });
                            }
                            if (response.data['kullanici']) {
                                this.oldKullaniciList = response.data.kullanici;
                                response.data.kullanici.forEach(function (e) {
                                    self.haberlerForm.kullanici.push(e.kullaniciId);
                                    self.checkedKeysKullanici.push(e.kullaniciId)
                                });
                                this.$nextTick(() => {
                                    this.$refs.tree.setCheckedKeys(self.checkedKeysKullanici);
                                });
                            }

                            self.haberlerForm.kategori = response.data.kategoriId;
                            self.haberlerForm.baslik = response.data.baslik;
                            if (response.data.icerik) {
                                self.haberlerForm.icerik = functions.htmlDecode(response.data.icerik);
                            }
                            self.haberlerForm.durum = response.data.durum;
                            self.haberlerForm.sms = response.data.sms;
                            self.haberlerForm.gizlilik = response.data.gizlilik;
                            self.haberlerForm.durum = response.data.durum;
                            self.haberDetayListKlon = response.data;
                        } else {
                            notification.Status(response.status, this)
                        }
                    })
                } else {
                    haberService.haberDetayMudur(haberKodu).then((response) => {
                        if (response.status === 200) {
                            if (response.data.anaResim) {
                                self.orjinalResimList.push(response.data.anaResim);
                                self.imageListMain.push({
                                    name: response.data.anaResim,
                                    url: haberService.imageUrlOrta + response.data.anaResim
                                });
                            }
                            if (response.data['resim']) {
                                response.data['resim'].forEach(function (e) {
                                    if (e) {
                                        self.orjinalResimList.push(e);
                                        self.imageListMain.push({
                                            name: e,
                                            url: haberService.imageUrlOrta + e
                                        });
                                    }
                                });
                            }
                            if (response.data['belge']) {
                                response.data['belge'].forEach(function (e) {
                                    if (e) {
                                        self.orjinalFileList.push(e);
                                        self.fileListMain.push({
                                            name: e.belgeAdi,
                                            url: haberService.imageUrlOrta + e.link
                                        });
                                    }
                                });
                            }
                            if (response.data['kullanici']) {
                                this.oldKullaniciList = response.data.kullanici;
                                response.data.kullanici.forEach(function (e) {
                                    self.haberlerForm.kullanici.push(e.kullaniciId);
                                    self.checkedKeysKullanici.push(e.kullaniciId)
                                });
                                this.$nextTick(() => {
                                    this.$refs.tree.setCheckedKeys(self.checkedKeysKullanici);
                                });
                            }

                            self.haberlerForm.kategori = response.data.kategoriId;
                            self.haberlerForm.baslik = response.data.baslik;
                            if (response.data.icerik) {
                                self.haberlerForm.icerik = functions.htmlDecode(response.data.icerik);
                            }
                            self.haberlerForm.durum = response.data.durum;
                            self.haberlerForm.sms = response.data.sms;
                            self.haberlerForm.gizlilik = response.data.gizlilik;
                            self.haberlerForm.durum = response.data.durum;
                            self.haberDetayListKlon = response.data;
                        } else {
                            notification.Status(response.status, this)
                        }
                    })
                }
            },
            haberGuncelle(formName) {
                let self = this;
                for (var key in this.haberDetayListKlon) {
                    if (key !== 'resim' && key !== 'belge' && key !== 'kullanici') {
                        if (this.haberDetayListKlon[key] !== this.haberlerForm[key]) {
                            if (this.haberlerForm[key] || this.haberlerForm[key] === '') {
                                var item = {
                                    type: key,
                                    value: this.haberlerForm[key]
                                };
                                this.updateData.push(item);
                            }
                        }
                    } else if (key === "resim") {
                        if (self.imageListMain.length === 0) {
                            if (self.orjinalResimList.length > 0) {
                                self.orjinalResimList.forEach(function (e) {
                                    functions.updateListImage(self, 'resim', 0, e);
                                })
                            }
                        } else {
                            for (var i = 0; i < self.imageListMain.length; i++) {
                                if (i === 0) {
                                    if (self.imageListMain[0].name !== self.orjinalResimList[0]) {
                                        functions.updateListImage(self, 'resim', 2, self.imageListMain[0].name);
                                        let obj = self.imageListMain.find(data => data.name === self.orjinalResimList[0]);
                                        if (obj !== undefined) {
                                            functions.updateListImage(self, 'resim', 1, self.orjinalResimList[0]);
                                        }
                                    }
                                } else {
                                    let obj = self.orjinalResimList.find(data => data === self.imageListMain[i].name);
                                    if (obj === undefined) {
                                        functions.updateListImage(self, 'resim', 1, self.imageListMain[i].name);
                                    }
                                }
                            }
                            if (self.orjinalResimList.length > 0) {
                                for (var y = 1; y < self.orjinalResimList.length; y++) {
                                    let obj = self.imageListMain.find(data => data.name === self.orjinalResimList[y]);
                                    if (obj === undefined) {
                                        functions.updateListImage(self, 'resim', 0, self.orjinalResimList[y]);
                                    }
                                }
                            }
                        }
                    }
                     else if (key === "belge") {
                        if (self.fileListMain.length === 0) {
                            if (self.orjinalFileList.length > 0) {
                                self.orjinalFileList.forEach(function (e) {
                                    functions.updateListFile(self, 'belge', 0, e.link,e.belgeAdi);
                                })
                            }
                        } else {
                            for (var i = 0; i < self.fileListMain.length; i++) {
                                if (i === 0 && self.orjinalFileList[0]) {
                                    if (self.fileListMain[0].name !== self.orjinalFileList[0].belgeAdi) {
                                        functions.updateListFile(self, 'belge', 2, self.fileListMain[0].link, self.fileListMain[0].name);
                                        let obj = self.fileListMain.find(data => data.name === self.orjinalFileList[0].belgeAdi);
                                        if (obj !== undefined) {
                                            functions.updateListFile(self, 'belge', 1, self.orjinalFileList[0].link, self.orjinalFileList[0].belgeAdi);
                                        }
                                    }
                                } else {
                                    let obj = self.orjinalFileList.find(data => data.belgeAdi === self.fileListMain[i].name);
                                    if (obj === undefined) {
                                        functions.updateListFile(self, 'belge', 1, self.fileListMain[i].link,self.fileListMain[i].name);
                                    }
                                }
                            }
                            if (self.orjinalFileList.length > 0) {
                                for (var y = 1; y < self.orjinalFileList.length; y++) {
                                    let obj = self.fileListMain.find(data => data.name === self.orjinalFileList[y].belgeAdi);
                                    if (obj === undefined) {
                                        functions.updateListFile(self, 'belge', 0, self.orjinalFileList[y].link,self.orjinalFileList[y].belgeAdi);
                                    }
                                }
                            }
                        }
                    }else if(key === 'kullanici'){

                        for (var ii = 0; ii < self.haberlerForm.kullanici.length; ii++) {
                            let obj11 = self.oldKullaniciList.find(data => data.kullaniciId === self.haberlerForm.kullanici[ii]);
                            if (obj11 === undefined) {
                                self.updateDataKul.push({
                                    durum: 1,
                                    kullaniciId: self.haberlerForm.kullanici[ii],
                                });
                            }
                        }
                        for (var jj = 0; jj < this.oldKullaniciList.length; jj++) {
                            let obj00 = self.haberlerForm.kullanici.find(data => data === self.oldKullaniciList[jj].kullaniciId);
                            if (obj00 === undefined) {
                                self.updateDataKul.push({
                                    durum: 0,
                                    kullaniciId: self.oldKullaniciList[jj].kullaniciId,
                                })
                            }
                        }
                    }
                }
                // console.log(this.updateData)
                this.$refs[formName].validate((valid) => {
                    if (self.updateDataKul.length !== 0) {
                        if (!this.userDataBatiKoleji.tip) {
                            haberService.haberKullaniciGuncelle(self.updateHaberKodu, self.updateDataKul).then((response) => {
                                if (response.status === 200) {
                                    notification.Status('success', self, 'Kullanici başarıyla güncellendi.');
                                    this.resetForm(formName);
                                    EventBus.$emit("haberList",true)
                                    this.$router.push({name: 'haber-liste'})
                                } else {
                                    notification.Status(response.status, self);
                                }
                            })
                        } else {
                            haberService.mudurhaberKullaniciGuncelle(self.updateHaberKodu, self.updateDataKul).then((response) => {
                                if (response.status === 200) {
                                    notification.Status('success', self, 'Kullanici başarıyla güncellendi.');
                                    this.resetForm(formName);
                                    EventBus.$emit("haberList",true)
                                    this.$router.push({name: 'haber-liste'})
                                } else {
                                    notification.Status(response.status, self);
                                }
                            })
                        }
                    }
                    if (valid && this.updateData.length !== 0) {
                        if (!this.userDataBatiKoleji.tip) {
                            haberService.haberGuncelle(this.updateHaberKodu, this.updateData).then((response) => {
                                if (response.status === 200) {
                                    EventBus.$emit("haberList", true)
                                    this.resetForm(formName);
                                    notification.Status('success', this, 'Haber başarıyla güncellendi.');
                                    this.$router.push({name: 'haber-liste'})
                                } else if (response.status === 230) {
                                    notification.Status('warning', this, 'Bu bilgiler ile kayıtlı bir haber zaten mevcut');
                                } else {
                                    notification.Status(response.status, this)
                                }
                            });
                        } else {
                            haberService.mudurhaberGuncelle(this.updateHaberKodu, this.updateData).then((response) => {
                                if (response.status === 200) {
                                    EventBus.$emit("haberList", true)
                                    this.resetForm(formName);
                                    notification.Status('success', this, 'Haber başarıyla güncellendi.');
                                    this.$router.push({name: 'haber-liste'})
                                } else if (response.status === 230) {
                                    notification.Status('warning', this, 'Bu bilgiler ile kayıtlı bir haber zaten mevcut');
                                } else {
                                    notification.Status(response.status, this)
                                }
                            });
                        }
                    } else
                        notification.Status('warning', this, 'Değişiklik yapılmadı!');
                })
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.imageListMain = [];
                this.fileListMain = [];
            },

        }
    }
</script>
