import Okul from '../views/apps/okul/Okul.vue'
import OkulTanimla from '../views/apps/okul/OkulTanimla.vue'
import layouts from "../layout";


export default {
    path: '/okul',
    name: 'okul',
    component: Okul,
    meta: {
        auth: true,
        layout: layouts.navLeft
    },
    children: [
        {
            path: 'detail',
            name: 'okul-detail',
            component: OkulTanimla,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                //layout: layouts.navTop,
                searchable: true,
                title: 'Okul Detail',
                tags: ['okul', 'shop', 'okuls']
            }
        }
    ]
}
