<template>
    <div class="login-page">
        <el-row style="height: 100%;margin: 0">
            <el-col :lg="8" :md="8" :sm="24" :xs="24" style="height: 100%">
                <div class="box grow scrollable only-y align-vertical side-box box-right login1" style="height: 100%">
                    <div class="align-vertical-middle wrapper text-center">
                        <form v-if="!secure" @submit.prevent="login">
                            <img class="image-logo" src="../../../assets/images/avatar-2.jpg"
                                 style=" border-radius: 50%; margin-bottom: 20px;width: 120px" alt="logo-left"/>
                            <div style="width: 100%; display: flex; justify-content: center">
                                <float-label class="styled">
                                    <input type="username" name="username"
                                           placeholder="Kullanıcı Adı" v-model="username">
                                </float-label>
                            </div>
                            <div style="width: 100%; display: flex; justify-content: center">
                                <float-label v-if="!sifreUnuttum" class="styled animated fadeInRight">
                                    <input type="password" name="password" placeholder="Şifre"
                                           v-model="password">
                                </float-label>
                            </div>
                            <div class="flex justify-center margin-top-10">
                                <el-select class="loginSelect" size="small" v-model="tip" placeholder="Kullanıcı Türü" style="width: 100%; max-width: 300px">
                                    <el-option label="Yönetici" value="4"></el-option>
                                    <!--<el-option label="İdari Personel" value="3"></el-option>
                                    <el-option label="Öğretmen" value="1"></el-option>
                                    <el-option label="Veli" value="0"></el-option>-->
                                    <el-option label="Öğrenci" value="2"></el-option>
                                </el-select>
                            </div>


                            <!--<div style="width: 100%; display: flex; justify-content: center">-->
                                <!--<float-label v-if="sifreUnuttum" label="TC NO" class="styled">-->
                                    <!--<input v-mask="'###########'" placeholder="TC No"-->
                                           <!--v-model="tcNo">-->
                                <!--</float-label>-->
                            <!--</div>-->
                            <!--<div v-if="!sifreUnuttum" class="flex" style="justify-content: center">-->
                                <!--<div class="box grow animated fadeInRight">-->
                                    <!--<el-button type="text" v-on:click="sifreUnuttum = true">-->
                                        <!--{{$t('src.views.authentication.login.sifremiUnuttum')}}-->
                                    <!--</el-button>-->
                                <!--</div>-->
                            <!--</div>-->
                            <div class="flex text-center center pt-30 pb-10">
                                <el-button v-if="!sifreUnuttum" plain size="small" native-type="submit"
                                           class="login-btn pulse animated themed animated fadeInRight">
                                    Giriş Yap
                                </el-button>
                                <el-button v-if="sifreUnuttum" plain size="small" @click="sifremiUnuttum"
                                           class="login-btn pulse animated themed animated fadeInRight">
                                    {{$t('src.views.authentication.login.sifreYenile')}}
                                </el-button>
                            </div>
                            <div style="margin-top: 10px">
                                <el-button
                                        v-if="sifreUnuttum" v-on:click="sifreUnuttum=false"
                                        class="buttonPage left animated fadeInRight delay-1s"
                                        style="float: left; cursor: pointer" icon="mdi mdi-chevron-double-left" type="text"
                                        size="small">
                                </el-button>
                            </div>
                        </form>


                        <form v-if="secure" @submit.prevent="sifremiUnuttum">
                            <img class="image-logo" src="../../../assets/images/security.png"
                                 style="margin-bottom: 20px;width: 120px" alt="logo-left"/>
                            <p class="font-weight-600 font-size-12 pt-10 pb-10">Bilgilerinizi korumak bizim önceliğimizdir.
                                <br> Lütfen telefonunuza gönderilen güvenlik kodunu girerek hesabınızı onaylayın.</p>
                            <div v-if="visPinCode" style="display: flex; flex-direction: row; justify-content: center">
                                <v-otp-input
                                        ref="otpInput"
                                        input-classes="otp-input"
                                        separator="-"
                                        :num-inputs="6"
                                        :should-auto-focus="true"
                                        :is-input-num="true"
                                        @on-complete="handleOnComplete"
                                />
                            </div>
                            <div v-if="visPinCode" class="base-timer" style="display: flex; justify-content: center">
                                <svg class="base-timer__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                    <g class="base-timer__circle">
                                        <circle class="base-timer__path-elapsed" cx="50" cy="50" r="45"></circle>
                                        <path
                                                :stroke-dasharray="circleDasharray"
                                                class="base-timer__path-remaining"
                                                :class="remainingPathColor"
                                                d="M 50, 50
                                            m -45, 0
                                            a 45,45 0 1,0 90,0
                                            a 45,45 0 1,0 -90,0 "></path>
                                    </g>
                                </svg>
                                <span class="base-timer__label">{{ formattedTimeLeft }}</span>
                            </div>
                            <div v-if="!visPinCode" class="flex text-center center pt-30 pb-10">
                                <el-button plain size="small" @click="koduTekrarGonder"
                                           class="login-btn pulse animated themed animated fadeInRight">
                                    Kodu Tekrar Gönder
                                </el-button>
                            </div>
                        </form>
                    </div>
                </div>
            </el-col>
            <el-col :lg="16" :md="16" :sm="24" :xs="24" style="height: 100%">
                <div id="loginImg" class="box grow scrollable only-y align-vertical side-box box-left loginImg" style="height: 100%">
                    <div class="align-vertical-middle wrapper text-center">
                        <img class="image-logo" src="../../../assets/images/logo3.png" alt="logo-left"/>
                        <!--<h3 class="h-big">{{$t('src.panelfirma')}}</h3>-->
                        <p class="pv-30" style="color: #333333">{{$t('src.aciklama')}}</p>
                        <!--<h4 class="h-big">{{$t('src.firma')}}</h4>-->
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>


<script>
    import yonetimService from '../../../WSProvider/YoneticiService'
    import kullaniciService from '../../../WSProvider/KullaniciService'
    import notification from '../../../notification'
    import JQuery from 'jquery';

    let $ = JQuery;

    let base64 = require('base64_js');
    const FULL_DASH_ARRAY = 283;
    const WARNING_THRESHOLD = 30;
    const ALERT_THRESHOLD = 15;

    const COLOR_CODES = {
        info: {
            color: "green"
        },
        warning: {
            color: "orange",
            threshold: WARNING_THRESHOLD
        },
        alert: {
            color: "red",
            threshold: ALERT_THRESHOLD
        }
    };
    const TIME_LIMIT = 60;


    export default {
        name: 'Login',
        data() {
            return {
                sifreUnuttum: false,
                username: '',
                password: '',
                tip: '',
                tcNo: '',
                visPinCode: true,
                secure: false,
                secureCount: 1,
                userData: [],


                timePassed: 0,
                timerInterval: null
            }
        },
        computed: {
            circleDasharray() {
                return `${(this.timeFraction * FULL_DASH_ARRAY).toFixed(0)} 283`;
            },
            formattedTimeLeft() {
                let seconds = this.timeLeft;
                if (seconds < 10) {
                    seconds = `0${seconds}`;
                }
                return `${seconds}`;
            },

            timeLeft() {
                return TIME_LIMIT - this.timePassed;
            },
            timeFraction() {
                const rawTimeFraction = this.timeLeft / TIME_LIMIT;
                return rawTimeFraction - (1 / TIME_LIMIT) * (1 - rawTimeFraction);
            },

            remainingPathColor() {
                const {alert, warning, info} = COLOR_CODES;

                if (this.timeLeft <= alert.threshold) {
                    return alert.color;
                } else if (this.timeLeft <= warning.threshold) {
                    return warning.color;
                } else {
                    return info.color;
                }
            }
        },
        watch: {
            timeLeft(newValue) {
                if (newValue === 0) {
                    this.onTimesUp();
                }
            }
        },
        methods: {
            onTimesUp() {
                this.visPinCode = false;
                clearInterval(this.timerInterval);
            },

            startTimer() {
                this.timerInterval = setInterval(() => (this.timePassed += 1), 1000);
            },

            login() {

                if (this.tip !== '') {
                    if(this.tip === '4'){
                        yonetimService.girisYap(this.username, this.password).then((response) => {
                            if (response.status === 200) {
                                if (this.$store.getters.getSecure(response.data.yonetimId) < 3 || (new Date().getTime() - this.$store.getters.getSecureSonGiris(response.data.yonetimId)) >= 3600000) {
                                    this.secure = true;
                                    this.userData = response.data;
                                    this.startTimer();
                                } else notification.Status('warning', this, 'Hesaba girişiniz geçici süreliğine engellenmiştir.');

                            } else {
                                notification.Status(response.status, this)
                            }
                        });
                    }else{
                        kullaniciService.login(this.username, this.password,this.tip).then((response) => {
                            if (response.status === 200) {
                                var userDataBatiKoleji = base64.encode(encodeURI(JSON.stringify(response.data)));
                                var userName = base64.encode(encodeURI(JSON.stringify(response.data)));
                                localStorage.setItem("userDataBatiKoleji", userDataBatiKoleji);
                                localStorage.setItem("userName", userName);
                                this.$store.commit('setLogin');
                                this.$router.push('/');
                                this.$store.commit('changeNavVisible', true);
                                this.$store.commit('resSecure', response.data.yonetimId);

                            } else {
                                notification.Status(response.status, this)
                            }
                        });
                    }
                } else {
                    notification.Status('warning', this, 'Lütfen bir kullanıcı türü seçiniz');
                }


            },

            sifremiUnuttum() {
                yonetimService.sifremiUnuttum(this.username, this.tcNo).then((response) => {
                    if (response.status === 200) {
                        notification.Status('success', this, 'Yeni Şifreniz E-Posta Adresinize gönderilmiştir.');
                    } else {
                        notification.Status(response.status, this)
                    }
                });
            },
            koduTekrarGonder() {
                if (this.$store.getters.getSecure(this.userData.yonetimId) < 3) {
                    this.$store.commit('secure', this.userData.yonetimId);
                    this.dogrulama();
                } else if (new Date().getTime() - this.$store.getters.getSecureSonGiris(this.userData.yonetimId) >= 3600000) {
                    this.dogrulama();
                } else {
                    notification.Status('warning', this, 'Hesaba girişiniz geçici süreliğine engellenmiştir.');
                }


            },
            dogrulama() {
                yonetimService.dogrulamaTekrar(this.username, this.password).then((response) => {
                    if (response.status === 200) {
                        this.visPinCode = true;
                        clearInterval(this.timerInterval);
                        this.timePassed = 0;
                        this.timerInterval = null;
                        this.startTimer();
                    } else notification.Status(response.status, this)
                })
            },
            handleOnComplete(value) {
                let self = this;
                yonetimService.dogrulamaKontrol(this.username, this.password, value).then((response) => {
                    if (response.status === 200) {
                        var userDataBatiKoleji = base64.encode(encodeURI(JSON.stringify(response.data)));
                        var userName = base64.encode(encodeURI(JSON.stringify(response.data)));
                        localStorage.setItem("userDataBatiKoleji", userDataBatiKoleji);
                        localStorage.setItem("userName", userName);
                        self.$store.commit('setLogin');
                        self.$router.push('/');
                        this.$store.commit('changeNavVisible', true);
                        this.$store.commit('resSecure', response.data.yonetimId);
                    } else notification.Status(response.status, this)
                })
            },
        }
    }
</script>

<style lang="scss">
    @import '../../../assets/scss/_variables';

    .layout-container .container .main {
        //background: #fff;
    }


    .login-page {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 0 !important;

        .side-box {
            .wrapper {
                width: 100%;
                box-sizing: border-box;
                padding: 20px;
            }
        }

        .box-left {
            background-image: url('../../../assets/images/login3.jpg');
            background-size: cover;
            background-position: 50% 50%;

            .wrapper {
                .image-logo {
                    width: 30%;
                    /*max-width: 100px;*/
                    margin-top: 20px;
                }

            }
        }

        .box-right {
            background: white;
            color: var(--background-color);
        }

        .form-box {
            width: 100%;
            max-width: 340px;
            padding: 30px;
            box-sizing: border-box;
            margin: 30px 150px;
            //background: transparent;

            a {
                font-size: 14px;
                color: var(--color-accent);
                text-decoration: none;
                font-weight: 500;
            }

            .image-logo {
                width: 80px;
                margin: 0px auto;
                margin-bottom: 70px;
                display: block;
            }

            .login-btn {
                background: #ffffff;
                color: #4a596a;
                border-color: #4a596a;
                border-width: 2px;
                font-weight: bold;
                border-radius: 0;

                &:hover {
                    background: #ffffff;
                    border-color: #4a596a;
                    color: #4a596a;
                }
            }
        }
    }

    @media (max-width: 1200px) {
        .login-page {
            .box-left {
                .wrapper {
                    .h-big {
                        font-size: 50px;
                    }
                }
            }
        }
    }

    @media (max-width: 900px) {
        .login-page {
            .box-left {
                .wrapper {
                    .h-big {
                        font-size: 30px;
                    }
                }
            }
        }
    }

    @media (max-width: 768px) {
        .login-page {
            display: block;
            overflow: auto;

            .side-box {
                display: block;
            }
        }
        .loginImg {
            position: absolute;
            z-index: 100;
            height: 65%;
            transition: all 0.8s ease;
        }
        .login1 {
            transition: 1s;
            z-index: 1;
            display: block !important;
            position: relative;
            transform: translateY(0);
        }
        .login2 {
            position: absolute;
            width: 100%;
            top: 0;
            height: 500px;
        }
        .yukariCek {
            transform: translateY(-100%);
        }


    }

    @media (min-width: 768px) {
        .login1 {
            position: relative;
            z-index: 90;
            right: 0;
        }

        .login2 {
            position: relative;
            z-index: 90;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: 30%;
        }

        .loginImg {
            position: absolute;
            left: 33%;
            height: 100%;
            width: 67%;
            z-index: 100;
            transition: all 0.8s ease;
        }
    }


    .el-progress-circle {
        width: 30px !important;
        height: 30px !important;
    }
</style>

<style lang="scss">
    .otp-input {
        width: 25px;
        height: 25px;
        padding: 3px;
        margin: 0 5px;
        font-size: 20px;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        text-align: center;

        &.error {
            border: 1px solid red !important;
        }
    }

    .otp-input::-webkit-inner-spin-button,
    .otp-input::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
</style>

<style scoped lang="scss">
    .base-timer {
        position: relative;
        width: 100%;
        height: 60px;
        margin-top: 20px;

        &__svg {
            transform: scaleX(-1);
        }

        &__circle {
            fill: none;
            stroke: none;
        }

        &__path-elapsed {
            stroke-width: 7px;
            stroke: grey;
        }

        &__path-remaining {
            stroke-width: 7px;
            stroke-linecap: round;
            transform: rotate(90deg);
            transform-origin: center;
            transition: 1s linear all;
            fill-rule: nonzero;
            stroke: currentColor;

            &.green {
                color: rgb(65, 184, 131);
            }

            &.orange {
                color: orange;
            }

            &.red {
                color: red;
            }
        }

        &__label {
            position: absolute;
            width: 60px;
            height: 60px;
            top: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
        }
    }
</style>

<style lang="scss">
    .loginSelect .el-input__inner{
        background: #f9f9f9;

        &::placeholder {
            color: #606266;
        }

        &:focus {
            border-color: rgba(74, 89, 106, 0.7) !important;
        }
    }
    .loginSelect .is-focus .el-input__inner {
        border-color: rgba(74, 89, 106, 0.7) !important;
    }
</style>



