<template>
    <div>
        <div class="notification-box">
            <div class="notify" v-for="n in notifications" :key="n.haberId" v-on:click="haberDetay(n)">
                <div class="flex">
                    <div class="n-title box grow"><strong><i class="mdi mdi-calendar"></i> {{n.baslik}}</strong></div>
                    <div class="n-date">{{n.olusumTarihi}} <i class="mdi mdi-clock"></i></div>
                </div>
                <div class="n-desc">{{(n.icerik).replace(/<[^>]*>?/gm, '')}}</div>
            </div>
            <div class="see-all" v-on:click="$router.push({name:'haber-liste'})">Tümünü Gör</div>

        </div>
        <haber-detay v-if="visible" :id="haberId" :dialogvisible.sync="visible"></haber-detay>
    </div>
</template>

<script>
    import HaberDetay from '@/components/HaberDetay.vue'

    import haberService from '../WSProvider/HaberService'
    import functions from '../functions'
    var base64 = require('base64_js');

    export default {
        components: {
            HaberDetay
        },
        name: 'NotificationBox',
        data() {
            return {
                notifications: [],
                haberId: '',
                visible: false,
            }
        },
        mounted() {
            this.userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
            if (this.userDataBatiKoleji.tip) {
                this.getDuyuruList();
            }
        },
        methods: {
            getDuyuruList() {
                haberService.ozelHaberList(0, 3, 1).then((response) => {
                    if (response.status === 200) {
                        response.data.forEach(function (e) {
                            if (e.icerik !== '') {
                                e.icerik = functions.htmlDecode(e.icerik)
                            }
                        });
                        this.notifications = response.data;
                    }
                })
            },
            haberDetay(val) {
                this.haberId = val.haberId;
                this.visible = true;
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    @import '../assets/scss/_variables';

    .notification-box {
        color: $text-color;

        .notify {
            cursor: pointer;
            margin-bottom: 20px;
            max-height: 70px;
            overflow: hidden;
            position: relative;

            &::after {
                content: '';
                display: none;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 20px;
                box-shadow: 0px -20px 20px -10px $background-color inset;
            }

            .n-title {
                text-align: left;
                padding-right: 10px;
            }

            .n-desc {
                color: transparentize($text-color, 0.5);
            }

            &:hover {
                color: $text-color-accent;

                .n-desc {
                    color: transparentize($text-color-accent, 0.5);
                }
            }
        }

        .see-all {
            cursor: pointer;
            &:hover {
                color: $text-color-accent;
            }
        }
    }
</style>

