<template>
    <div v-loading="loading">
        <el-row>
            <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                <el-form-item :label='$t("src.views.apps.haberler.tanimlama.belge")'
                              prop="belge">:
                    <el-row>
                        <el-col :lg="12" :md="12" :sm="24" :xs="24">
                            <el-upload
                                    multiple
                                    class="upload-demo"
                                    action=""
                                    accept=".pdf,.doc,.docx,.xls,.xlsx"
                                    :file-list="fileListMain"
                                    :auto-upload="false"
                                    :on-change="fileUpload"
                                    :on-remove="fileRemove">
                                <el-button size="mini" style="width: 150px;"
                                           type="success">
                                    Dosya
                                    Yükle
                                </el-button>
                            </el-upload>
                            <!--<el-button style="margin-top: 15px;width: 150px;"-->
                            <!--type="primary"-->
                            <!--v-on:click="serverDocumentList"-->
                            <!--@click="dialogDocServerVisible = true; "-->
                            <!--size="mini">-->
                            <!--Sunucudan-->
                            <!--Seç-->
                            <!--</el-button>-->
                        </el-col>
                    </el-row>
                </el-form-item>
            </el-col>
        </el-row>
        <el-dialog
                title='Sunucudan Belge Seç'
                :visible.sync="dialogDocServerVisible"
                width="50%"
                append-to-body
                center>
            <el-upload
                    action="#"
                    accept=".pdf,.doc,.docx,.xls,.xlsx"
                    class="upload-demo"
                    :on-preview="fileUploadtoServer"
                    :auto-upload="false"
                    :file-list="fileListServer">
            </el-upload>
        </el-dialog>
    </div>
</template>

<script>
    import controllerService from '../WSProvider/ControllerService'

    import notification from '../notification'
    import functions from '../functions'

    export default {
        name: "Belge",
        props: ['modulName', 'fileList'],
        watch: {
            fileList: function (val) {
                this.fileListMain = val;
            }
        },
        data() {
            return {
                loading: false,
                fileListMain: [],
                fileListServer: [],
                dialogDocServerVisible: false,
            }
        },
        methods: {
            serverDocumentList() {
                var dizi = [];
                controllerService.sunucuBelge(this.modulName).then((response) => {
                    if (response.status === 200) {
                        response.data.forEach(function (e) {
                            var item = {
                                name: e,
                            };
                            dizi.push(item);
                        });
                        this.fileListServer = dizi;
                    }
                })
            },
            fileUploadtoServer(file) {
                let obj = this.fileListMain.find(data => data.name === file.name.slice(file.name.indexOf("-") + 1));
                if (obj === undefined) {
                    var item = {
                        name: file.name,
                        url: file.url,
                    };
                    this.fileListMain.push(item);
                    status.Status('success', this, file.name.slice(file.name.indexOf("-") + 1) + ' belgesi eklendi.');
                } else {
                    status.Status('warning', this, 'Bu belge zaten mevcut');
                }
            },
            fileRemove(file) {
                this.fileListMain.splice(this.fileListMain.indexOf(file), 1);
                this.$emit('fileListMain', this.fileListMain)
                console.log(this.fileListMain)
            },
            fileUpload(file, fileList) {
                let self = this;
                this.loading = true;
                var dizi = [];
                var type = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
                var typeExcel = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                if (file.raw["type"] === type || file.raw["type"] === typeExcel || file.raw["type"] === 'application/vnd.ms-excel' || file.raw["type"] === 'application/msword' || file.raw["type"] === 'application/pdf') {
                    var uzanti = (file.name.slice(file.name.lastIndexOf(".")));
                    var dosyaName = (file.name.split(file.name.slice(file.name.lastIndexOf(".")))[0]).substring(0, 60);
                    var imgname = functions.randomText(7) + '-' + functions.linkTemizle(dosyaName) + uzanti;
                    const files = file.raw;
                    if (files) {
                        const reader = new FileReader();
                        reader.onload = e => {
                            const data = e.target.result;
                            controllerService.belgeYukle(this.modulName, imgname, data).then((response) => {
                                if (response.status === 200) {
                                    this.fileListMain.push({
                                        link: response.data,
                                        belgeAdi: imgname.split("-")[2],
                                        name: imgname,
                                        url: file.url,
                                        id: file.raw.lastModified
                                    });

                                    this.$emit('fileListMain', this.fileListMain)
                                    this.loading = false;
                                } else notification.Status(response.status, this);
                            });
                        };
                        reader.readAsDataURL(files);
                    }
                } else {
                    fileList.pop();
                    status.Status('warning', this, 'Yalnızca ".pdf", ".xls", "xlsx", ".doc" ve ".docx" uzantılı görsel seçiniz!!');
                }
            },
        }
    }
</script>

<style scoped>

</style>