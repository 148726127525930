import WsProvider from './provider/WSProvider'

var base64 = require('base64_js');

var userDataBatiKoleji = localStorage.getItem("userDataBatiKoleji") ? JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji")))) : "";
var serviceName = "index";
var sinavService = {

    sinavTanimla(okulId, tipi,sinavKodu,baslik,tarih,sinif,sonuclar) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                yonetimId: userDataBatiKoleji["yonetimId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                okulId: okulId,
                tipi: tipi,
                sinavKodu: sinavKodu,
                baslik: baslik,
                tarih: tarih,
                sinif: sinif,
                sonuclar: sonuclar,
                serviceName : "sinav",
                methodName: "sinavTanimlama",
            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;
    },
    mudursinavTanimlama(tipi,sinavKodu,baslik,tarih,sinif,sonuclar) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                mudurId: userDataBatiKoleji["kullaniciId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                tipi: tipi,
                sinavKodu: sinavKodu,
                baslik: baslik,
                tarih: tarih,
                sinif: sinif,
                sonuclar: sonuclar,
                serviceName : "sinav",
                methodName: "mudursinavTanimlama",
            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;
    },
    sinavDetay(sinavId) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                yonetimId: userDataBatiKoleji["yonetimId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                sinavId: sinavId,
                serviceName : "sinav",
                methodName: "sinavDetay"
            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;
    },
    sonucDetay(id) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                yonetimId: userDataBatiKoleji["yonetimId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                id: id,
                serviceName : "sinav",
                methodName: "sonucDetay"
            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;
    },
    sonucArama(key) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                yonetimId: userDataBatiKoleji["yonetimId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                key: key,
                serviceName : "sinav",
                methodName: "sonucArama"
            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;
    },
    sinavList(durum,baslangic,limit) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                yonetimId: userDataBatiKoleji["yonetimId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                durum: durum,
                baslangic: baslangic,
                limit: limit,
                serviceName : "sinav",
                methodName: "sinavList"
            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;
    },
    mudursinavList(durum,baslangic,limit) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                mudurId: userDataBatiKoleji["kullaniciId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                durum: durum,
                baslangic: baslangic,
                limit: limit,
                serviceName : "sinav",
                methodName: "mudursinavList"
            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;
    },
    sinavOgrList(sinavId) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                yonetimId: userDataBatiKoleji["yonetimId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                sinavId: sinavId,
                serviceName : "sinav",
                methodName: "sinavOgrList"
            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;
    },
    sinavDurumGuncelle(sinavId,durum) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                yonetimId: userDataBatiKoleji["yonetimId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                sinavId: sinavId,
                durum: durum,
                serviceName : "sinav",
                methodName: "sinavDurumGuncelle"
            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;
    },
    mudursinavDurumGuncelleme(sinavId,durum) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                mudurId: userDataBatiKoleji["kullaniciId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                sinavId: sinavId,
                durum: durum,
                serviceName : "sinav",
                methodName: "mudursinavDurumGuncelleme"
            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;
    },
    sinavGuncelle(sinavId,updateList) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                yonetimId: userDataBatiKoleji["yonetimId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                sinavId: sinavId,
                updateList: updateList,
                serviceName : "sinav",
                methodName: "sinavGuncelle"
            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;
    },
    mudursinavGuncelleme(sinavId,updateList) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                mudurId: userDataBatiKoleji["kullaniciId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                sinavId: sinavId,
                updateList: updateList,
                serviceName : "sinav",
                methodName: "mudursinavGuncelleme"
            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;
    },
    sinavDerece(sinavId) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                yonetimId: userDataBatiKoleji["yonetimId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                sinavId: sinavId,
                serviceName : "sinav",
                methodName: "sinavDerece"
            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;
    },
    sinavDereceKullanici(sinavId) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                sinavId: sinavId,
                serviceName : "sinav",
                methodName: "sinavDereceKullanici"
            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;
    },
    sinavListKullanici(durum,baslangic,limit) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                durum: durum,
                baslangic: baslangic,
                limit: limit,
                serviceName : "sinav",
                methodName: "sinavListKullanici"
            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;
    },


};
export default sinavService



