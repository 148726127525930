import WsProvider from './provider/WSProvider'

var base64 = require('base64_js');

var userDataBatiKoleji = localStorage.getItem("userDataBatiKoleji") ? JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji")))) : "";
var serviceName = "okul";
var okulService = {

    okulTanimlama(okulAdi, telefon,adres,iframe) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                yonetimId: userDataBatiKoleji["yonetimId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                okulAdi: okulAdi,
                telefon: telefon,
                sabitTelefon: "",
                adres: adres,
                iframe: iframe,
                methodName: "okulTanimlama"
            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;
    },
    okulDetay(okulId) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                yonetimId: userDataBatiKoleji["yonetimId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                okulId: okulId,
                methodName: "okulDetay"
            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;
    },
    okulListesi(durum,baslangic,limit) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                yonetimId: userDataBatiKoleji["yonetimId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                durum: durum,
                baslangic: baslangic,
                limit: limit,
                methodName: "okulListesi"
            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;
    },
    okulDurumGuncelle(okulId,durum) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                yonetimId: userDataBatiKoleji["yonetimId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                okulId: okulId,
                durum: durum,
                methodName: "okulDurumGuncelle"
            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;
    },
    okulCount(durum) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                yonetimId: userDataBatiKoleji["yonetimId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                durum: durum,
                methodName: "okulCount"
            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;
    },
    okulGuncelleme(okulId,okulAdi, telefon,adres,iframe) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                yonetimId: userDataBatiKoleji["yonetimId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                okulId: okulId,
                okulAdi: okulAdi,
                telefon: telefon,
                sabitTelefon: "",
                adres: adres,
                iframe: iframe,
                methodName: "okulGuncelle"
            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;
    },

};
export default okulService



