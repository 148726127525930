import WsProvider from './provider/WSProvider'

var base64 = require('base64_js');
var serviceName = "index";
var crypto = require('crypto');

var kullaniciService = {
    login(kullaniciAdi, sifre, tip) {
        // var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                firmaKodu: "HBT0032",
                kullaniciAdi: kullaniciAdi,
                tip: tip,
                sifre: crypto.createHash('md5').update(sifre).digest("hex"),
                serviceName: "kullanici",
                methodName: "login"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    sifremiUnuttum(mail) {
        var param = {
            data: {
                mail: mail,
                serviceName: "kullanici",
                methodName: "sifremiUnuttum"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    sifreGuncelle(eskiSifre, yeniSifre) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                yonetimId: userDataBatiKoleji["yonetimId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                eskiSifre: eskiSifre,
                yeniSifre: yeniSifre,
                serviceName: "kullanici",
                methodName: "sifreGuncelle"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    profilGuncelle(updateList) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                yonetimId: userDataBatiKoleji["yonetimId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                updateList: updateList,
                serviceName: "kullanici",
                methodName: "profilGuncelle"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },


    kullaniciTanimlama(okulId, ustKullaniciId, tip, tcNo, ogrNo, sinif, adSoyad, telefon, mail, sifre) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                yonetimId: userDataBatiKoleji["yonetimId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                tur: userDataBatiKoleji["tur"],
                okulId: okulId,
                ustKullaniciId: ustKullaniciId,
                tip: tip,
                tcNo: tcNo,
                ogrNo: ogrNo,
                sinif: sinif,
                adSoyad: adSoyad,
                telefon: telefon,
                mail: mail,
                sifre: sifre,
                serviceName: "kullanici",
                methodName: "kullaniciTanimlama"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    mudurkullaniciTanimlama(ustKullaniciId, tip, tcNo, ogrNo, sinif, adSoyad, telefon, mail, sifre) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                mudurId: userDataBatiKoleji["kullaniciId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                tur: userDataBatiKoleji["tur"],
                ustKullaniciId: ustKullaniciId,
                tip: tip,
                tcNo: tcNo,
                ogrNo: ogrNo,
                sinif: sinif,
                adSoyad: adSoyad,
                telefon: telefon,
                mail: mail,
                sifre: sifre,
                serviceName: "kullanici",
                methodName: "mudurkullaniciTanimlama"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    kullaniciListesi(durum, baslangic, limit, okulId) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                yonetimId: userDataBatiKoleji["yonetimId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                durum: durum,
                baslangic: baslangic,
                limit: limit,
                okulId: okulId,
                serviceName: "kullanici",
                methodName: "kullaniciList"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    mudurkullaniciList(durum, baslangic, limit) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                mudurId: userDataBatiKoleji["kullaniciId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                durum: durum,
                baslangic: baslangic,
                limit: limit,
                serviceName: "kullanici",
                methodName: "mudurkullaniciList"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    kullaniciTipListesi(durum, baslangic, limit, tip) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                yonetimId: userDataBatiKoleji["yonetimId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                durum: durum,
                baslangic: baslangic,
                limit: limit,
                tip: tip,
                serviceName: "kullanici",
                methodName: "kullaniciTipList"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    mudurkullaniciTipList(durum, baslangic, limit, tip) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                mudurId: userDataBatiKoleji["kullaniciId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                durum: durum,
                baslangic: baslangic,
                limit: limit,
                tip: tip,
                serviceName: "kullanici",
                methodName: "mudurkullaniciTipList"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    kullaniciDetay(kullaniciId) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                yonetimId: userDataBatiKoleji["yonetimId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                kullaniciId: kullaniciId,
                serviceName: "kullanici",
                methodName: "kullaniciDetay"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    kullaniciDurumGuncelleme(kullaniciId, durum) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                yonetimId: userDataBatiKoleji["yonetimId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                kullaniciId: kullaniciId,
                durum: durum,
                serviceName: "kullanici",
                methodName: "kullaniciDurumGuncelle"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    mudurkullaniciDurumGuncelleme(kullaniciId, durum) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                mudurId: userDataBatiKoleji["kullaniciId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                kullaniciId: kullaniciId,
                durum: durum,
                serviceName: "kullanici",
                methodName: "mudurkullaniciDurumGuncelleme"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    kullaniciCount() {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                yonetimId: userDataBatiKoleji["yonetimId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                serviceName: "kullanici",
                methodName: "kullaniciCount"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    mudurkullaniciCount() {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                mudurId: userDataBatiKoleji["kullaniciId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                serviceName: "kullanici",
                methodName: "mudurkullaniciCount"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    kullaniciGuncelleme(kullaniciId, updateList) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                yonetimId: userDataBatiKoleji["yonetimId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                kullaniciId: kullaniciId,
                updateList: updateList,
                serviceName: "kullanici",
                methodName: "kullaniciGuncelle"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    mudurkullaniciGuncelle(kullaniciId, updateList) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                mudurId: userDataBatiKoleji["kullaniciId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                kullaniciId: kullaniciId,
                updateList: updateList,
                serviceName: "kullanici",
                methodName: "mudurkullaniciGuncelle"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    kullaniciArama(arama, okulId) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                yonetimId: userDataBatiKoleji["yonetimId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                arama: arama,
                okulId: okulId,
                serviceName: "kullanici",
                methodName: "kullaniciArama"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    kullaniciSearch(tip,key) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                yonetimId: userDataBatiKoleji["yonetimId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                serviceName: "kullanici",
                tip: tip,
                key: key,
                methodName: "kullaniciSearch"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    mudurkullaniciSearch(tip,key) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                mudurId: userDataBatiKoleji["kullaniciId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                serviceName: "kullanici",
                tip: tip,
                key: key,
                methodName: "mudurkullaniciSearch"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },


    takvimTanimlama(okulId, kullaniciId, baslik, tarih) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                yonetimId: userDataBatiKoleji["yonetimId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                okulId: okulId,
                kullaniciId: kullaniciId,
                baslik: baslik,
                tarih: tarih,
                serviceName: "kullanici",
                methodName: "takvimTanimlama"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    takvimDetay(kullaniciId) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                yonetimId: userDataBatiKoleji["yonetimId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                kullaniciId: kullaniciId,
                serviceName: "kullanici",
                methodName: "takvimDetay"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    kullaniciTree(okulId) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                yonetimId: userDataBatiKoleji["yonetimId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                okulId: okulId,
                serviceName: "kullanici",
                methodName: "kullaniciTree"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    mudurkullaniciTree() {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                okulId: userDataBatiKoleji["okulId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                serviceName: "kullanici",
                methodName: "mudurkullaniciTree"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },

    kullaniciCountTip() {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                yonetimId: userDataBatiKoleji["yonetimId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                serviceName: "kullanici",
                methodName: "kullaniciCountTip"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },

    kullaniciCountOkul() {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                yonetimId: userDataBatiKoleji["yonetimId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                serviceName: "kullanici",
                methodName: "kullaniciCountOkul"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },

    smsGonder(telefon, icerik) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                yonetimId: userDataBatiKoleji["yonetimId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                telefon: telefon,
                icerik: icerik,
                serviceName: "kullanici",
                methodName: "smsGonder"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },

    veliOgrenciList(kullaniciId) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                kullaniciId: kullaniciId,
                serviceName: "kullanici",
                methodName: "veliOgrenciList"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },

    ogrenciVeliList(kullaniciId) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                kullaniciId: kullaniciId,
                serviceName: "kullanici",
                methodName: "ogrenciVeliList"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },

    ogrenciSinavList(kullaniciId) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                kullaniciId: kullaniciId,
                serviceName: "kullanici",
                methodName: "ogrenciSinavList"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },

    ogrenciSinavDetay(kullaniciId, sinavId) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                kullaniciId: kullaniciId,
                sinavId: sinavId,
                serviceName: "kullanici",
                methodName: "ogrenciSinavDetay"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;
    },

    okulOgrenciList(okulId, baslangic, limit) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                okulId: okulId,
                baslangic: baslangic,
                limit: limit,
                serviceName: "kullanici",
                methodName: "okulOgrenciList"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },

    mudurOgretmenList(baslangic, limit) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                mudurId: userDataBatiKoleji["kullaniciId"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                baslangic: baslangic,
                limit: limit,
                serviceName: "kullanici",
                methodName: "mudurOgretmenList"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },

    okulOgrenciSearch(okulId, key) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                okulId: okulId,
                key: key,
                serviceName: "kullanici",
                methodName: "okulOgrenciSearch"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },

    sifreOlustur(kullaniciId,sifre) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                yonetimId: userDataBatiKoleji["yonetimId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                kullaniciId: kullaniciId,
                sifre: sifre,
                serviceName: "kullanici",
                methodName: "sifreGuncelle"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },

}

export default kullaniciService

