import Sinav from '../views/apps/sinav/Sinav.vue'
import SinavTanimla from '../views/apps/sinav/SinavTanimla.vue'
import layouts from "../layout";
import OgrenciSinavList from "../views/apps/sinav/SinavOgrenciListesi.vue";


export default {
    path: '/sinav',
    name: 'sinav',
    component: Sinav,
    meta: {
        auth: true,
        layout: layouts.navLeft
    },
    children: [
        {
            path: 'detail',
            name: 'sinav-detail',
            component: SinavTanimla,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                //layout: layouts.navTop,
                searchable: true,
                title: 'Okul Detail',
                tags: ['sinav', 'shop', 'sinavs']
            }
        },
        {
            path: 'ogrenciler',
            name: 'ogrenci-sinav-list',
            component: OgrenciSinavList,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                //layout: layouts.navTop,
                searchable: true,
                title: 'Sınav Öğrenci Listesi',
                tags: ['sinav', 'ogrenci', 'liste']
            }
        },
    ]
}
