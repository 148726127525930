import WsProvider from './provider/WSProvider'

var base64 = require('base64_js');
var serviceName = "index";
var haberService = {
    kategoriTanimlama(baslik) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                yonetimId: userDataBatiKoleji["yonetimId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                baslik: baslik,
                serviceName: "haber",
                methodName: "kategoriTanimlama"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    mudurkategoriTanimlama(baslik) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                mudurId: userDataBatiKoleji["kullaniciId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                baslik: baslik,
                serviceName: "haber",
                methodName: "mudurkategoriTanimlama"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    kategoriMenuList() {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                yonetimId: userDataBatiKoleji["yonetimId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                serviceName: "haber",
                methodName: "kategoriMenuList"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    kategoriList(durum) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                yonetimId: userDataBatiKoleji["yonetimId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                durum: durum,
                serviceName: "haber",
                methodName: "kategoriList"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    mudurkategoriList(durum) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                mudurId: userDataBatiKoleji["kullaniciId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                durum: durum,
                serviceName: "haber",
                methodName: "mudurkategoriList"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    kategoriKaldir(kategoriId) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                yonetimId: userDataBatiKoleji["yonetimId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                kategoriId: kategoriId,
                serviceName: "haber",
                methodName: "kategoriKaldir"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    kategoriGuncelle(kategoriId, baslik) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                yonetimId: userDataBatiKoleji["yonetimId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                kategoriId: kategoriId,
                baslik: baslik,
                serviceName: "haber",
                methodName: "kategoriGuncelle"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    mudurkategoriGuncelle(kategoriId, baslik) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                mudurId: userDataBatiKoleji["kullaniciId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                kategoriId: kategoriId,
                baslik: baslik,
                serviceName: "haber",
                methodName: "mudurkategoriGuncelle"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    kategoriDurumGuncelle(kategoriId, durum) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                yonetimId: userDataBatiKoleji["yonetimId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                kategoriId: kategoriId,
                durum: durum,
                serviceName: "haber",
                methodName: "kategoriDurumGuncelle"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    mudurkategoriDurumGuncelle(kategoriId, durum) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                mudurId: userDataBatiKoleji["kullaniciId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                kategoriId: kategoriId,
                durum: durum,
                serviceName: "haber",
                methodName: "mudurkategoriDurumGuncelle"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },


}

export default haberService

