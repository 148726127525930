import Kategori from '../views/apps/haber/kategori/Kategori.vue'
import KategoriTanimlama from '../views/apps/haber/kategori/KategoriTanimlama.vue'
import layouts from "../layout";


export default {
    path: '/haber/kategori',
    name: 'haberkategori',
    component: Kategori,
    meta: {
        auth: true,
        layout: layouts.navLeft
    },
    children: [
        {
            path: 'detail',
            name: 'kategori-detail',
            component: KategoriTanimlama,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                //layout: layouts.navTop,
                searchable: true,
                title: 'Kategori Detail',
                tags: ['haber', 'shop', 'habers']
            }
        }
    ]
}
