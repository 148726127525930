import Kullanici from '../views/apps/kullanici/Kullanici.vue'
import KullaniciTanimla from '../views/apps/kullanici/KullaniciTanimla.vue'
import KullaniciSinavlar from '../views/apps/kullanici/KullaniciSinavListesi.vue'
import layouts from "../layout";


export default {
    path: '/kullanici',
    name: 'kullanici',
    component: Kullanici,
    meta: {
        auth: true,
        layout: layouts.navLeft
    },
    children: [
        {
            path: 'detail',
            name: 'kullanici-detail',
            component: KullaniciTanimla,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                //layout: layouts.navTop,
                searchable: true,
                title: 'Okul Detail',
                tags: ['kullanici', 'shop', 'kullanicis']
            }
        },
        {
            path: 'sinav',
            name: 'kullanici-sinavlar',
            component: KullaniciSinavlar,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                //layout: layouts.navTop,
                searchable: true,
                title: 'Kullanıcı Sınavları',
                tags: ['kullanici', 'shop', 'kullanicis']
            }
        }
    ]
}
