<template>
    <div class="page-table mainDiv scrollable only-y" id="affix-container">
        <div class="card-base baslikBg animated fadeInUp">
            {{$t("src.views.apps.haberler.kategori.tanimlama.mainBaslik")}}
        </div>
        <el-row :gutter="10">
            <el-col :lg="18" :md="18" :sm="24" :xs="24">
                <div class="page-header card-base card-shadow--medium animated fadeInUp">
                    <el-row class="mt-0 mb-10">
                        <el-col :lg="16" :md="16" :sm="24" :xs="24">
                            <div>
                                <el-radio-group size="small" v-on:change="handleChange($event)" v-model="radio" fill="#E5F5F9"
                                                text-color="gray">
                                    <el-radio-button label="1">
                                        <i class="mdi mdi-page-next mdi-18px" style="color: #13ce66; margin-right: 5px"></i>
                                        <transition
                                                name="slide-fade">
                                            <label v-if="selectIcon==='1'">Yayındaki Kategoriler</label>
                                        </transition>
                                    </el-radio-button>
                                    <el-radio-button label="0">
                                        <i class="mdi mdi-page-next mdi-18px" style="color: #ec205f; margin-right: 5px"></i>
                                        <transition
                                                name="slide-fade">
                                            <label v-if="selectIcon==='0'">Pasif Kategoriler</label>
                                        </transition>
                                    </el-radio-button>
                                </el-radio-group>
                            </div>
                        </el-col>
                        <el-col :lg="8" :md="8" :sm="24" :xs="24">
                            <el-col :lg="24" :md="24" :sm="24" :xs="24">
                                <ul class="islemler">
                                    <li class="duzenle">
                                        <i class="mdi mdi-pen"></i> Düzenle
                                    </li>
                                    <li v-if="selectIcon === '1'" class="sil">
                                        <i class="mdi mdi-trash-can"></i> Sil
                                    </li>
                                    <li v-else class="aktif">
                                        <i class="mdi mdi-play"></i> Yayına Al
                                    </li>
                                </ul>
                            </el-col>
                        </el-col>
                    </el-row>
                    <el-table
                            border
                            stripe
                            :data="kategoriListe"
                            style="width: 100%">
                        <el-table-column
                                label="Sıra"
                                type="index"
                                width="50">
                        </el-table-column>
                        <el-table-column
                                prop="baslik"
                                label="Başlık"
                                width="auto">
                        </el-table-column>
                        <el-table-column
                                label="İşlem"
                                align="right"
                                width="auto">
                            <template slot-scope="scope">
                                <el-tooltip :content='$t("src.views.apps.haberler.kategori.liste.duzenle")'
                                            :open-delay="500"
                                            placement="bottom">
                                    <el-button
                                            v-on:click="selectionIndex=scope.$index; selection=scope.row; updateStart(scope.row); "
                                            type="text"
                                            class="buttonEdit"
                                            icon="mdi mdi-pen mdi-24px">
                                    </el-button>
                                </el-tooltip>
                                <el-tooltip :content='$t("src.views.apps.haberler.kategori.liste.sil")'
                                            :open-delay="500"
                                            placement="bottom">
                                    <el-button
                                            v-if="selectIcon==='1'"
                                            v-on:click="dialogVisible = true; selection=scope.row; selectionIndex=scope.$index"
                                            type="text"
                                            class="buttonDel"
                                            icon="mdi mdi-trash-can mdi-24px">
                                    </el-button>
                                </el-tooltip>
                                <el-tooltip :content='$t("src.views.apps.sayfa.liste.yayindanKaldir")' :open-delay="500"
                                            placement="bottom">
                                    <el-button
                                            v-if="selectIcon==='0' "
                                            @click="kategoriDurumChange(scope.row,scope.$index,kategoriListe,1)"
                                            type="text"
                                            icon="mdi mdi-play mdi-24px">
                                    </el-button>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-col>
            <el-col :lg="6" :md="6" :sm="24" :xs="24">
                <div class="card-base card-shadow--medium demo-box bg-white animated fadeInUp">
                    <el-form status-icon :model="kategoriForm" :rules="rulesKategori" ref="kategoriForm"
                             class="demo-ruleForm">
                        <div>
                            <el-row>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item
                                            :label='$t("src.views.apps.haberler.tanimlama.baslik")'
                                            prop="baslik">:
                                        <el-input size="small"
                                                  v-model="kategoriForm.baslik"
                                                  :placeholder='$t("src.views.apps.haberler.tanimlama.baslikPlace")'></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </div>
                        <el-row class="col-p">
                            <el-form-item>
                                <el-button v-if="btnVisible" size="small" v-on:click="kategoriTanimlama('kategoriForm')"
                                           type="primary">
                                    {{$t('src.views.apps.haberler.kategori.tanimlama.kategoriEkle')}}
                                </el-button>
                                <el-button v-else size="small" v-on:click="guncelleme('kategoriForm')" type="primary">
                                    {{$t('src.views.apps.haberler.kategori.tanimlama.kategoriGuncelle')}}
                                </el-button>
                                <el-button v-if="!btnVisible" size="small" v-on:click="vazgec('kategoriForm')">
                                    Vazgeç
                                </el-button>
                            </el-form-item>
                        </el-row>
                    </el-form>
                </div>
            </el-col>
        </el-row>
        <el-dialog
                :title='$t("src.views.apps.haberler.liste.uyari")'
                :visible.sync="dialogVisible"
                width="30%"
                center>
            <span>"{{selection["baslik"]}}" {{$t('src.views.apps.haberler.kategori.tanimlama.sil')}}</span>
            <span slot="footer" class="dialog-footer">
                    <el-button icon="el-icon-check"
                               type="success"
                               size="mini"
                               @click="kategoriDurumChange(selection,selectionIndex,kategoriListe,0), dialogVisible = false"></el-button>
                    <el-button size="mini" type="danger" icon="el-icon-close"
                               @click="dialogVisible = false"></el-button>
                </span>
        </el-dialog>
    </div>
</template>

<script>
    import haberKategoriService from '../../../../WSProvider/HaberKategoriService'
    import haberService from '../../../../WSProvider/HaberService'
    import controllerService from '../../../../WSProvider/ControllerService'
    // import seoService from '../../../../WSProvider/SeoService'
    import notification from '../../../../notification'
    let base64 = require('base64_js');
    export default {
        name: "KategoriTanimlama",
        mounted() {
            if (localStorage.getItem("userDataBatiKoleji")) {
                this.userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
            }
            this.getKategoriList();
        },
        data() {
            return {
                userDataBatiKoleji : '',
                sayfaTipi : '',
                editable : [],
                selectIcon: '1',
                radio: '1',
                kategoriListe: [],
                path: haberService.path,
                updateKodu: '',
                btnVisible: true,
                selection: '',
                selectionIndex: '',
                dialogVisible: false,
                duzenleBtnVisible: true,
                kategoriForm: {
                    baslik: '',
                    link: '',
                    menu: '0',
                },
                rulesKategori: {
                    baslik: [{
                        required: true,
                        message: "Lütfen Başlık Giriniz",
                        trigger: 'blur'
                    }],
                },
            }
        },
        methods: {
            handleChange(event) {
                this.selectIcon = event;
                this.getKategoriList();
            },
            getKategoriList() {
                let self = this;
                if (!this.userDataBatiKoleji.tip) {
                    haberKategoriService.kategoriList(this.selectIcon).then((response) => {
                        if (response.status === 200) {
                            this.kategoriListe = response.data;
                        } else {
                            notification.Status(response.status, this)
                        }
                    });
                } else {
                    haberKategoriService.mudurkategoriList(this.selectIcon).then((response) => {
                        if (response.status === 200) {
                            this.kategoriListe = response.data;
                        } else {
                            notification.Status(response.status, this)
                        }
                    });
                }
            },
            updateStart(scope) {
                this.kategoriForm.baslik = scope.baslik;
                this.updateKodu = scope.hkategoriId;
                this.btnVisible = false;
            },
            vazgec(formName) {
                this.$refs[formName].resetFields();
                this.btnVisible = true;
            },
            kategoriTanimlama(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (!this.userDataBatiKoleji.tip) {
                            haberKategoriService.kategoriTanimlama(this.kategoriForm.baslik).then((response) => {
                                if (response.status === 200) {
                                    this.kategoriListe.push(response.data);
                                    notification.Status('success', this, 'Kategori başarıyla eklendi.');
                                    this.$refs[formName].resetFields();
                                } else if (response.status === 230) {
                                    notification.Status('warning', this, 'Bu bilgiler ile kayıtlı bir kategori zaten mevcut');
                                } else {
                                    notification.Status(response.status, this)
                                }
                            });
                        } else {
                            haberKategoriService.mudurkategoriTanimlama(this.kategoriForm.baslik).then((response) => {
                                if (response.status === 200) {
                                    this.kategoriListe.push(response.data);
                                    notification.Status('success', this, 'Kategori başarıyla eklendi.');
                                    this.$refs[formName].resetFields();
                                } else if (response.status === 230) {
                                    notification.Status('warning', this, 'Bu bilgiler ile kayıtlı bir kategori zaten mevcut');
                                } else {
                                    notification.Status(response.status, this)
                                }
                            });
                        }
                    }
                })
            },
            guncelleme(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (!this.userDataBatiKoleji.tip) {
                            haberKategoriService.kategoriGuncelle(this.updateKodu, this.kategoriForm.baslik).then((response) => {
                                if (response.status === 200) {
                                    this.kategoriListe.splice(this.selectionIndex, 1);
                                    this.kategoriListe.splice(this.selectionIndex, 0, response.data);
                                    notification.Status('success', this, 'Kategori başarıyla güncellendi...');
                                    this.$refs[formName].resetFields();
                                    this.btnVisible = true;
                                } else if (response.status === 200) {
                                    notification.Status('warning', this, 'Bu bilgiler ile kayıtlı bir kategori zaten mevcut');
                                } else {
                                    notification.Status(response.status, this)
                                }
                            });
                        } else {
                            haberKategoriService.mudurkategoriGuncelle(this.updateKodu, this.kategoriForm.baslik).then((response) => {
                                if (response.status === 200) {
                                    this.kategoriListe.splice(this.selectionIndex, 1);
                                    this.kategoriListe.splice(this.selectionIndex, 0, response.data);
                                    notification.Status('success', this, 'Kategori başarıyla güncellendi...');
                                    this.$refs[formName].resetFields();
                                    this.btnVisible = true;
                                } else if (response.status === 200) {
                                    notification.Status('warning', this, 'Bu bilgiler ile kayıtlı bir kategori zaten mevcut');
                                } else {
                                    notification.Status(response.status, this)
                                }
                            });
                        }
                    }
                })
            },
            kategoriDurumChange(selection, index, list, durum) {
                if (!this.userDataBatiKoleji.tip) {
                    haberKategoriService.kategoriDurumGuncelle(selection.hkategoriId, durum).then((response) => {
                        if (response.status === 200) {
                            notification.Status('success', this, 'Kategori durumu değiştirildi.');
                            list.splice(index, 1);
                        } else {
                            notification.Status(response.status, this)
                        }
                    })
                } else {
                    haberKategoriService.mudurkategoriDurumGuncelle(selection.hkategoriId, durum).then((response) => {
                        if (response.status === 200) {
                            notification.Status('success', this, 'Kategori durumu değiştirildi.');
                            list.splice(index, 1);
                        } else {
                            notification.Status(response.status, this)
                        }
                    })
                }
            },
        }
    }

</script>


<style lang="scss" scoped>
    .dangerbtn {
        color: #ec205f;
        position: absolute;
        bottom: -12px;
        right: -24px;
    }

    .successbtn {
        color: #13ce66;
        position: absolute;
        top: -12px;
        right: -24px;
    }

    .tabloFirstChild {
        position: fixed;
    }
</style>
