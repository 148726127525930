import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

var base64 = require('base64_js');
Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({

    state: {
        secure: [],
        cartList: [],
        musteriId: '',
        tur: '',
        updateKodu: '',
        urunId: '',
        sinavId: '',
        ogrenciId: '',
        layout: {
            navPos: 'left', //top, bottom, left, right, false
            toolbar: 'top', //top, bottom, false
            footer: true, //above, below, false
            boxed: false, //true, false
            roundedCorners: false, //true, false
            viewAnimation: 'fade-top' // fade-left, fade-right, fade-top, fade-top-in-out, fade-bottom, fade-bottom-in-out, fade, false
        },
        splashScreen: true,
        logged: true
    },
    mutations: {
        changeUpdateKodu: (state, value) => {
            state.updateKodu = value
        },
        secure: (state, id) => {
            var obj = state.secure.find(data => data.id === id);
            if (obj) {
                obj.count++;
            } else {
                state.secure.push({
                    id: id,
                    count: 1,
                    sonGiris: new Date().getTime()
                })
            }
        },
        resSecure: (state, id) => {
            var obj = state.secure.find(data => data.id === id);
            state.secure.splice(state.secure.indexOf(obj),1)
        },
        cleanCartList(state, payload) {
            var cart = state.cartList.find(data => data.musteriId === state.musteriId);
            if (cart)
                cart.sepet = [];
        },
        addCartList: (state, value) => {
            var cart = state.cartList.find(data => data.musteriId === state.musteriId);
            if (cart)
                cart.sepet.push(value);
            else {
                var item = {
                    musteriId: state.musteriId,
                    sepet: [value]
                };
                state.cartList.push(item);
            }

        },
        changeAdet: (state, value) => {
            var cart = state.cartList.find(data => data.musteriId === state.musteriId);
            if (cart)
                cart.sepet[value.index].adet = value.adet
        },
        spliceCartList: (state, value) => {
            var cart = state.cartList.find(data => data.musteriId === state.musteriId);
            if (cart)
                cart.sepet.splice(value, 1)
        },
        plusAdetCartList: (state, value) => {
            var cart = state.cartList.find(data => data.musteriId === state.musteriId);
            if (cart)
                cart.sepet[value].adet = parseInt(cart.sepet[value].adet) + 1;
        },

        // changeMaxStok: (state, value) => {
        //     var cart = state.cartList.find(data => data.musteriId === state.musteriId);
        //     if (cart)
        //         cart.sepet[value.index].maxstok = value.adet
        // },
        // cleanMaxStok: (state) => {
        //     var cart = state.cartList.find(data => data.musteriId === state.musteriId);
        //     if (cart)
        //         cart.sepet.forEach(function (e) {
        //             e.maxstok = null;
        //         });
        // },
        changeUrunId: (state, value) => {
            state.urunId = value;
        },
        changeSinavId: (state, value) => {
            state.sinavId = value;
        },
        changeOgrenciId: (state, value) => {
            state.ogrenciId = value;
        },
        changeTur: (state, value) => {
            state.tur = value;
        },
        changeMusteriId: (state, value) => {
            state.musteriId = value;
        },


        setLayout(state, payload) {
            if (payload && payload.navPos !== undefined)
                state.layout.navPos = payload.navPos

            if (payload && payload.toolbar !== undefined)
                state.layout.toolbar = payload.toolbar

            if (payload && payload.footer !== undefined)
                state.layout.footer = payload.footer

            if (payload && payload.boxed !== undefined)
                state.layout.boxed = payload.boxed

            if (payload && payload.roundedCorners !== undefined)
                state.layout.roundedCorners = payload.roundedCorners

            if (payload && payload.viewAnimation !== undefined)
                state.layout.viewAnimation = payload.viewAnimation
        },
        setLogin(state, payload) {
            state.logged = true
        },
        setLogout(state, payload) {
            state.layout.navPos = null
            state.layout.toolbar = null
            state.logged = false
        },
        setSplashScreen(state, payload) {
            state.splashScreen = payload
        }
    },
    actions: {},
    getters: {
        getUpdateKodu(state, getters) {
            return state.updateKodu
        },
        getSecure: (state) => (id) => {
            var obj = state.secure.find(data => data.id === id);
            if(obj){
                return obj.count;
            }else return 1
        },
        getSecureSonGiris: (state) => (id) => {
            var obj = state.secure.find(data => data.id === id);
            if(obj){
                return obj.sonGiris;
            }
        },
        getCartList(state, getters) {
            var cart = state.cartList.find(data => data.musteriId === state.musteriId);
            if (cart)
                return cart.sepet;
            else
                return [];

        },
        getUrunId(state, getters) {
            return state.urunId
        },
        getSinavId(state, getters) {
            return state.sinavId
        },
        getOgrenciId(state, getters) {
            return state.ogrenciId
        },
        getTur(state, getters) {
            return state.tur
        },
        layout(state, getters) {
            return state.layout
        },
        navPos(state, getters) {
            return state.layout.navPos
        },
        toolbar(state, getters) {
            return state.layout.toolbar
        },
        footer(state, getters) {
            return state.layout.footer
        },
        boxed(state, getters) {
            return state.layout.boxed
        },
        roundedCorners(state, getters) {
            return state.layout.roundedCorners
        },
        viewAnimation(state, getters) {
            return state.layout.viewAnimation
        },
        isLogged(state, getters) {
            return state.logged
        },
        splashScreen(state, getters) {
            return state.splashScreen
        }
    },
    plugins: [createPersistedState()],
    strict: debug
})
