import Yonetici from '../views/apps/yonetici/Yonetici.vue'
import YoneticiTanimla from '../views/apps/yonetici/YoneticiTanimla.vue'
import YoneticilerListe from '../views/apps/yonetici/YoneticiListe.vue'
import YoneticilerDuzenle from '../views/apps/yonetici/YoneticiDuzenle.vue'
import layouts from "../layout";


export default {
    path: '/yonetici',
    name: 'yonetici',
    component: Yonetici,
    meta: {
        auth: true,
        layout: layouts.navLeft
    },
    children: [
        {
            path: 'tanimla',
            name: 'yonetici-tanimla',
            component: YoneticiTanimla,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                //layout: layouts.navTop,
                searchable: true,
                title: 'Yonetici Tanımla',
                tags: ['yonetici', 'shop', 'yoneticis']
            }
        },{
            path: 'liste',
            name: 'yonetici-liste',
            component: YoneticilerListe,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                //layout: layouts.navTop,
                searchable: true,
                title: 'Yonetici Liste',
                tags: ['yonetici', 'shop', 'yoneticis']
            }
        },{
            path: 'duzenle',
            name: 'yonetici-duzenle',
            component: YoneticilerDuzenle,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                //layout: layouts.navTop,
                searchable: true,
                title: 'Yonetici Güncelle',
                tags: ['yonetici', 'shop', 'yoneticis']
            }
        }
    ]
}
