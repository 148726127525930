import Haber from '../views/apps/haber/Haber.vue'
import HaberTanimla from '../views/apps/haber/HaberTanimla.vue'
import HaberlerListe from '../views/apps/haber/HaberlerListe.vue'
import HaberlerDuzenle from '../views/apps/haber/HaberlerDuzenle.vue'
import layouts from "../layout";


export default {
    path: '/haber',
    name: 'haber',
    component: Haber,
    meta: {
        auth: true,
        layout: layouts.navLeft
    },
    children: [
        {
            path: 'tanimla',
            name: 'haber-tanimla',
            component: HaberTanimla,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                //layout: layouts.navTop,
                searchable: true,
                title: 'Haber Tanımla',
                tags: ['haber', 'shop', 'habers']
            }
        },{
            path: 'liste',
            name: 'haber-liste',
            component: HaberlerListe,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                //layout: layouts.navTop,
                searchable: true,
                title: 'Haber Liste',
                tags: ['haber', 'shop', 'habers']
            }
        },{
            path: 'duzenle',
            name: 'haber-duzenle',
            component: HaberlerDuzenle,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                //layout: layouts.navTop,
                searchable: true,
                title: 'Haber Güncelle',
                tags: ['haber', 'shop', 'habers']
            }
        }
    ]
}
