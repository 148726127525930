<template>
    <el-dialog :custom-class="'user-dialog duyuruDialog'" :visible="dialogvisible" width="40%" append-to-body
               @close="$emit('update:dialogvisible', false); visiblePassword=false">
        <div class="avatar-box">
<!--            <img src="@/assets/images/batilogo.png" alt="Hakan Bilgi Teknolojileri">-->
        </div>
        <div class="center demo-box">
            <div class="right-box box grow text-center">
                <span style="font-weight: bold; font-size: 22px;color: #00AEEF">{{haberDetay.kategoriBaslik}}</span><br>
            </div>
            <div class="right-box box grow text-center">
                <span style="font-weight: bold; font-size: 18px">{{haberDetay.baslik}}</span><br>
            </div>
            <div class="araCizgi"></div>
            <div v-if="haberDetay.anaResim" class="right-box box grow text-center padding-5">
                <img :src="path + haberDetay.anaResim" alt="Hakan Bilgi Teknolojileri" width="30%" height="30%">
            </div>
            <div class="right-box box grow text-center margin-top-8">
                <blockquote>
                    <p>{{haberDetay.icerik}}</p>
                </blockquote>
            </div>
            <div v-if="haberDetayBelgeList.length>0" class="right-box box grow text-center padding-10">
                <div class="flex align-center justify-space-between border-bottom" v-for="n in haberDetayBelgeList">
                    <strong style="font-size: 16px">
                        <i class="mdi mdi-file mdi-18px" style="color: #00aeef"></i><span>{{n.belgeAdi}}</span>
                    </strong>
                    <el-button type="text" size="mini" icon="mdi mdi-download" v-on:click="belgeIndir(n)">İndir</el-button>
                </div>
            </div>
            <div v-if="haberDetay.olusturan" class="text-right padding-right-20" style="font-size: 12px !important;">
                <strong>Duyuruyu Yayınlayan:</strong> {{haberDetay.olusturan}}
            </div>
            <div v-if="!userDataBatiKoleji.tip || userDataBatiKoleji.tip === '3'" class="padding-horizontal-20" style="font-size: 12px !important;">
                <strong style="font-size: 14px !important;">Duyurunun Gönderildiği Kişiler:</strong>
                <div style="max-height: 150px" class="scrollable only-y">
                    <el-row v-if="gonderilenler.length>0">
                        <el-col :lg="6" :md="6" :sm="24" :xs="24"  v-for="(item,index) in gonderilenler" :key="index">
                            • {{item.adSoyad}}
                        </el-col>
                    </el-row>
                    <el-row v-else>
                        <el-col v-if="!userDataBatiKoleji.tip" :lg="24" :md="24" :sm="24" :xs="24">
                            Duyuru tüm kullanıcılara gönderildi.
                        </el-col>
                        <el-col v-if="userDataBatiKoleji.tip === '3'" :lg="24" :md="24" :sm="24" :xs="24">
                            Duyuru kurumunuzdaki tüm kullanıcılara gönderildi.
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>

    </el-dialog>

</template>

<script>
    import haberService from '../WSProvider/HaberService'
    import functions from '../functions'
    import notification from "../notification";
    var base64 = require('base64_js');

    export default {
        name: "HaberDetay",
        props: ['id', 'dialogvisible'],
        data() {
            return {
                path: haberService.imageUrlOrta,
                pathbelge: haberService.belge,
                haberDetay: [],
                gonderilenler: [],
                userDataBatiKoleji: [],
                haberDetayBelgeList: [],
                notifications: []
            }
        },
        mounted() {
            this.getDuyuruDetay();
        },
        methods: {
            belgeIndir(n){
                window.open(this.pathbelge+n.link, '_blank')
            },
            getDuyuruDetay() {
                var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
                this.userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
                if (userDataBatiKoleji["kullaniciId"]) {
                    haberService.haberDetay(this.id).then((response) => {
                        if (response.status === 200) {
                            if (response.data.icerik !== '') {
                                response.data.icerik = (functions.htmlDecode(response.data.icerik)).replace(/<[^>]*>?/gm, '');
                            }
                            this.haberDetay = response.data;
                            this.haberDetayBelgeList = response.data.belge;
                            this.haberKullanici(this.id)
                        }
                    })
                } else {
                    haberService.haberDetay2(this.id).then((response) => {
                        if (response.status === 200) {
                            if (response.data.icerik !== '') {
                                response.data.icerik = (functions.htmlDecode(response.data.icerik)).replace(/<[^>]*>?/gm, '');
                            }
                            this.haberDetay = response.data;
                            this.haberDetayBelgeList = response.data.belge;
                            this.haberKullanici(this.id)
                        }
                    })
                }
            },
            haberKullanici(val) {
                if (!this.userDataBatiKoleji.tip) {
                    haberService.haberKullaniciArama(val).then((response) => {
                        if (response.status === 200) {
                            this.gonderilenler = response.data
                        } else {
                            notification.Status(response.status, this)
                        }
                    });
                } else {
                    haberService.mudurhaberKullaniciArama(val).then((response) => {
                        if (response.status === 200) {
                            this.gonderilenler = response.data
                        } else {
                            notification.Status(response.status, this)
                        }
                    });
                }
            },
            // getDuyuruDetay() {
            //     var base64 = require('base64_js');
            //     var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
            //     console.log(userDataBatiKoleji)
            //
            //     if (userDataBatiKoleji["tip"] ==='1') {
            //         haberService.haberDetay2(this.id).then((response) => {
            //             if (response.status === 200) {
            //                 response.data.icerik = (functions.htmlDecode(response.data.icerik)).replace(/<[^>]*>?/gm, '');
            //                 this.haberDetay = response.data;
            //             }
            //         })
            //     } else {
            //         haberService.haberDetay(this.id).then((response) => {
            //             if (response.status === 200) {
            //                 response.data.icerik = (functions.htmlDecode(response.data.icerik)).replace(/<[^>]*>?/gm, '');
            //                 this.haberDetay = response.data;
            //             }
            //         })
            //     }
            // }
        }
    }
</script>

<style lang="scss">

.duyuruDialog {
    border-radius: 50px 20px;
    .el-dialog__headerbtn {
        background: transparent !important;
    }
    .avatar-box {
        border-radius: 47px 17px 0 0;
        background: #0075c3 !important;
        margin-bottom: 20px !important;
        img {
            position: static !important;
        }
    }
    .araCizgi {
        background: #cccccc;
        display: block;
        unicode-bidi: isolate;
        margin-block-start: 0.5em;
        margin-block-end: 0.5em;
        margin-inline-start: auto;
        margin-inline-end: auto;
        height: 1px;
        width: 100%;
    }
    .el-dialog__body {
        padding-bottom: 20px !important;
    }


    blockquote {
        position: relative;
        border-left: 5px solid #00AEEF;
        margin: 0 0 25px 0;
        padding: 10px;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        min-height: 38px;
        background: #f2f7fa;
        &::before,
        &::after {
            user-select: none;
            pointer-events: none;
            position: absolute;
            font-size: 80px;
            line-height: 30px;
            color: #1e528e;
        }
        &::before {
            content: "\201C";
            top: 5px;
            left: 0;
        }
        &::after {
            content: "\201D";
            right: 10px;
            top: auto;
            bottom: -0.4em;
        }
        p {
            margin: 0;
            font-size: 14px;
        }
    }
}

</style>
