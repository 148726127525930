<template>
	<div class="box-logo flex align-center justify-center" :class="{'nav-collapsed':collapseNav, [mode]:true}">
		<!--<div class="letter-logo">P</div>-->
		<!--<img class="image-logo" src="@/assets/images/onur-boya.png" alt="logo"/>-->
		<div class="app-name" @click="goto('/')">
			<img src="@/assets/images/logo3.png" alt="">
		</div>
		<!--<button class="collapse-nav" @click="collapseNavToggle">-->
			<!--<i class="mdi mdi-menu"></i>-->
		<!--</button>-->
	</div>
</template>


<script>
	export default {
		name: 'Logo',
		props: ['collapseNav', 'mode'],
		data() {
			return {}
		},
		methods: {
			collapseNavToggle() {
				this.$emit('collapse-nav-toggle')
			},
			goto(index, indexPath) {
				if (window.location.pathname !== '/') {
					this.$router.push(index)
				}
			}
		}
	}
</script>

<style lang="scss">
	@import '../assets/scss/_variables';
	@import '../assets/scss/_mixins';

	.box-logo {
		height: 60px;
		padding: 0 20px;
		box-sizing: border-box;
		font-size: 20px;
		font-weight: bold;
		position: relative;
		@include text-bordered-shadow();


		.image-logo {
			width: 30px;
			height: 30px;
			margin-right: 10px;
			filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.3));
		}

		.app-name {
			cursor: pointer;
			img {
				position: relative;
				top: 5px;
				height: 30px;
			}
		}

		.collapse-nav {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: -30px;
			height: 60px;
			width: 30px;
			opacity: 1;
			cursor: pointer;
			border: 0;
			color: #c1c7cf;
			background-color: transparent;
			outline: none;
			transition: all 500ms;
			i {
				font-size: 32px;
			}
		}


		&.nav-collapsed {
			 .app-name {
				 display: none;
			 }
		 }

		&.horizontal {
			height: 100%;

			.collapse-nav {
				display: none;
			}
		}
	}


	@media (max-width: 768px) {
		.box-logo {
			.collapse-nav {
				display: none;
			}

			&.horizontal {
				padding: 0 10px;

				.app-name {
					display: none;
				}
			}
		}

	}
</style>

