<template>
    <div v-loading="loading">
        <el-row>
            <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                <el-form-item :label='$t("src.views.apps.sayfa.tanimlama.resim") + " (800x600)"'
                              prop="resim">:
                    <el-upload
                            class="resimler"
                            action=""
                            list-type="picture-card"
                            multiple
                            :file-list="imageListMain"
                            :auto-upload="false"
                            accept=".jpg,.jpeg,.png"
                            :on-change="imageUpload"
                            :on-preview="firstImage"
                            :on-remove="imageRemove">
                        <i slot="default" class="el-icon-plus"></i>
                    </el-upload>
                    <el-button style="margin-top: 15px;width: 150px;" type="primary"
                               v-on:click="serverImageList"
                               @click="dialogImgServerVisible = true; " size="mini">
                        Sunucudan
                        Seç
                    </el-button>
                </el-form-item>
            </el-col>
        </el-row>


        <el-dialog
                title='Sunucudan Fotoğraf Seç'
                :visible.sync="dialogImgServerVisible"
                width="60%"
                append-to-body
                center>
            <el-upload
                    action="#"
                    accept=".jpg,.jpeg,.png"
                    :on-change="serverImageUpload"
                    list-type="picture-card"
                    :file-list="imageListServer"
                    :auto-upload="false">
                <i slot="default" class="el-icon-plus"></i>
                <div slot="file" slot-scope="{file}">
                    <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                    <span class="el-upload-list__item-actions">
                            <span class="el-upload-list__item-delete"
                                  @click="imageUploadtoServer(file)">
                                  <i class="el-icon-plus"></i>
                            </span>
                        </span>
                </div>
            </el-upload>
        </el-dialog>
    </div>
</template>

<script>
    import controllerService from '../WSProvider/ControllerService'
    import haberService from '../WSProvider/HaberService'



    import notification from '../notification'
    import functions from '../functions'
    export default {
        name: "Resim",
        props: ['modulName','link','imageList'],
        watch: {
            imageList: function (val) {
                this.imageListMain = val;
            }
        },
        data(){
            return{
                dialogImgServerVisible: false,
                loading : false,
                imageListMain: [],
                imageListServer: [],
            }
        },
        methods : {
            firstImage(item) {
                var index = this.imageListMain.indexOf(item);
                this.imageListMain.splice(index, 1);
                this.imageListMain.unshift(item);
                notification.Status('success', this, item.name + " ana resim olarak ayarlandı..");
            },
            serverImageUpload(file, fileList) {
                if (file.raw["type"] === 'image/jpeg' || file.raw["type"] === 'image/png') {
                    this.resimYukleme(file, this.modulName+ "-" + functions.randomText(7) + '-' + file.name);
                } else {
                    fileList.pop();
                    notification.Status('warning', this, 'Yalnızca ".jpeg", ".jpg" ve ".png" uzantılı görsel seçiniz!!');
                }
            },
            serverImageList() {
                var dizi = [];
                controllerService.sunucuResim(this.modulName).then((response) => {
                    if (response.status === 200) {
                        response.data.forEach(function (e) {
                            var item = {
                                name: e,
                                url: haberService.imageUrlOrta + e
                            };
                            dizi.push(item);
                        });
                        this.imageListServer = dizi;
                    }
                })
            },
            imageUploadtoServer(file) {
                let obj = this.imageListMain.find(data => data.name === file.name.slice(file.name.indexOf("-") + 1));
                if (obj === undefined) {
                    var item = {
                        name: file.name,
                        url: file.url
                    };
                    this.imageListMain.push(item);
                    notification.Status('success', this, file.name.slice(file.name.indexOf("-") + 1) + ' resmi eklendi.');
                } else {
                    notification.Status('warning', this, 'Bu resim zaten mevcut');
                }
            },
            imageRemove(file) {
                this.imageListMain.splice(this.imageListMain.indexOf(file), 1);
            },
            imageUpload(file, fileList) {
                this.loading = true;
                fileList.pop();
                if (file.raw["type"] === 'image/jpg' || file.raw["type"] === 'image/jpeg' || file.raw["type"] === 'image/png') {
                    if (this.link) {
                        if (file.raw["type"] === 'image/jpg' || file.raw["type"] === 'image/jpeg') {
                            var imgname = this.modulName + "-" + functions.randomText(7) + '-' + functions.linkTemizle(this.link) + ".jpg";
                        } else {
                            imgname = this.modulName + "-" + functions.randomText(7) + '-' + functions.linkTemizle(this.link) + ".png";
                        }
                    } else {
                        if (file.raw["type"] === 'image/jpg' || file.raw["type"] === 'image/jpeg') {
                            imgname = this.modulName + "-" + functions.randomText(7) + '-' + functions.linkTemizle(file.name.split('.')[0].substring(0, 60)) + ".jpg";
                        } else {
                            imgname = this.modulName + "-" + functions.randomText(7) + '-' + functions.linkTemizle(file.name.split('.')[0].substring(0, 60)) + ".png";
                        }
                    }
                    let obj = this.imageListMain.find(data => data.id === file.raw.lastModified);
                    if (obj === undefined) {
                        var item = {
                            name: imgname,
                            url: file.url,
                            id: file.raw.lastModified
                        };
                        this.resimYukleme(file, imgname, item);
                    } else {
                        notification.Status('warning', this, 'Bu resim zaten mevcut');
                    }
                } else {
                    fileList.pop();
                    notification.Status('warning', this, 'Yalnızca ".jpeg", ".jpg" ve ".png" uzantılı görsel seçiniz!!');
                }
            },
            resimYukleme(file, imgname, item) {

                const files = file.raw;
                if (files) {
                    const reader = new FileReader();
                    reader.onload = e => {
                        const data = e.target.result;
                        controllerService.resimYukle(this.modulName, imgname, data).then((response) => {
                            if (response.status === 200) {

                                this.imageListMain.push(item);
                                this.$emit('imageListMain', this.imageListMain)
                                this.loading = false;
                            } else {
                                notification.Status(response.status, this)
                            }
                        });
                    };
                    reader.readAsDataURL(files);
                }
            },
        }
    }
</script>

<style scoped>

</style>