<template>
    <div class="page-table mainDiv scrollable" id="affix-container">
        <div class="card-base baslikBg animated fadeInUp">
            Sınav İşlemleri
        </div>
        <el-row :gutter="10">
            <el-col :lg="18" :md="18" :sm="24" :xs="24">
                <div class="page-header card-base card-shadow--medium animated fadeInUp">
                    <el-row class="mt-0 mb-10">
                        <el-col :lg="16" :md="16" :sm="24" :xs="24">
                            <div>
                                <el-radio-group size="small" v-on:change="handleChange($event)" v-model="radio" fill="#E5F5F9"
                                                text-color="gray">
                                    <el-radio-button label="1">
                                        <i class="mdi mdi-page-next mdi-18px" style="color: #13ce66; margin-right: 5px"></i>
                                        <transition
                                                name="slide-fade">
                                            <label v-if="selectIcon==='1'">Aktif Sınavlar</label>
                                        </transition>
                                    </el-radio-button>
                                    <el-radio-button label="0">
                                        <i class="mdi mdi-page-next mdi-18px" style="color: #ec205f; margin-right: 5px"></i>
                                        <transition
                                                name="slide-fade">
                                            <label v-if="selectIcon==='0'">Pasif Sınavlar</label>
                                        </transition>
                                    </el-radio-button>
                                </el-radio-group>
                            </div>
                        </el-col>
                        <el-col :lg="8" :md="8" :sm="24" :xs="24">
                            <el-col :lg="24" :md="24" :sm="24" :xs="24">
                                <ul class="islemler">
                                    <!--<li class="duzenle">-->
                                        <!--<i class="mdi mdi-pen"></i> Düzenle-->
                                    <!--</li>-->
                                    <li class="duzenle">
                                        <i class="mdi mdi-account-multiple"></i> Sınava Giren Öğrenciler
                                    </li>
                                    <li v-if="selectIcon === '1'" class="sil">
                                        <i class="mdi mdi-trash-can"></i> Sil
                                    </li>
                                    <li v-else class="aktif">
                                        <i class="mdi mdi-play"></i> Yayına Al
                                    </li>
                                </ul>
                            </el-col>
                        </el-col>
                    </el-row>
                    <el-table
                            border
                            stripe
                            :data="sinavList"
                            style="width: 100%">
                        <el-table-column
                                label="Sıra"
                                type="index"
                                width="50">
                        </el-table-column>
                        <el-table-column
                                prop="tipi"
                                label="Tip"
                                width="100"
                                :filters="[{ text: 'Tarama', value: '0' }, { text: 'Deneme', value: '1' }]"
                                :filter-method="filterTag"
                                filter-placement="bottom-end">
                            <template slot-scope="scope">
                                <el-tag
                                        :type="scope.row.tipi === '1' ? 'primary' : 'success'"
                                        disable-transitions>{{scope.row.tipi === '0' ? 'Tarama' : "Deneme"}}
                                </el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="baslik"
                                label="Başlık"
                                width="auto">
                        </el-table-column>
                        <el-table-column
                                prop="sinavKodu"
                                label="Sınav Kodu"
                                width="100">
                        </el-table-column>
                        <el-table-column
                                label="Tarih"
                                width="150">
                            <template slot-scope="scope">
                                {{getSinavTarih(scope.row.tarih)}}
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="sinif"
                                label="Sınıf"
                                width="100">
                        </el-table-column>
                        <el-table-column
                                label="İşlem"
                                align="right"
                                width="200">
                            <template slot-scope="scope">
                                <!--<el-tooltip :content='$t("src.views.apps.haberler.kategori.liste.duzenle")'-->
                                            <!--:open-delay="500"-->
                                            <!--placement="bottom">-->
                                    <!--<el-button-->
                                            <!--v-on:click="selectionIndex=scope.$index; selection=scope.row; updateStart(scope.row); "-->
                                            <!--type="text"-->
                                            <!--class="buttonEdit"-->
                                            <!--icon="mdi mdi-pen mdi-24px">-->
                                    <!--</el-button>-->
                                <!--</el-tooltip>-->
                                <el-tooltip content="Sınava Giren Öğrenciler"
                                            :open-delay="500"
                                            placement="bottom">
                                    <el-button
                                            v-on:click="sinavOgrenciListRoute(scope.row)"
                                            type="text"
                                            class="buttonEdit"
                                            icon="mdi mdi-account-group mdi-24px">
                                    </el-button>
                                </el-tooltip>
                                <el-tooltip :content='$t("src.views.apps.haberler.kategori.liste.sil")'
                                            :open-delay="500"
                                            placement="bottom">
                                    <el-button
                                            v-if="selectIcon==='1'"
                                            v-on:click="dialogVisible = true; selection=scope.row; selectionIndex=scope.$index"
                                            type="text"
                                            class="buttonDel"
                                            icon="mdi mdi-trash-can mdi-24px">
                                    </el-button>
                                </el-tooltip>
                                <el-tooltip :content='$t("src.views.apps.sayfa.liste.yayindanKaldir")' :open-delay="500"
                                            placement="bottom">
                                    <el-button
                                            v-if="selectIcon==='0' "
                                            @click="sinavDurumGuncelle(scope.row,scope.$index,sinavList,1)"
                                            type="text"
                                            icon="mdi mdi-play mdi-24px">
                                    </el-button>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                    </el-table>
                    <pagination :total="total" :sizes="sizes" :postPage="postPage" v-on:pagination="handlePagination"></pagination>
                </div>
            </el-col>
            <el-col :lg="6" :md="6" :sm="24" :xs="24">
                <div class="card-base card-shadow--medium demo-box bg-white animated fadeInUp">
                    <el-form status-icon :model="sinavForm" :rules="rulesSinav" ref="sinavForm"
                             class="demo-ruleForm">
                        <div>
                            <el-row>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item label='Okul' prop="okulId">
                                        <el-select
                                                :disabled="okulKilit"
                                                size="small"
                                                v-model="sinavForm.okulId"
                                                style="width: 100%">
                                            <el-option v-for="item in okulList"
                                                       :key="item.okulId"
                                                       :label="item.okulAdi"
                                                       :value="item.okulId"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item label='Sınav Tipi'>:
                                        <div>
                                            <el-radio style="width: calc(50% - 20px)" v-model="sinavForm.tipi" label="0" border>Tarama</el-radio>
                                            <el-radio style="width: calc(50% - 20px)" v-model="sinavForm.tipi" label="1" border>Deneme</el-radio>
                                        </div>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item
                                            label='Sınav Başlığı'
                                            prop="baslik">:
                                        <el-input size="small"
                                                  v-model="sinavForm.baslik"
                                                  placeholder='Başlık Giriniz'></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item
                                            label='Sınav Kodu'
                                            prop="sinavKodu">:
                                        <el-input size="small"
                                                  v-model="sinavForm.sinavKodu"
                                                  placeholder='Sınav Kodu Giriniz'></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item label='Tarih' prop="tarih">:
                                        <el-date-picker
                                                size="small"
                                                 style="width: 100%"
                                                v-model="sinavForm.tarih"
                                                type="date"
                                                format="dd-MM-yyyy"
                                                value-format="yyyy-MM-dd"
                                                :picker-options="dateRangeOptions"
                                                placeholder="Tarih Seçiniz">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item label='Sınıf' prop="sinif">:
                                        <el-input
                                                size="small"
                                                v-model="sinavForm.sinif">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item label='Sonuçlar' prop="spnuclar">:
                                        <el-upload
                                                class="upload-demo"
                                                action=""
                                                :on-change="handleChangeBelge"
                                                :limit="1"
                                                accept=".xls,.xlsx"
                                                :file-list="fileList"
                                                :auto-upload="false">
                                            <el-button size="mini" type="success">Gözat</el-button>
                                        </el-upload>
                                    </el-form-item>
                                </el-col>


                            </el-row>
                        </div>
                        <el-row class="col-p">
                            <el-form-item>
                                <el-button v-if="btnVisible" size="small" v-on:click="sinavTanimla('sinavForm')"
                                           type="primary">
                                    Sınav Tanımla
                                </el-button>
                                <el-button v-else size="small" v-on:click="guncelleme('sinavForm')" type="primary">
                                    Sınav Güncelle
                                </el-button>
                                <el-button v-if="!btnVisible" size="small" v-on:click="vazgec('sinavForm')">
                                    Vazgeç
                                </el-button>
                            </el-form-item>
                        </el-row>
                    </el-form>
                </div>
            </el-col>
        </el-row>
        <el-dialog
                :title='$t("src.views.apps.haberler.liste.uyari")'
                :visible.sync="dialogVisible"
                width="30%"
                center>
            <span>"{{selection["baslik"]}}" sınavını silmek istediğinize emin misiniz?</span>
            <span slot="footer" class="dialog-footer">
                    <el-button icon="el-icon-check"
                               type="success"
                               size="mini"
                               @click="sinavDurumGuncelle(selection,selectionIndex,sinavList,0), dialogVisible = false"></el-button>
                    <el-button size="mini" type="danger" icon="el-icon-close"
                               @click="dialogVisible = false"></el-button>
                </span>
        </el-dialog>
    </div>
</template>

<script>
    import XLSX from 'xlsx';
    import excelJson from '../../../excel'
    import excelTabloJson from '../../../excelTablo'
    import sinavService from '../../../WSProvider/SinavService'
    import okulService from '../../../WSProvider/OkulService'
    import controllerService from '../../../WSProvider/ControllerService'
    import notification from '../../../notification'
    import moment from 'moment-timezone'
    import Pagination from '@/components/Pagination.vue'

    let base64 = require('base64_js');
    export default {
        name: "SinavTanimlama",
        mounted() {
            if (localStorage.getItem("userDataBatiKoleji")) {
                this.userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
                if (this.userDataBatiKoleji.okulId) {
                    this.sinavForm.okulId = this.userDataBatiKoleji.okulId;
                    this.okulKilit = true;
                } else {
                    this.sinavForm.okulId = '';
                    this.okulKilit = false;
                }
            }
            this.getSinavList();
            this.getOkulList();
        },
        components: {
            Pagination
        },
        data() {
            return {
                excelColList: excelJson["col"],
                excelTabloList: excelTabloJson["col"],
                fileList: [],
                file: [],


                // pagination
                sizes: 10,
                postPage: 0,
                total: null,

                dateRangeOptions: {
                    firstDayOfWeek: 1
                },
                userDataBatiKoleji: '',
                selectIcon: '1',
                radio: '1',
                okulList: [],
                sinavList: [],
                kullaniciTipList: [],
                updateData: [],
                sinavFormKlon: [],
                updateKodu: '',
                btnVisible: true,
                selection: '',
                selectionIndex: '',
                dialogVisible: false,
                okulKilit: false,
                // okulId, tipi,sinavKodu,baslik,tarih,sinif,sonuclar
                sinavForm: {
                    okulId: '',
                    tipi: '0',
                    sinavKodu: '',
                    baslik: '',
                    tarih: '',
                    sinif: '',
                    sonuclar: ''
                },
                rulesSinav: {
                    baslik: [{
                        required: true,
                        message: "Lütfen Başlık Giriniz",
                        trigger: 'blur'
                    }],
                },
            }
        },
        methods: {
            sinavOgrenciListRoute(val) {
                this.$store.commit('changeSinavId', val.sinavId);
                this.$router.push({name: 'ogrenci-sinav-list'})
            },
            handlePagination(sizes, postPage) {
                this.sizes = sizes;
                this.postPage = postPage;
                this.getSinavList();
            },
            getSinavTarih(gelenTarih){
                return moment(gelenTarih).format('YYYY/MM/DD');
            },
            handleChangeBelge(file1, fileList) {
                const file = file1.raw;
                self = this;
                if (file) {
                    const reader = new FileReader();
                    reader.onload = e => {
                        const data = e.target.result;
                        const workbook = XLSX.read(data, {type: 'array'})
                        const firstSheetName = workbook.SheetNames[0]
                        const worksheet = workbook.Sheets[firstSheetName]
                        this.importBordro(worksheet)
                    }
                    reader.readAsArrayBuffer(file);
                }
            },
            importBordro(excel) {
                var uzunluk = excel["!ref"].split(":")[1];
                var rowEnd = uzunluk.match(/[^-_ \d]+|\d+/g)[1];
                let self = this;
                var dizi = [];
                for (var i = 7; i <= rowEnd; i++) {
                    var item = {};
                    for (var j = 0; j < self.excelColList.length; j++) {
                        // console.log((excel[(self.excelColList[j] + i)]))
                        if ((excel[(self.excelColList[j] + i)])) {
                            if (excel["B" + i].v.trim() !== 'SINIF ORTALAMASI') {
                                item[self.excelTabloList[j]]  = (excel[(self.excelColList[j]+ i)]).v
                            } else i += 6;
                        }
                    }
                    if(!_.isEmpty(item)){dizi.push(item)}
                }
                this.sinavForm.sonuclar = JSON.stringify(dizi);
            },
            filterTag(value, row) {
                return row.tipi === value;
            },
            handleChange(event) {
                this.selectIcon = event;
                this.getSinavList();
            },
            getOkulList() {
                okulService.okulListesi(this.selectIcon, "", "").then((response) => {
                    if (response.status === 200) {
                        this.okulList = response.data;
                    } else {
                        notification.Status(response.status, this)
                    }
                });
            },
            getSinavList() {
                let self = this;
                if (!this.userDataBatiKoleji.tip) {
                    sinavService.sinavList(this.selectIcon, this.postPage,this.sizes).then((response) => {
                        if (response.status === 200) {
                            this.sinavList = response.data;
                        } else {
                            notification.Status(response.status, this)
                        }
                    });
                } else {
                    sinavService.mudursinavList(this.selectIcon, this.postPage,this.sizes).then((response) => {
                        if (response.status === 200) {
                            this.sinavList = response.data;
                        } else {
                            notification.Status(response.status, this)
                        }
                    });
                }
            },
            updateStart(scope) {
                this.sinavForm.okulId = scope.okulId;
                this.sinavForm.tipi = scope.tipi;
                this.sinavForm.sinavKodu = scope.sinavKodu;
                this.sinavForm.baslik = scope.baslik;
                this.sinavForm.sinif = scope.sinif;
                this.sinavForm.sonuclar = scope.sonuclar;
                this.updateKodu = scope.sinavId;
                this.sinavFormKlon = JSON.stringify(scope)
                this.btnVisible = false;
            },
            vazgec(formName) {
                this.$refs[formName].resetFields();
                this.btnVisible = true;
            },
            sinavTanimla(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {

                        if (!this.userDataBatiKoleji.tip) {
                            sinavService.sinavTanimla(this.sinavForm.okulId, this.sinavForm.tipi, this.sinavForm.sinavKodu, this.sinavForm.baslik, this.sinavForm.tarih, this.sinavForm.sinif, this.sinavForm.sonuclar).then((response) => {
                                if (response.status === 200) {
                                    this.getSinavList();
                                    notification.Status('success', this, 'Sınav başarıyla eklendi.');
                                    this.$refs[formName].resetFields();
                                } else if (response.status === 230) {
                                    notification.Status('warning', this, 'Bu bilgiler ile kayıtlı bir sınavı zaten mevcut');
                                } else {
                                    notification.Status(response.status, this)
                                }
                            });
                        } else {
                            sinavService.mudursinavTanimlama(this.sinavForm.tipi, this.sinavForm.sinavKodu, this.sinavForm.baslik, this.sinavForm.tarih, this.sinavForm.sinif, this.sinavForm.sonuclar).then((response) => {
                                if (response.status === 200) {
                                    this.getSinavList();
                                    notification.Status('success', this, 'Sınav başarıyla eklendi.');
                                    this.$refs[formName].resetFields();
                                    this.sinavForm.okulId = this.userDataBatiKoleji.okulId;
                                } else if (response.status === 230) {
                                    notification.Status('warning', this, 'Bu bilgiler ile kayıtlı bir sınavı zaten mevcut');
                                } else {
                                    notification.Status(response.status, this)
                                }
                            });
                        }
                    }
                })
            },
            guncelleme(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        for (var key in JSON.parse(this.sinavFormKlon)) {
                            if (JSON.parse(this.sinavFormKlon)[key] !== this.sinavForm[key]) {
                                if (this.sinavForm[key] || this.sinavForm[key] === '') {
                                    var item = {
                                        type: key,
                                        value: this.sinavForm[key]
                                    };
                                    this.updateData.push(item);
                                }
                            }

                        }
                        if (!this.userDataBatiKoleji.tip) {
                            sinavService.sinavGuncelle(this.updateKodu,this.updateData).then((response) => {
                                if (response.status === 200) {
                                    this.sinavList.splice(this.selectionIndex, 1);
                                    this.sinavList.splice(this.selectionIndex, 0, response.data);
                                    notification.Status('success', this, 'Sınav başarıyla güncellendi...');
                                    this.$refs[formName].resetFields();
                                    this.btnVisible = true;
                                    this.updateData=[];
                                    this.getOkulList();
                                } else if (response.status === 200) {
                                    notification.Status('warning', this, 'Bu bilgiler ile kayıtlı bir sınavı zaten mevcut');
                                } else {
                                    notification.Status(response.status, this)
                                }
                            });
                        } else {
                            sinavService.mudursinavGuncelleme(this.updateKodu,this.updateData).then((response) => {
                                if (response.status === 200) {
                                    this.sinavList.splice(this.selectionIndex, 1);
                                    this.sinavList.splice(this.selectionIndex, 0, response.data);
                                    notification.Status('success', this, 'Sınav başarıyla güncellendi...');
                                    this.$refs[formName].resetFields();
                                    this.sinavForm.okulId = this.userDataBatiKoleji.okulId;
                                    this.btnVisible = true;
                                    this.updateData=[];
                                    this.getOkulList();
                                } else if (response.status === 200) {
                                    notification.Status('warning', this, 'Bu bilgiler ile kayıtlı bir sınavı zaten mevcut');
                                } else {
                                    notification.Status(response.status, this)
                                }
                            });
                        }

                    }
                })
            },
            sinavDurumGuncelle(selection, index, list, durum) {
                if (!this.userDataBatiKoleji.tip) {
                    sinavService.sinavDurumGuncelle(selection.sinavId, durum).then((response) => {
                        if (response.status === 200) {
                            notification.Status('success', this, 'Sınav durumu değiştirildi.');
                            list.splice(index, 1);
                        } else {
                            notification.Status(response.status, this)
                        }
                    })
                } else {
                    sinavService.mudursinavDurumGuncelleme(selection.sinavId, durum).then((response) => {
                        if (response.status === 200) {
                            notification.Status('success', this, 'Sınav durumu değiştirildi.');
                            list.splice(index, 1);
                        } else {
                            notification.Status(response.status, this)
                        }
                    })
                }
            },
        }
    }

</script>


<style lang="scss" scoped>
    .dangerbtn {
        color: #ec205f;
        position: absolute;
        bottom: -12px;
        right: -24px;
    }

    .successbtn {
        color: #13ce66;
        position: absolute;
        top: -12px;
        right: -24px;
    }

    .tabloFirstChild {
        position: fixed;
    }
</style>
