import Costumers from '../views/apps/costumers/Costumers.vue'
import CostumersList from '../views/apps/costumers/CostumersList.vue'
import layouts from "../layout";


export default {
    path: '/costumers',
    name: 'costumers',
    component: Costumers,
    meta: {
        auth: true,
        layout: layouts.navLeft
    },
    children: [
        {
            path: 'list',
            name: 'costumers-detail',
            component: CostumersList,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                //layout: layouts.navTop,
                searchable: true,
                title: 'Costumers Detail',
                tags: ['costumers', 'shop', 'costumerss']
            }
        }
    ]
}
