var funtions = {
    linkTemizle(text) {
        var trMap = {
            'çÇ': 'c',
            'ğĞ': 'g',
            'şŞ': 's',
            'üÜ': 'u',
            'ıİ': 'i',
            'öÖ': 'o'
        };
        for (var key in trMap) {
            text = text.replace(new RegExp('[' + key + ']', 'g'), trMap[key]);
        }
        return text.replace(/[^-a-zA-Z0-9\s]+/ig, '')
            .replace(/\s/gi, "-")
            .replace(/[-]+/gi, "-")
            .toLowerCase();

    },
    strip_html_tags(str) {
        if (str) {
            str = str.replace(/&nbsp;/gi, '');
            if ((str === null) || (str === ''))
                return false;
            else
                str = str.toString();
            return str.replace(/<[^>]*>/g, '');
        }
    },
    randomText(length) {
        let today = new Date();
        let day = today.getDate();
        let mounth = (today.getMonth() + 1);
        let year = today.getFullYear();
        let bugun = year + '' + (mounth <= 9 ? '0' + mounth : mounth) + '' + (day <= 9 ? '0' + day : day);

        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        for (var i = 0; i < length; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        return bugun + "-" + text;
    },
    htmlDecode(input) {
        var e = document.createElement('div');
        e.innerHTML = input;
        return e.childNodes[0].nodeValue;
    },
    updateListImage(self, type, durum, name) {
        var item = {
            type: type,
            value: [{
                durum: durum,
                [type]: name
            }]
        };
        self.updateData.push(item);
    },
    updateListFile(self, type, durum, link, name) {
        var item = {
            type: type,
            value: [{
                durum: durum,
                link: link,
                belgeAdi: name
            }]
        };
        self.updateData.push(item);
    }

}
export default funtions;