<template>
    <div class="page-table mainDiv scrollable only-y" id="affix-container">
        <div class="card-base baslikBg animated fadeInUp">{{$t("src.views.apps.yonetici.tanimlama.baslik")}}</div>
        <div class="card-base card-shadow--medium demo-box bg-white animated fadeInUp">
            <el-form status-icon :model="yoneticiForm" :rules="rulesYonetici" ref="yoneticiForm"
                     class="demo-ruleForm">
                <div class="card-base card-shadow--medium animated fadeInUp">
                    <el-row>
                        <el-col :lg="6" :md="6" :sm="20" :xs="20" class="col-p">
                            <el-form-item :label='$t("src.views.apps.yonetici.tanimlama.resim")'
                                          prop="image">:
                                <el-upload
                                        action=""
                                        :auto-upload="false"
                                        :limit="1"
                                        :on-change="imageUpload"
                                        :on-remove="imageRemove"
                                        accept=".jpg,.jpeg,.png"
                                        ref="upload"
                                        list-type="picture-card">
                                    <i slot="default" class="el-icon-plus"></i>
                                </el-upload>
                            </el-form-item>
                        </el-col>
                        <el-col :lg="18" :md="18" :sm="24" :xs="24" class="col-p">
                            <el-row>
                                <el-col :lg="8" :md="8" :sm="24" :xs="24" class="col-p">
                                    <el-form-item label="T.C. No" prop="tcNo">:
                                        <el-input
                                                   v-model="yoneticiForm.tcNo" v-mask="'###########'"
                                                   placeholder="Lütfen T.C. No Giriniz"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="8" :md="8" :sm="24" :xs="24" class="col-p">
                                    <el-form-item
                                            :label='$t("src.views.apps.yonetici.tanimlama.adSoyad")'
                                            prop="adSoyad">:
                                        <el-input
                                                   v-model="yoneticiForm.adSoyad"
                                                   :placeholder='$t("src.views.apps.yonetici.tanimlama.adSoyadPlace")'></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="8" :md="8" :sm="24" :xs="24" class="col-p">
                                    <el-form-item
                                            :label='$t("src.views.apps.yonetici.tanimlama.yetki")'
                                            prop="tur">:
                                        <el-select
                                                   v-model="yoneticiForm.tur"
                                                   style="width: 100%">
                                            <el-option value="0" label="Yetki Yok">Yetki Yok</el-option>
                                            <el-option value="2" label="Tam Yetkili">Tam Yetkili</el-option>
                                            <el-option value="3" label="Sınırlı Yetkili">Sınırlı
                                                Yetkili
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="8" :md="8" :sm="24" :xs="24" class="col-p">
                                    <el-form-item
                                            :label='$t("src.views.apps.yonetici.tanimlama.gorev")'
                                            prop="gorev">:
                                        <el-select
                                                   v-model="yoneticiForm.gorev"
                                                   :placeholder='$t("src.views.apps.yonetici.tanimlama.gorevPlace")'
                                                   style="width: 100%">
                                            <el-option value="2" label="Diğer">Diğer</el-option>
<!--                                            <el-option value="1" label="Sevkiyatçı">Sevkiyatçı</el-option>-->
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="8" :md="8" :sm="24" :xs="24" class="col-p">
                                    <el-form-item
                                            :label='$t("src.views.apps.yonetici.tanimlama.email")'
                                            prop="eposta">:
                                        <el-input type="email"
                                                   v-model="yoneticiForm.eposta"
                                                   placeholder="example@gmail.com"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="8" :md="8" :sm="24" :xs="24" class="col-p">
                                    <el-form-item
                                            :label='$t("src.views.apps.yonetici.tanimlama.email2")'
                                            prop="eposta2">:
                                        <el-input type="email"
                                                   v-model="yoneticiForm.eposta2"
                                                   placeholder="example@gmail.com"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="8" :md="8" :sm="24" :xs="24" class="col-p">
                                    <el-form-item
                                            :label='$t("src.views.apps.yonetici.tanimlama.telefonCep")'
                                            prop="cepTelefon">:
                                        <el-input
                                                   v-model="yoneticiForm.cepTelefon"
                                                   v-mask="'+90(###)### ## ##'"
                                                   placeholder="+90(555)555 55 55"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="8" :md="8" :sm="24" :xs="24" class="col-p">
                                    <el-form-item
                                            :label='$t("src.views.apps.yonetici.tanimlama.telefonSabit")'
                                            prop="sabitTelefon">:
                                        <el-input
                                                   v-model="yoneticiForm.sabitTelefon"
                                                   v-mask="'+90(###)### ## ##'"
                                                   placeholder="+90(346)555 55 55"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="8" :md="8" :sm="24" :xs="24" class="col-p">
                                    <el-form-item
                                            :label='$t("src.views.apps.yonetici.tanimlama.dahili")'
                                            prop="dahili">:
                                        <el-input
                                                   v-model="yoneticiForm.dahili" v-mask="'######'"
                                                   placeholder="555555"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="8" :md="8" :sm="24" :xs="24" class="col-p">
                                    <el-form-item
                                            :label='$t("src.views.apps.yonetici.tanimlama.kullaniciAdi")'
                                            prop="kullaniciAdi">:
                                        {{yoneticiForm.kullaniciAdi}}
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="8" :md="8" :sm="24" :xs="24" class="col-p">
                                    <el-form-item
                                            :label='$t("src.views.apps.yonetici.tanimlama.sifre")'
                                            prop="sifre">:
                                        <el-input type="password"
                                                   v-model="yoneticiForm.sifre"></el-input>
                                    </el-form-item>
                                    <password v-model="yoneticiForm.sifre"
                                              :strength-meter-only="true"/>
                                </el-col>
                                <el-col :lg="8" :md="8" :sm="24" :xs="24" class="col-p">
                                    <el-form-item
                                            :label='$t("src.views.apps.yonetici.tanimlama.sifre2")'
                                            prop="sifre2">:
                                        <el-input type="password"
                                                   v-model="yoneticiForm.sifre2"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item>
                                        <el-switch
                                                v-on:change="switchChange"
                                                v-model="yoneticiForm.switch"
                                                active-value="1"
                                                inactive-value="0"
                                                active-color="#13ce66"
                                                inactive-color="#13ce66"
                                                active-text="E-Posta"
                                                inactive-text="T.C. No">
                                        </el-switch>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item>
                                        <span>" 'T.C. No' yada 'E-Posta' adresinizi kullanıcı adı olarak seçebilirsiniz. "</span>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item>
                                        <el-button v-on:click="yoneticiTanimlama('yoneticiForm')" size="small"
                                                   type="primary">
                                            {{$t('src.views.apps.yonetici.tanimlama.yoneticiEkle')}}
                                        </el-button>
                                        &nbsp;
                                        <el-popover
                                                placement="top"
                                                width="300"
                                                v-model="visibleSil">
                                            <p>{{$t('src.views.apps.yonetici.tanimlama.formAlertP')}}</p>
                                            <div style="text-align: right; margin: 0">
                                                <el-button size="mini" type="primary"
                                                           @click="resetForm('yoneticiForm')">
                                                    {{$t('src.views.apps.yonetici.tanimlama.evet')}}
                                                </el-button>
                                                <el-button size="mini" @click="visibleSil = false">
                                                    {{$t('src.views.apps.yonetici.tanimlama.hayir')}}
                                                </el-button>
                                            </div>
                                            <el-button size="small" slot="reference">
                                                {{$t('src.views.apps.yonetici.tanimlama.temizle')}}
                                            </el-button>
                                        </el-popover>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-col>
                    </el-row>
                </div>
            </el-form>
        </div>
    </div>
</template>

<script>

    import yoneticiService from '../../../WSProvider/YoneticiService'
    import controllerService from '../../../WSProvider/ControllerService'
    import Password from 'vue-password-strength-meter'
    import notification from '../../../notification'
    import functions from '../../../functions'
    import JQuery from 'jquery';

    let $ = JQuery;

    export default {
        name: "YoneticiTanimla",
        components: {Password},
        data() {
            let tcKontrol = (rule, value, callback) => {
                let toplam = Number(value.substring(0, 1)) + Number(value.substring(1, 2)) +
                    Number(value.substring(2, 3)) + Number(value.substring(3, 4)) +
                    Number(value.substring(4, 5)) + Number(value.substring(5, 6)) +
                    Number(value.substring(6, 7)) + Number(value.substring(7, 8)) +
                    Number(value.substring(8, 9)) + Number(value.substring(9, 10));
                let strtoplam = String(toplam);
                let onunbirlerbas = strtoplam.substring(strtoplam.length, strtoplam.length - 1);
                if (onunbirlerbas == value.substring(10, 11))
                    callback();
                else
                    callback(new Error("TC Kimlik Numarası Hatalı"));
            };
            var validatePass = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('Lütfen Şifre Giriniz'));
                } else {
                    if (this.yoneticiForm.sifre2 !== '') {
                        this.$refs.yoneticiForm.validateField('sifre2');
                    }
                    callback();
                }
            };
            var validatePass2 = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('Lütfen Şifreyi Tekrar Giriniz'));
                } else if (value !== this.yoneticiForm.sifre) {
                    callback(new Error('Şifreler Uyuşmuyor!!'));
                } else {
                    callback();
                }
            };

            var validateEmail = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('Lütfen E-Posta Belirleyiniz'));
                } else {
                    if (this.yoneticiForm.eposta2 !== '') {
                        this.$refs.yoneticiForm.validateField('email2');
                    }
                    callback();
                }
            };
            var validateEmail2 = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('Lütfen E-Posta Tekrar Giriniz'));
                } else if (value !== this.yoneticiForm.eposta) {
                    callback(new Error('E-Posta Uyuşmuyor!'));
                } else {
                    callback();
                }
            };
            return {
                visibleSil: false,
                yoneticiForm: {
                    yonetimKodu: '',
                    tur: '2',
                    tcNo: '',
                    gorev: '2',
                    image: '',
                    imageBase: '',
                    kullaniciAdi: '',
                    adSoyad: '',
                    eposta: '',
                    eposta2: '',
                    cepTelefon: '',
                    sabitTelefon: '',
                    dahili: '',
                    sifre: '',
                    sifre2: ''
                },
                rulesYonetici: {
                    // tcNo: [{validator: tcKontrol, trigger: 'blur'}],
                    eposta: [
                        {type: 'email', message: 'Lütfen Geçerli Bir E-Posta Giriniz', trigger: 'blur'},
                        {required: true, validator: validateEmail}
                    ],
                    eposta2: [
                        {required: true, validator: validateEmail2, trigger: 'blur'}
                    ],
                    sifre: [
                        {required: true, validator: validatePass, trigger: 'blur'}
                    ],
                    sifre2: [
                        {required: true, validator: validatePass2, trigger: 'blur'}
                    ],
                    kullaniciAdi: [{
                        required: true,
                        message: " Lütfen Kullanıcı Adı Seçiniz. ",
                        trigger: 'change'
                    }],
                    adSoyad: [{
                        required: true,
                        message: " Lütfen İsim Soyisim Giriniz. ",
                        trigger: 'blur'
                    }],
                },
            }
        },
        methods: {
            switchChange(val) {
                switch (val) {
                    case '0' :
                        this.yoneticiForm.kullaniciAdi = this.yoneticiForm.tcNo;
                        break;
                    case '1' :
                        this.yoneticiForm.kullaniciAdi = this.yoneticiForm.eposta;
                        break;
                }
            },
            imageRemove() {
                $('.el-upload--picture-card').show();
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
                $('.el-upload-list__item-delete').click();
                this.visibleSil = false;
            },
            sumResimFonk(file, imgname) {
                const files = file.raw;
                if (files) {
                    const reader = new FileReader();
                    reader.onload = e => {
                        const data = e.target.result;
                        controllerService.resimYukle('yonetici', imgname, data).then((response) => {
                            if (response.status === 200) {
                                this.yoneticiForm.image = response.data[0].file;
                            } else {
                                notification.Status(response.status, this)
                            }
                        });
                    };
                    reader.readAsDataURL(files);
                }
            },
            imageUpload(file) {
                $('.el-upload--picture-card').hide();
                if (file.raw["type"] === 'image/jpg' || file.raw["type"] === 'image/jpeg' || file.raw["type"] === 'image/png') {
                    if (this.yoneticiForm.adSoyad) {
                        if (file.raw["type"] === 'image/jpg' || file.raw["type"] === 'image/jpeg') {
                            var imgname = "yonetici-" + functions.randomText(7) + '-' + functions.linkTemizle(this.yoneticiForm.adSoyad) + ".jpg";
                        } else {
                            imgname = "yonetici-" + functions.randomText(7) + '-' + functions.linkTemizle(this.yoneticiForm.adSoyad) + ".png";
                        }
                    } else {
                        if (file.raw["type"] === 'image/jpg' || file.raw["type"] === 'image/jpeg') {
                            imgname = "yonetici-" + functions.randomText(7) + '-' + functions.linkTemizle(file.name.split('.')[0].substring(0, 60)) + ".jpg";
                        } else {
                            imgname = "yonetici-" + functions.randomText(7) + '-' + functions.linkTemizle(file.name.split('.')[0].substring(0, 60)) + ".png";
                        }
                    }

                    this.sumResimFonk(file, imgname);
                } else {
                    notification.Status('warning', this, 'Yalnızca ".jpeg", ".jpg" ve ".png" uzantılı görsel seçiniz!!');
                }
            },
            yoneticiTanimlama(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        yoneticiService.yoneticiTanimlama(this.yoneticiForm.tur, this.yoneticiForm.tcNo,
                            this.yoneticiForm.image, this.yoneticiForm.kullaniciAdi,
                            this.yoneticiForm.sifre, this.yoneticiForm.adSoyad, this.yoneticiForm.gorev, this.yoneticiForm.eposta,
                            this.yoneticiForm.cepTelefon, this.yoneticiForm.sabitTelefon,
                            this.yoneticiForm.dahili).then((response) => {
                            if (response.status === 200) {
                                notification.Status('success', this, 'Yönetici başarıyla eklendi.');
                                this.$refs[formName].resetFields();
                                $('.el-upload-list__item-delete').click();
                            } else if (response.status === 230) {
                                notification.Status('warning', this, 'Bu bilgiler ile bir yönetici zaten kayıtlı!!');
                            } else {
                                notification.Status(response.status, this)
                            }
                        })
                    }
                })
            },
        }
    }

    function linkTemizle(text) {
        var trMap = {
            'çÇ': 'c',
            'ğĞ': 'g',
            'şŞ': 's',
            'üÜ': 'u',
            'ıİ': 'i',
            'öÖ': 'o'
        };
        for (var key in trMap) {
            text = text.replace(new RegExp('[' + key + ']', 'g'), trMap[key]);
        }
        return text.replace(/[^-a-zA-Z0-9\s]+/ig, '')
            .replace(/\s/gi, "-")
            .replace(/[-]+/gi, "-")

    }

    function randomText(length) {
        let today = new Date();
        let day = today.getDate();
        let mounth = (today.getMonth() + 1);
        let year = today.getFullYear();
        let bugun = year + '' + (mounth <= 9 ? '0' + mounth : mounth) + '' + (day <= 9 ? '0' + day : day);

        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        for (var i = 0; i < length; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));

        return bugun + "-" + text;
    }
</script>

<style lang="scss" scoped>
    @import '../../../assets/scss/_variables';

    .aciklamaSpan {
        position: absolute;
        top: 25px;
    }

    .page-table {
        &.overflow {
            overflow: hidden;
        }

        .table-box {
            overflow: hidden;
        }
    }

    .el-form-item {
        font-weight: bold;
    }

</style>
