<template>
    <div class="page-table mainDiv scrollable only-y">
        <div class="card-base baslikBg animated fadeInUp">
            {{$t("src.views.apps.firma.yonetici.liste.yoneticiListesi")}}
        </div>
        <div class="page-header card-base card-shadow--medium animated fadeInUp">
            <el-col :span="24" class="mb-10">
                <div>
                    <el-radio-group size="small" v-on:change="handleChange($event)" v-model="radio" fill="#E5F5F9"
                                    text-color="gray">
                        <el-radio-button label="1">
                            <i class="mdi mdi-account mdi-18px" style="color: #13ce66; margin-right: 5px"></i>
                            <transition
                                    name="slide-fade"><label v-if="selectIcon==='1'">Aktif Yöneticiler</label>
                            </transition>
                        </el-radio-button>
                        <el-radio-button label="0">
                            <i class="mdi mdi-account-remove mdi-18px" style="color: #ec205f; margin-right: 5px"></i>
                            <transition
                                    name="slide-fade"><label v-if="selectIcon==='0'">Pasif Yöneticiler</label>
                            </transition>
                        </el-radio-button>
                    </el-radio-group>
                </div>
            </el-col>
            <el-table
                    :data="yoneticiListesi"
                    border
                    style="width: 100%">
                <el-table-column
                        label="Sıra"
                        type="index"
                        :index="indexMethod">
                </el-table-column>
                <el-table-column
                        prop="adSoyad"
                        label="İsim Soyisim"
                        width="auto">
                </el-table-column>
                <el-table-column
                        label="Görev"
                        width="auto">
                    <template slot-scope="scope">
                        <el-tooltip :content='scope.row.gorev' :open-delay="500" placement="bottom">
                            <span v-if="scope.row.gorev === '1'">{{'Sevkiyatçı'}}</span>
                            <span v-else>{{'Diğer'}}</span>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="sabitTelefon"
                        label="Telefon"
                        width="auto">
                </el-table-column>
                <el-table-column
                        label="İşlem"
                        align="right"
                        width="200">
                    <template slot-scope="scope">
                        <!--
                        <el-tooltip :content='$t("src.views.apps.firma.yonetici.liste.yoneticidetay")' :open-delay="500"
                                    placement="bottom">
                            <el-button
                                    v-if="root || yetkili ||yetkiList.yoneticiDetayla"
                                    v-on:click="yoneticiDetay(scope.row)"
                                    type="text"
                                    size="mini"
                                    icon="mdi mdi-information-variant mdi-24px">
                            </el-button>
                        </el-tooltip>
                        -->
                        <el-tooltip :content='$t("src.views.apps.firma.yonetici.liste.yoneticiduzenle")'
                                    :open-delay="500"
                                    placement="bottom">
                            <el-button
                                    v-if="root || yetkili || yetkiList.yoneticiGuncelleme"
                                    v-on:click="routeYonetici('Yönetici Düzenle',scope.row)"
                                    type="text"
                                    class="buttonEdit"
                                    icon="mdi mdi-circle-edit-outline mdi-24px">
                            </el-button>
                        </el-tooltip>
                        <el-tooltip :content='$t("src.views.apps.firma.yonetici.liste.yoneticikaldir")'
                                    :open-delay="500"
                                    placement="bottom">
                            <el-button
                                    v-if="selectIcon==='1' && (root || yetkili || yetkiList.yoneticiSil)"
                                    class="buttonDel"
                                    @click="deleteDialogVisible = true; selection = scope.row; selectionIndex = scope.$index"
                                    type="text"
                                    icon="mdi mdi-pause mdi-24px">
                            </el-button>
                        </el-tooltip>
                        <el-tooltip :content='$t("src.views.apps.firma.yonetici.liste.yoneticiaktif")'
                                    :open-delay="500"
                                    placement="bottom">
                            <el-button
                                    v-if="selectIcon==='0' && (root || yetkili || yetkiList.yoneticiSil)"
                                    @click="yoneticiDurumChange(scope.row,scope.$index,yoneticiListesi,1), deleteDialogVisible = false"
                                    type="text"
                                    icon="mdi mdi-play mdi-24px">
                            </el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <user-dialog v-if="dialogVisibleYonetici" :dialogvisible.sync="dialogVisibleYonetici"
                     :userdata="userDataBatiKoleji"></user-dialog>
        <el-dialog
                :title='$t("src.views.apps.firma.yonetici.liste.uyari")'
                :visible.sync="deleteDialogVisible"
                width="30%"
                center>
            <span>"{{selection.adSoyad}}" {{$t('src.views.apps.firma.yonetici.liste.yoneticisil')}}</span>
            <span slot="footer" class="dialog-footer">
        <el-button icon="el-icon-check"
                   type="success"
                   size="mini"
                   @click="yoneticiDurumChange(selection,selectionIndex,yoneticiListesi,0), deleteDialogVisible = false"></el-button>
        <el-button size="mini" type="danger" icon="el-icon-close"
                   @click="deleteDialogVisible = false"></el-button>
        </span>
        </el-dialog>
    </div>
</template>

<script>
    import yoneticiService from '../../../WSProvider/YoneticiService'
    import UserDialog from '@/components/UserDialog'
    import notification from '../../../notification'

    let base64 = require('base64_js');
    let userDataBatiKoleji = "";
    export default {
        name: "YoneticiListe",
        mounted() {
            let self = this;
            if (localStorage.getItem("userDataBatiKoleji")) {
                userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
                if (userDataBatiKoleji["tur"]) {
                    if (userDataBatiKoleji["tur"] === '1') {
                        self.root = true;
                        self.yetkiList.yoneticiSil = true;
                        self.yetkiList.yoneticiDetayla = true;
                        self.yetkiList.yoneticiGuncelleme = true;
                    }
                    if (userDataBatiKoleji["tur"] === '2') {
                        self.yetkiList.yoneticiSil = true;
                        self.yetkiList.yoneticiDetayla = true;
                        self.yetkiList.yoneticiGuncelleme = true;
                    }
                } else if (userDataBatiKoleji["yetki"]) {
                    userDataBatiKoleji["yetki"].forEach(function (value) {
                        switch (value.yetkiKodu) {
                            case 'YK-416861':
                                self.yetkiList.yoneticiDetayla = true;
                                break;
                            case 'YK-191879':
                                self.yetkiList.yoneticiDetayla = true;
                                self.yetkiList.yoneticiSil = true;
                                self.yetkiList.yoneticiGuncelleme = true;
                                break;
                        }
                    });
                }
            }
            this.getFirmaYoneticiList();
        },
        components: {
            UserDialog,
        },
        data() {
            return {
                selectIcon: '1',
                radio: '1',
                root: false,
                yetkili: false,
                yetkiList: {
                    yoneticiDetayla: false,
                    yoneticiGuncelleme: false,
                    yoneticiSil: false,
                },
                dialogVisibleYonetici: false,
                userDataBatiKoleji: {},
                visibleSil: false,
                deleteDialogVisible: false,
                selection: '',
                selectionIndex: '',
                yoneticiListesi: [],
            }
        },
        methods: {
            indexMethod(index) {
                return index + 1;
            },
            yoneticiDetay(scope) {

                this.userDataBatiKoleji = scope;
                this.dialogVisibleYonetici = true;
            },
            handleChange(event) {
                this.yoneticiListesi = [];
                this.selectIcon = event;
                this.getFirmaYoneticiList();
            },
            getFirmaYoneticiList() {
                let self = this;
                yoneticiService.yoneticiList('').then((response) => {
                    if (response.status === 200) {
                        response.data.forEach(function (e) {
                            if (e.durum === self.selectIcon) {
                                self.yoneticiListesi.push(e);
                            }
                        });
                    } else {
                        notification.Status(response.status, this)
                    }
                });
            },
            yoneticiDurumChange(selection, index, list, durum) {
                yoneticiService.yoneticiDurumGuncelle(selection.yonetimKodu, durum).then((response) => {
                    if (response.status === 200) {
                        switch (durum) {
                            case 0:
                                list.splice(index, 1);
                                notification.Status('success', this, 'Yönetici başarıyla eklendi.');
                                break;
                            case 1:
                                list.splice(index, 1);
                                notification.Status('success', this, 'Yönetici başarıyla silindi.');
                                break;
                        }
                    } else {
                        notification.Status(response.status, this)
                    }
                })
            },
            routeYonetici(name, scope) {
                if (scope) {
                    this.$store.commit('changeUpdateKodu', scope.yonetimKodu);
                }
                this.$router.push({name: 'yonetici-duzenle'})
            },
        }
    }
</script>
<style lang="scss" scoped>
    .el-pagination {
        margin-top: 20px;
        float: right;
    }

    .el-radio-button--mini .el-radio-button__inner label {
        position: relative;
        bottom: 5px;
    }

</style>
<style lang="scss">
    .slide-fade-enter-active {
        transition: all .3s ease;
    }
    .el-radio-button--mini .el-radio-button__inner {
        padding: 0 10px !important;
        font-size: 11px;
        border-radius: 0;
    }

    .slide-fade-leave-active {
        transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active below version 2.1.8 */
    {
        transform: translateX(10px);
        opacity: 0;
    }
</style>