import WsProvider from './provider/WSProvider'

var base64 = require('base64_js');
var userDataBatiKoleji = localStorage.getItem("userDataBatiKoleji") ? JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji")))) : "";

var serviceName = "index";
var haberService = {
    imageUrlBuyuk: WsProvider.resim + "/" + userDataBatiKoleji["firmaKodu"] + "/buyuk/",
    imageUrlOrta: WsProvider.resim + "/" + userDataBatiKoleji["firmaKodu"] + "/orta/",
    imageUrlKucuk: WsProvider.resim + "/" + userDataBatiKoleji["firmaKodu"] + "/kucuk/",
    imageUrlEnKucuk: WsProvider.resim + "/" + userDataBatiKoleji["firmaKodu"] + "/enkucuk/",
    belge: WsProvider.belge + "/" + userDataBatiKoleji["firmaKodu"]+'/',
    path  : "https://www.batikoleji.com.tr/",
    haberTanimlama(kategori, baslik, icerik,resimList, belgeList,gizlilik,sms,kullanici, durum) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                yonetimId: userDataBatiKoleji["yonetimId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                kategori: kategori,
                baslik: baslik,
                icerik: icerik,
                resimList: resimList,
                belgeList: belgeList,
                gizlilik: gizlilik,
                sms: sms,
                kullanici: kullanici,
                durum: durum,
                serviceName: "haber",
                methodName: "haberTanimlama"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    mudurhaberTanimlama(kategori, baslik, icerik,resimList, belgeList,gizlilik,sms,kullanici, durum) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                mudurId: userDataBatiKoleji["kullaniciId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                kategori: kategori,
                baslik: baslik,
                icerik: icerik,
                resimList: resimList,
                belgeList: belgeList,
                gizlilik: gizlilik,
                sms: sms,
                kullanici: kullanici,
                durum: durum,
                serviceName: "haber",
                methodName: "mudurhaberTanimlama"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    haberDetay2(haberId) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                yonetimId: userDataBatiKoleji["yonetimId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                haberId: haberId,
                serviceName: "haber",
                methodName: "haberDetay"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    haberDetayMudur(haberId) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                haberId: haberId,
                serviceName: "haber",
                methodName: "haberDetayMudur"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    haberDetay(haberId) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                kullaniciId: userDataBatiKoleji["kullaniciId"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                haberId: haberId,
                serviceName: "haber",
                methodName: "haberDetay"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    haberCount(durum) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                yonetimId: userDataBatiKoleji["yonetimId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                durum: durum,
                serviceName: "haber",
                methodName: "haberCount"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    mudurhaberCount(durum) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                mudurId: userDataBatiKoleji["kullaniciId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                durum: durum,
                serviceName: "haber",
                methodName: "mudurhaberCount"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    haberKisaList(kategoriId, limit) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                yonetimId: userDataBatiKoleji["yonetimId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                kategoriId: kategoriId,
                limit: limit,
                serviceName: "haber",
                methodName: "haberKisaList"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    haberListDetay(kategoriId, baslangic, limit, durum) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                yonetimId: userDataBatiKoleji["yonetimId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                kategoriId: kategoriId,
                baslangic: baslangic,
                limit: limit,
                durum: durum,
                serviceName: "haber",
                methodName: "haberListDetay"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    mudurhaberList(kategoriId, baslangic, limit, durum) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                mudurId: userDataBatiKoleji["kullaniciId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                kategoriId: kategoriId,
                baslangic: baslangic,
                limit: limit,
                durum: durum,
                serviceName: "haber",
                methodName: "mudurhaberListDetay"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    ozelHaberList(baslangic, limit, durum) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                kullaniciId: userDataBatiKoleji["kullaniciId"],
                baslangic: baslangic,
                limit: limit,
                durum: durum,
                serviceName: "haber",
                methodName: "ozelHaberList"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    haberDurumGuncelle(haberId, durum) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                yonetimId: userDataBatiKoleji["yonetimId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                haberId: haberId,
                durum: durum,
                serviceName: "haber",
                methodName: "haberDurumGuncelle"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    mudurhaberDurumGuncelle(haberId, durum) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                mudurId: userDataBatiKoleji["kullaniciId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                haberId: haberId,
                durum: durum,
                serviceName: "haber",
                methodName: "mudurhaberDurumGuncelle"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    haberGuncelle(haberId, updateList) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                yonetimId: userDataBatiKoleji["yonetimId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                haberId: haberId,
                updateList: updateList,
                serviceName: "haber",
                methodName: "haberGuncelle"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    mudurhaberGuncelle(haberId, updateList) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                mudurId: userDataBatiKoleji["kullaniciId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                haberId: haberId,
                updateList: updateList,
                serviceName: "haber",
                methodName: "mudurhaberGuncelle"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    haberKullaniciGuncelle(haberId, updateList) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                yonetimId: userDataBatiKoleji["yonetimId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                haberId: haberId,
                updateList: updateList,
                serviceName: "haber",
                methodName: "haberKullaniciGuncelle"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    mudurhaberKullaniciGuncelle(haberId, updateList) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                mudurId: userDataBatiKoleji["kullaniciId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                haberId: haberId,
                updateList: updateList,
                serviceName: "haber",
                methodName: "mudurhaberKullaniciGuncelle"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    mudurhaberArama(arama, durum) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                mudurId: userDataBatiKoleji["kullaniciId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                arama: arama,
                durum: durum,
                serviceName: "haber",
                methodName: "mudurhaberArama"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;
    },

    haberArama(arama, durum) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                yonetimId: userDataBatiKoleji["yonetimId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                arama: arama,
                durum: durum,
                serviceName: "haber",
                methodName: "haberArama"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;
    },

    haberKullaniciArama(haberId) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                yonetimId: userDataBatiKoleji["yonetimId"],
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                haberId: haberId,
                serviceName: "haber",
                methodName: "haberKullaniciArama"
            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;
    },

    mudurhaberKullaniciArama(haberId) {
        var userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
        var param = {
            data: {
                userToken: userDataBatiKoleji["token"],
                firmaKodu: userDataBatiKoleji["firmaKodu"],
                haberId: haberId,
                serviceName: "haber",
                methodName: "mudurhaberKullaniciArama"
            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;
    },

}

export default haberService

