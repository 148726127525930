<template>
    <div class="page-table haberTanim scrollable mainDiv" id="affix-container">
        <div class="animated fadeInUp card-base baslikBg">{{$t("src.views.apps.haberler.tanimlama.pageBaslik")}}</div>
        <el-row :gutter="10">
            <el-form status-icon :model="haberlerForm" :rules="rulesDuyuruler" ref="haberlerForm"
                     class="demo-ruleForm">
                <el-col :lg="18" :md="18" :sm="24" :xs="24">
                    <div class="card-base card-shadow--medium demo-box bg-white animated fadeInLeft">
                        <el-row>
                            <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                <el-form-item
                                        :label='$t("src.views.apps.haberler.tanimlama.baslik")'
                                        prop="baslik">:
                                    <el-input size="small"

                                              v-model="haberlerForm.baslik"
                                              :placeholder='$t("src.views.apps.haberler.tanimlama.baslikPlace")'></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                <el-form-item :label='$t("src.views.apps.haberler.tanimlama.icerik")'
                                              prop="icerik">:
                                    <ckeditor :editor="editor"
                                              :config="editorConfig"
                                              v-model="haberlerForm.icerik"
                                    ></ckeditor>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>
                    <el-collapse style="margin-top: 10px" v-model="activeCollapse">
                        <el-collapse-item class="card-shadow--medium" title="Resim Ayarları" name="3">
                            <resim :modulName="'haber'" :link="haberlerForm.baslik" v-on:imageListMain="handleResim"></resim>
                        </el-collapse-item>
                        <el-collapse-item class="card-shadow--medium" title="Belge Ayarları" name="4">
                            <belge :modulName="'haber'" v-on:fileListMain="handleBelge"></belge>
                        </el-collapse-item>
                    </el-collapse>
                </el-col>
                <el-col :lg="6" :md="6" :sm="24" :xs="24">
                    <el-collapse class="yanForm animated fadeInUp" v-model="activeCollapse">
                        <el-collapse-item class="card-shadow--medium" name="5"
                                          :title='$t("src.views.apps.haberler.tanimlama.durum")'>
                            <el-row>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item prop="durum">
                                        <el-select
                                                size="small"

                                                v-model="haberlerForm.durum"
                                                :placeholder='$t("src.views.apps.haberler.tanimlama.durumPlace")'
                                                style="width: 100%">
                                            <el-option value="0" label="Taslak">Taslak</el-option>
                                            <el-option value="1" label="Yayınla">Yayınla</el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-button
                                        size="mini" v-on:click="haberTanimlama('haberlerForm',haberlerForm.durum)"
                                        class="onayBtn"
                                        type="primary">
                                    <label v-if="haberlerForm.durum === '1'">
                                        {{$t('src.views.apps.haberler.tanimlama.haberEkle')}}</label>
                                    <label v-else>Kaydet</label>
                                </el-button>
                                &nbsp;
                                <el-popover
                                        placement="bottom"
                                        width="300"
                                        v-model="visibleSil">
                                    <p>{{$t('src.views.apps.yonetici.tanimlama.formAlertP')}}</p>
                                    <div style="text-align: right; margin: 0">
                                        <el-button size="mini" type="primary" @click="resetForm('haberlerForm')">
                                            {{$t('src.views.apps.yonetici.tanimlama.evet')}}
                                        </el-button>
                                        <el-button size="mini" @click="visibleSil = false">
                                            {{$t('src.views.apps.yonetici.tanimlama.hayir')}}
                                        </el-button>
                                    </div>
                                    <el-button size="mini" slot="reference">
                                        {{$t('src.views.apps.yonetici.tanimlama.temizle')}}
                                    </el-button>
                                </el-popover>
                                <el-tooltip content='Çöp Kutusuna At' :open-delay="500" placement="bottom">
                                    <el-button class="cop" v-on:click="haberTanimlama('haberlerForm','2')"
                                               type="text"
                                               icon="el-icon-delete"></el-button>
                                </el-tooltip>
                            </el-row>
                        </el-collapse-item>
                        <el-collapse-item class="card-shadow--medium"
                                          :title='$t("src.views.apps.haberler.tanimlama.kategori")' name="6">
                            <el-row>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item prop="kategori">
                                        <el-select
                                                size="small"

                                                v-model="haberlerForm.kategori"
                                                :placeholder='$t("src.views.apps.haberler.tanimlama.kategoriPlace")'
                                                style="width: 100%">
                                            <el-option v-for="item in kategoriListe"
                                                       :key="item.hkategoriId"
                                                       :label="item.baslik"
                                                       :value="item.hkategoriId"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <div style="font-style: italic">
                                        {{$t("src.views.apps.haberler.tanimlama.kategoriAciklama")}}
                                    </div>
                                </el-col>

                            </el-row>
                        </el-collapse-item>
                        <el-collapse-item class="card-shadow--medium"
                                          title='SMS' name="7">
                            <el-row>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item label="SMS" prop="sms">
                                        <el-switch
                                                v-model="haberlerForm.sms"
                                                active-text="SMS At"
                                                inactive-text="SMS Atma"
                                                active-value="1"
                                                inactive-value="0">
                                        </el-switch>
                                    </el-form-item>
                                    <div style="font-style: italic">
                                        {{"* SMS; Eklenecek duyuru SMS olarak bilgilendirilsin mi?"}}
                                    </div>
                                </el-col>
                            </el-row>
                        </el-collapse-item>
                        <el-collapse-item class="card-shadow--medium"
                                          title='Gizlilik' name="8">
                            <el-row>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item label="Gizlilik" prop="gizlilik">
                                        <el-switch
                                                v-model="haberlerForm.gizlilik"
                                                active-text="Belirlenmiş Kişilere"
                                                inactive-text="Tüm Okula"
                                                active-value="1"
                                                inactive-value="0">
                                        </el-switch>
                                    </el-form-item>
                                    <div style="font-style: italic">
                                        {{"* Genel; Eklenecek duyuruyu herkes görebilir."}}
                                    </div>
                                    <div style="font-style: italic">
                                        {{"* Özel; Eklenecek duyuruyu yalnızca seçilen kullanıcılar görebilir."}}
                                    </div>
                                </el-col>

                            </el-row>
                        </el-collapse-item>
                        <el-collapse-item v-if="haberlerForm.gizlilik==='1'" class="card-shadow--medium"
                                          title='Kullanıcılar' name="9">
                            <el-row>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item prop="okulId">
                                        <el-select
                                            size="small"
                                            :disabled="okulKilit"
                                            v-on:change="getKullaniciTree"
                                            v-model="haberlerForm.okulId"
                                            placeholder='Okul Seçiniz'
                                            style="width: 100%">
                                                <el-option v-for="item in okulList"
                                                   :key="item.okulId"
                                                   :label="item.okulAdi"
                                                   :value="item.okulId"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item label="Kullanıcılar" prop="gizlilik">
                                        <el-input
                                                class="mb-10"
                                                size="small"
                                                placeholder="Kullanıcı Ara"
                                                v-model="filterText">
                                        </el-input>
                                        <el-tree class="filter-tree"
                                                 :data="data"
                                                 ref="tree"
                                                 :auto-expand-parent="false"
                                                 :check-strictly="true"
                                                 @check="handleNodeClick"
                                                 :filter-node-method="filterNode"
                                                 show-checkbox
                                                 node-key="id"
                                                 :props="defaultProps">
                                        </el-tree>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-collapse-item>
                    </el-collapse>
                </el-col>
            </el-form>
        </el-row>
    </div>
</template>
<script>
    import ClassicEditor from '../../../../node_modules/ckeditor5-build-classic';
    import kullaniciService from '../../../WSProvider/KullaniciService'
    import haberService from '../../../WSProvider/HaberService'
    import haberKategoriService from '../../../WSProvider/HaberKategoriService'
    import notification from '../../../notification'
    import EventBus from '@/components/event-bus'
    import Resim from '@/components/Resim.vue'
    import Belge from '@/components/Belge.vue'
    import okulService from "../../../WSProvider/OkulService";
    let base64 = require('base64_js');

    export default {
        name: "DuyurulerTanimla",
        components: {
            Resim,
            Belge,
        },
        watch: {
            filterText(val) {
                this.$refs.tree.filter(val.toLowerCase());
            }
        },
        data() {
            return {
                data: [],
                filterText: '',
                defaultProps: {
                    children: 'children',
                    label: 'label'
                },
                path: haberService.path,
                editor: ClassicEditor,
                editorConfig: {
                    language: 'tr',
                },
                activeCollapse: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
                imageListMain: [],
                fileListMain: [],
                visibleSil: false,
                okulKilit: false,
                kategoriListe: [],
                okulList: [],
                okulId: '',
                haberlerForm: {
                    kategori: '',
                    baslik: '',
                    icerik: '',
                    resim: [],
                    belge: [],
                    durum: '1',
                    gizlilik: '0',
                    sms: '0',
                    kullanici: [],
                    okulId: '',
                },
                rulesDuyuruler: {
                    baslik: [{
                        required: true,
                        message: "Lütfen Duyuru Başlığı Giriniz",
                        trigger: 'blur'
                    }]

                },
            }
        },
        mounted() {
            this.userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
            if (this.userDataBatiKoleji.okulId) {
                this.okulId = this.userDataBatiKoleji.okulId;
                this.okulKilit = true;
            } else {
                this.okulId = '';
                this.okulKilit = false;
            }
            this.getKategoriListe();
            this.getOkulList();
        },
        methods: {
            getKullaniciTree(val){
                if (!this.userDataBatiKoleji.tip) {
                    kullaniciService.kullaniciTree(val).then((response) => {
                        if (response.status === 200) {
                            this.data = response.data
                        } else {
                            notification.Status(response.status, this)
                        }
                    });
                } else {
                    kullaniciService.mudurkullaniciTree().then((response) => {
                        if (response.status === 200) {
                            this.data = response.data
                        } else {
                            notification.Status(response.status, this)
                        }
                    });
                }
            },
            getOkulList() {
                okulService.okulListesi("1", "", "").then((response) => {
                    if (response.status === 200) {
                        this.okulList = response.data;
                        if (this.okulList.length > 0) {
                            if (this.okulId !== '') {
                                this.haberlerForm.okulId = this.okulId;
                                this.getKullaniciTree()
                            } else {
                                this.haberlerForm.okulId = response.data[0].okulId;
                            }
                        }
                    } else {
                        notification.Status(response.status, this)
                    }
                });
            },
            handleNodeClick(data, checked, indeterminate) {
                if(checked.checkedKeys){
                    this.haberlerForm.kullanici = checked.checkedKeys;
                }
            },
            filterNode(value, data) {
                if (!value) return true;
                return data.label.toLowerCase().indexOf(value) !== -1;
            },
            handleResim(data) {
                this.imageListMain = data
            },
            handleBelge(data) {
                this.haberlerForm.belge = data
            },
            getKategoriListe() {
                if (!this.userDataBatiKoleji.tip) {
                    haberKategoriService.kategoriList(1).then(response => {
                        if (response.status === 200) {
                            this.kategoriListe = response.data;
                            if (response.data.length > 0) {
                                this.haberlerForm.kategori = response.data[0].hkategoriId;
                            }
                        } else {
                            notification.Status(response.status, this)
                        }
                    })
                } else {
                    haberKategoriService.mudurkategoriList(1).then(response => {
                        if (response.status === 200) {
                            this.kategoriListe = response.data;
                            if (response.data.length > 0) {
                                this.haberlerForm.kategori = response.data[0].hkategoriId;
                            }
                        } else {
                            notification.Status(response.status, this)
                        }
                    })
                }
            },
            haberTanimlama(formName, durum) {
                let self = this;
                this.haberlerForm.resim = [];
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.imageListMain.forEach(function (e) {
                            self.haberlerForm.resim.push(e.name);
                        });
                        if (!this.userDataBatiKoleji.tip){
                            haberService.haberTanimlama(this.haberlerForm.kategori, this.haberlerForm.baslik,
                                this.haberlerForm.icerik, this.haberlerForm.resim, this.haberlerForm.belge, this.haberlerForm.gizlilik, this.haberlerForm.sms, this.haberlerForm.kullanici,durum).then((response) => {
                                if (response.status === 200) {
                                    switch (durum) {
                                        case '0':
                                            notification.Status('success', this, 'Duyuru taslağa kaldırıldı.');
                                            break;
                                        case '1':
                                            notification.Status('success', this, 'Duyuru başarıyla eklendi.');
                                            break;
                                        case '2':
                                            notification.Status('success', this, 'Duyuru arşive kaldırıldı.');
                                            break;
                                    }
                                    EventBus.$emit("haberList", true);
                                    this.getKategoriListe();
                                    this.resetForm(formName);
                                    this.$router.push({name: 'haber-liste'})
                                } else if (response.status === 230) {
                                    notification.Status('warning', this, 'Bu bilgiler ile kayıtlı bir haber zaten mevcut');
                                } else {
                                    notification.Status(response.status, this)
                                }
                            });
                        } else {
                            haberService.mudurhaberTanimlama(this.haberlerForm.kategori, this.haberlerForm.baslik,
                                this.haberlerForm.icerik, this.haberlerForm.resim, this.haberlerForm.belge, this.haberlerForm.gizlilik, this.haberlerForm.sms, this.haberlerForm.kullanici,durum).then((response) => {
                                if (response.status === 200) {
                                    switch (durum) {
                                        case '0':
                                            notification.Status('success', this, 'Duyuru taslağa kaldırıldı.');
                                            break;
                                        case '1':
                                            notification.Status('success', this, 'Duyuru başarıyla eklendi.');
                                            break;
                                        case '2':
                                            notification.Status('success', this, 'Duyuru arşive kaldırıldı.');
                                            break;
                                    }
                                    EventBus.$emit("haberList", true);
                                    this.getKategoriListe();
                                    this.resetForm(formName);
                                    this.$router.push({name: 'haber-liste'})
                                } else if (response.status === 230) {
                                    notification.Status('warning', this, 'Bu bilgiler ile kayıtlı bir haber zaten mevcut');

                                } else {
                                    notification.Status(response.status, this)
                                }
                            });
                        }
                    }
                })
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.visibleSil = false;
                this.haberlerForm.kullanici = [];
                this.imageListMain = [];
                this.fileListMain = [];
                this.haberlerForm.belge = [];
            },
        }
    }

</script>
