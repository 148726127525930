<template>
    <div class="page-table haberTanim mainDiv listPage scrollable only-y" id="affix-container">
        <div class="card-base baslikBg animated fadeInUp">
            {{$t("src.views.apps.haberler.liste.title")}}
        </div>
        <div class="page-header card-base card-shadow--medium animated fadeInUp">
            <el-row class="mt-0 mb-10">
                <el-col :lg="16" :md="16" :sm="24" :xs="24">
                    <div>
                        <el-radio-group size="small" v-on:change="handleChange($event)" v-model="radio" fill="#E5F5F9"
                                        text-color="gray">
                            <el-radio-button label="1">
                                <i class="mdi mdi-bullhorn mdi-18px" style="color: #13ce66; margin-right: 5px"></i>
                                <transition
                                        name="slide-fade">
                                    <label v-if="selectIcon==='1'">{{$t("src.views.apps.haberler.liste.yayinda")}}</label>
                                </transition>
                            </el-radio-button>
                            <el-radio-button label="2">
                                <i class="mdi mdi-bullhorn mdi-18px" style="color: #f7ba2a; margin-right: 5px"></i>
                                <transition
                                        name="slide-fade">
                                    <label v-if="selectIcon==='2'">{{$t("src.views.apps.haberler.liste.okunmus")}}</label>
                                </transition>
                            </el-radio-button>
                            <el-radio-button v-if="!userDataBatiKoleji.tip || userDataBatiKoleji.tip==='3'" label="0">
                                <i class="mdi mdi-bullhorn mdi-18px" style="color: #ec205f; margin-right: 5px"></i>
                                <transition
                                        name="slide-fade">
                                    <label v-if="selectIcon==='0'">{{$t("src.views.apps.haberler.liste.pasif")}}</label>
                                </transition>
                            </el-radio-button>
                        </el-radio-group>
                    </div>
                </el-col>
                <el-col :lg="8" :md="8" :sm="24" :xs="24">
                    <el-col :lg="24" :md="24" :sm="24" :xs="24">
                        <ul class="islemler">
                            <li class="detay">
                                <i class="mdi mdi-account"></i> Oluşturan
                            </li>
                            <li class="aktif">
                                <i class="mdi mdi-email-open"></i> Duyuru Detayı
                            </li>
                            <li v-if="root || userDataBatiKoleji.tip === '3'" class="duzenle">
                                <i class="mdi mdi-pen"></i> Düzenle
                            </li>
                            <li v-if="selectIcon === '1' && (root || userDataBatiKoleji.tip === '3')" class="sil">
                                <i class="mdi mdi-trash-can"></i> Sil
                            </li>
                            <li v-else-if="selectIcon==='0' && (root || userDataBatiKoleji.tip === '3')" class="aktif">
                                <i class="mdi mdi-play"></i> Yayına Al
                            </li>
                        </ul>
                    </el-col>
                </el-col>
            </el-row>
            <el-table
                    :data="haberlerList.filter(data => !search.baslik || data.baslik.toLowerCase().includes(search.baslik.toLowerCase()) )"
                    ref="filterTable"
                    border
                    stripe
                    style="width: 100%">
                <el-table-column
                        label="Sıra"
                        type="index"
                        width="50">
                </el-table-column>
                <el-table-column
                        v-if="!index"
                        :filters="filtersKategori"
                        :filter-method="filterTag"
                        filter-placement="bottom-end"
                        prop="kategoriAdi"
                        label="Kategori"
                        width="150">
                    <template slot-scope="scope">
                        <el-tag
                                type="primary"
                                disable-transitions>{{scope.row.kategoriAdi}}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                        label="Resim"
                        width="100">
                    <template slot-scope="scope">
                        <el-popover
                                transition="el-fade-in-linear"
                                placement="left"
                                width="auto"
                                trigger="hover">
                            <img v-if="scope.row.resim" :src="imagepath+scope.row.resim"
                                 :alt="scope.row.baslik">
                            <i style="font-size: 24px; cursor: pointer; color: #337ab7" slot="reference"
                               class="el-icon-picture"></i>
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="baslik"
                        label="Başlık"
                        width="auto">
                </el-table-column>
                <el-table-column
                        v-if="userDataBatiKoleji['tur'] === '1'"
                        prop="tipi"
                        label="Tip"
                        width="auto">
                    <template slot-scope="scope">
                        <div v-on:dblclick="doubleClick(scope.row,scope.$index)">
                            <el-input
                                    size="mini"
                                    v-if="editable[scope.$index]"
                                    style="position: relative; right: 10px; width: 100%"
                                    ref="searchHasta"
                                    class="inline-input"
                                    :clearable="false"
                                    v-model="sayfaTipi"
                                    placeholder="Sayfa tipi giriniz">
                            </el-input>
                            <span v-if="editable[scope.$index]" style="position: relative">
                                        <el-button
                                                v-on:click="clearable(scope.row,scope.$index)"
                                                class="dangerbtn"
                                                type="text" size="mini"
                                                icon="el-icon-circle-close"></el-button>
                                        <el-button
                                                v-on:click="kaydet(scope.row,scope.$index)"
                                                class="successbtn"
                                                type="text" size="mini"
                                                icon="el-icon-circle-check"></el-button>
                                        </span>
                            <span v-else>{{scope.row.tipi}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                        v-if="userDataBatiKoleji['tur'] === '1'"
                        prop="link"
                        label="Link"
                        width="auto">
                </el-table-column>
                <el-table-column
                        sortable
                        prop="olusumTarihi"
                        label="Yayın Tarihi"
                        width="180">
                </el-table-column>
                <el-table-column
                        label="İşlem"
                        align="right"
                        width="250">
                    <template v-if="userDataBatiKoleji.tip === '3' || !userDataBatiKoleji.tip" slot="header" slot-scope="scope">
                        <el-input clearable v-on:clear="clearable2" v-model="search" v-on:input="searchMethod"
                                  placeholder="Ara" size="mini"></el-input>
                    </template>
                    <template slot-scope="scope">
<!--                        <el-tooltip :content='scope.row.olusturan' :open-delay="500"-->
<!--                                    placement="bottom">-->
<!--                            <el-button-->
<!--                                    class="buttonDetay"-->
<!--                                    type="text"-->
<!--                                    icon="mdi mdi-account mdi-24px">-->
<!--                            </el-button>-->
<!--                        </el-tooltip>-->
                        <el-tooltip :content='$t("src.views.apps.haberler.liste.haberdetay")' :open-delay="500"
                                    placement="bottom">
                        <el-button
                                v-on:click="haberDetay(scope.row)"
                                class="buttonAktif"
                                type="text"
                                icon="mdi mdi-email-open mdi-24px">
                        </el-button>
                        </el-tooltip>
                        <el-tooltip :content='$t("src.views.apps.haberler.liste.haberlerduzenle")'
                                    :open-delay="500"
                                    placement="bottom">
                            <el-button
                                    v-if="root || userDataBatiKoleji.tip === '3'"
                                    v-on:click="routeBulten('Bülten Düzenle',scope.row)"
                                    type="text"
                                    class="buttonEdit"
                                    icon="mdi mdi-pen mdi-24px">
                            </el-button>
                        </el-tooltip>
                        <el-tooltip :content='$t("src.views.apps.haberler.liste.yayindanKaldir")' :open-delay="500"
                                    placement="bottom">
                            <el-button
                                    v-if="selectIcon==='1' && (root || userDataBatiKoleji.tip === '3')"
                                    @click="deleteDialogVisible = true; selection = scope.row; selectionIndex = scope.$index"
                                    size="mini"
                                    class="buttonDel"
                                    type="text"
                                    icon="mdi mdi-trash-can mdi-24px">
                            </el-button>
                        </el-tooltip>
                        <el-tooltip :content='$t("src.views.apps.sayfa.liste.yayinaAl")' :open-delay="500"
                                    placement="bottom">
                            <el-button
                                    v-if="selectIcon==='0' && (root || userDataBatiKoleji.tip === '3')"
                                    @click="addDialogVisible = true; selection = scope.row; selectionIndex = scope.$index"
                                    type="text"
                                    class="buttonAktif"
                                    icon="mdi mdi-play mdi-24px">
                            </el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
            <pagination v-if="search === ''" :total="total" :sizes="sizes" :postPage="postPage" v-on:pagination="handlePagination"></pagination>
        </div>
        <el-dialog
                :title='$t("src.views.apps.haberler.liste.uyari")'
                :visible.sync="deleteDialogVisible"
                width="30%"
                center>
            <span>"{{selection.baslik}}" {{$t('src.views.apps.haberler.liste.haberlerYayin')}}</span>
            <span slot="footer" class="dialog-footer">
                    <el-button icon="el-icon-check"
                               type="success"
                               size="mini"
                               @click="haberDurumChange(selection,selectionIndex,haberlerList,0), deleteDialogVisible = false"></el-button>
                    <el-button size="mini" type="danger" icon="el-icon-close"
                               @click="deleteDialogVisible = false"></el-button>
                </span>
        </el-dialog>
        <el-dialog
                :title='$t("src.views.apps.haberler.liste.uyari")'
                :visible.sync="addDialogVisible"
                width="30%"
                center>
            <span>"{{selection.baslik}}" {{$t('src.views.apps.haberler.liste.haberlersil')}}</span>
            <span slot="footer" class="dialog-footer">
                    <el-button icon="el-icon-check"
                               type="success"
                               size="mini"
                               @click="haberDurumChange(selection,selectionIndex,haberlerList,1), addDialogVisible = false"></el-button>
                    <el-button size="mini" type="danger" icon="el-icon-close"
                               @click="addDialogVisible = false"></el-button>
                </span>
        </el-dialog>
        <haber-detay v-if="visibleDetay" :id="detayHaberId" :dialogvisible.sync="visibleDetay"></haber-detay>
    </div>
</template>

<script>
    import haberlerService from '../../../WSProvider/HaberService'
    import controllerService from '../../../WSProvider/ControllerService'
    import notification from '../../../notification'
    import functions from '../../../functions'
    import EventBus from '@/components/event-bus'
    import Pagination from '@/components/Pagination.vue'
    import HaberDetay from '@/components/HaberDetay.vue'
    // import markaService from "../../../WSProvider/MarkaService";
    // import sayfaService from "../../../WSProvider/SayfaService";

    var moment = require('moment');

    let base64 = require('base64_js');
    let userDataBatiKoleji = "";
    export default {
        name: "HaberlerListe",
        components: {
            Pagination,
            HaberDetay
        },
        mounted() {
            let self = this;
            if (localStorage.getItem("userDataBatiKoleji")) {
                userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
                this.userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
                if (userDataBatiKoleji["tur"]) {
                    if (userDataBatiKoleji["tur"] === '1' || userDataBatiKoleji["tur"] === '2') {
                        self.root = true;
                    }
                }
            }
            EventBus.$on('haberList', function (bool) {
                if (bool) {
                    self.haberlerCount();
                    self.getHaberList();
                }
            });
            this.haberlerCount();
            this.getHaberList();
        },
        data() {
            return {
                // haber detay
                detayHaberId: '',
                visibleDetay: false,

                userDataBatiKoleji : [],
                editable: [],
                sayfaTipi: '',
                selectIcon: '1',
                selectIconOkundu: '1',
                radio: '1',
                radioOkundu: '1',
                root: false,
                path: haberlerService.path,
                search: '',
                imagepath: haberlerService.imageUrlOrta,
                index: '',
                page: 0,
                sizes: 10,
                postPage: 0,
                total: null,
                deleteDialogVisible: false,
                addDialogVisible: false,
                selection: '',
                selectionIndex: '',
                haberlerList: [],
                filtersKategori: [],
            }
        },
        methods: {
            haberDetay(val) {
                this.detayHaberId = val.haberId;
                this.visibleDetay = true;
            },
            searchMethod(val) {
                if (val !== '') {
                    var dizi = [];
                    if (!this.userDataBatiKoleji.tip){
                        haberlerService.haberArama(val, this.selectIcon).then((response) => {
                            if (response.status === 200) {
                                response.data.forEach(function (e) {
                                    dizi.push(e);
                                });
                                this.haberlerList = dizi;
                            } else notification.Status(response.status, this)
                        })
                    } else {
                        haberlerService.mudurhaberArama(val, this.selectIcon).then((response) => {
                            if (response.status === 200) {
                                response.data.forEach(function (e) {
                                    dizi.push(e);
                                });
                                this.haberlerList = dizi;
                            } else notification.Status(response.status, this)
                        })
                    }
                } else this.clearable2();
            },
            clearable2() {
                this.haberlerList = [];
                this.getHaberList();
                this.haberlerCount();
            },
            handlePagination(sizes, postPage) {
                this.sizes = sizes;
                this.postPage = postPage;
                this.getHaberList();
            },
            handleSizeChange(val) {
                this.sizes = val;
                this.getHaberList();
            },
            pageChange(val) {
                this.postPage = (val - 1) * this.sizes;
                this.getHaberList();
            },
            handleChange(event) {
                this.selectIcon = event;
                this.getHaberList();
                this.haberlerCount();
            },
            handleChangeOkundu(event) {
                this.selectIconOkundu = event;
                this.getHaberList();
                this.haberlerCount();
            },
            forceUpper(e, o, prop) {
                const start = e.target.selectionStart;
                e.target.value = e.target.value.toUpperCase();
                this.$set(o, prop, e.target.value);
                e.target.setSelectionRange(start, start);
            },
            filterTag(value, row) {
                return row.kategoriKodu === value;
            },
            haberlerCount() {
                if (!this.userDataBatiKoleji.tip) {
                    haberlerService.haberCount(this.selectIcon).then((response) => {
                        if (response.status === 200) {
                            this.total = parseInt(response.data.count);
                        } else {
                            notification.Status(response.status, this)
                        }
                    });
                } else {
                    haberlerService.mudurhaberCount(this.selectIcon).then((response) => {
                        if (response.status === 200) {
                            this.total = parseInt(response.data.count);
                        } else {
                            notification.Status(response.status, this)
                        }
                    });
                }
            },
            getHaberList() {
                // ozelHaberList
                let self = this;
                if (!this.userDataBatiKoleji.tip) {
                    haberlerService.haberListDetay(this.index, this.postPage, this.sizes, this.selectIcon).then((response) => {
                        if (response.status === 200) {
                            response.data.forEach(function (e) {
                                e.olusumTarihi = moment(e.olusumTarihi).format('DD-MM-YYYY HH:mm:ss');
                                var obj2 = self.filtersKategori.find(data => data.value === e.kategoriKodu);
                                if (obj2 === undefined) {
                                    self.filtersKategori.push({
                                        text: e.kategoriAdi,
                                        value: e.kategoriKodu
                                    })
                                }
                            });
                            self.haberlerList = response.data;
                        } else {
                            notification.Status(response.status, this)
                        }
                    });
                } else {
                    haberlerService.mudurhaberList(this.index, this.postPage, this.sizes, this.selectIcon).then((response) => {
                        if (response.status === 200) {
                            response.data.forEach(function (e) {
                                e.olusumTarihi = moment(e.olusumTarihi).format('DD-MM-YYYY HH:mm:ss');
                                var obj2 = self.filtersKategori.find(data => data.value === e.kategoriKodu);
                                if (obj2 === undefined) {
                                    self.filtersKategori.push({
                                        text: e.kategoriAdi,
                                        value: e.kategoriKodu
                                    })
                                }
                            });
                            self.haberlerList = response.data;
                        } else {
                            notification.Status(response.status, this)
                        }
                    });
                }
            },
            routeBulten(name, scope) {
                let tabs = this.$store.getters.getTabList;
                if (scope) {
                    this.$store.commit('changeUpdateKodu', scope.haberId);
                }
                this.$router.push({name: 'haber-duzenle'})
            },
            haberDurumChange(selection, index, list, durum) {
                if (!this.userDataBatiKoleji.tip) {
                    haberlerService.haberDurumGuncelle(selection.haberId, durum).then((response) => {
                        if (response.status === 200) {
                            switch (durum) {
                                case 0:
                                    notification.Status("success", this, "Duyuru Taslağa Taşındı.");
                                    list.splice(index, 1);
                                    break;
                                case 1:
                                    notification.Status("success", this, "Duyuru Yayına Alındı.");
                                    list.splice(index, 1);
                                    break;
                                case 2:
                                    notification.Status("success", this, "Duyuru Arşive Taşındı.");
                                    list.splice(index, 1);
                                    break;
                            }
                        }
                    })
                } else {
                    haberlerService.mudurhaberDurumGuncelle(selection.haberId, durum).then((response) => {
                        if (response.status === 200) {
                            switch (durum) {
                                case 0:
                                    notification.Status("success", this, "Duyuru Taslağa Taşındı.");
                                    list.splice(index, 1);
                                    break;
                                case 1:
                                    notification.Status("success", this, "Duyuru Yayına Alındı.");
                                    list.splice(index, 1);
                                    break;
                                case 2:
                                    notification.Status("success", this, "Duyuru Arşive Taşındı.");
                                    list.splice(index, 1);
                                    break;
                            }
                        }
                    })
                }
            },
            doubleClick(scope, row) {
                this.editable = [];
                this.sayfaTipi = scope.tipi ? scope.tipi :  "";
                this.editable[row] = true;
                this.$set(this.editable, row, true)

            },
            clearable(scope, row) {
                this.editable = [];
                this.editable[row] = false;
                this.$set(this.editable, row, false)

            },
            kaydet(scope,row) {
                controllerService.seoTipiGuncelleme(scope.link,this.sayfaTipi,'haber').then((response) => {
                    if (response.status === 200) {
                        notification.Status("success", this, "Duyuru tipi değiştirildi.");
                        this.haberlerList.splice(row, 1);
                        this.haberlerList.splice(row, 0, response.data);
                        this.editable = [];
                        this.editable[row] = false;
                        this.$set(this.editable, row, false)
                    }
                    notification.Status(response.status, this)
                })

            },
        }
    }

</script>

<style lang="scss">
    .slide-fade-enter-active {
        transition: all .3s ease;
    }


    @media (min-width: 768px) {
        .el-radio-button--mini .el-radio-button__inner {
            padding: 0 10px !important;
            font-size: 11px;
            border-radius: 0;
        }
    }


    .slide-fade-leave-active {
        transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }

    .slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active below version 2.1.8 */
    {
        transform: translateX(10px);
        opacity: 0;
    }
</style>
<style lang="scss" scoped>
    .dangerbtn {
        color: #ec205f;
        position: absolute;
        bottom: -12px;
        right: -24px;
    }

    .successbtn {
        color: #13ce66;
        position: absolute;
        top: -12px;
        right: -24px;
    }

    .tabloFirstChild {
        position: fixed;
    }
</style>
