<template>
    <div class="page-table mainDiv scrollable" id="affix-container">
        <div class="card-base baslikBg animated fadeInUp">
            Kullanıcı İşlemleri
        </div>
        <el-row :gutter="10">
            <el-col :lg="18" :md="18" :sm="24" :xs="24">
                <div class="page-header card-base card-shadow--medium animated fadeInUp">
                    <el-row class="mt-0 mb-10">
                        <el-col :lg="6" :md="6" :sm="24" :xs="24">
                            <div>
                                <el-radio-group size="small" v-on:change="handleChange($event)" v-model="radio" fill="#E5F5F9"
                                                text-color="gray">
                                    <el-radio-button label="1">
                                        <i class="mdi mdi-page-next mdi-18px" style="color: #13ce66; margin-right: 5px"></i>
                                        <transition
                                                name="slide-fade">
                                            <label v-if="selectIcon==='1'">Aktif Kullanıcılar</label>
                                        </transition>
                                    </el-radio-button>
                                    <el-radio-button label="0">
                                        <i class="mdi mdi-page-next mdi-18px" style="color: #ec205f; margin-right: 5px"></i>
                                        <transition
                                                name="slide-fade">
                                            <label v-if="selectIcon==='0'">Pasif Kullanıcılar</label>
                                        </transition>
                                    </el-radio-button>
                                </el-radio-group>
                            </div>
                        </el-col>
                        <el-col :lg="18" :md="18" :sm="24" :xs="24">
                            <el-col :lg="24" :md="24" :sm="24" :xs="24">
                                <ul class="islemler">
                                    <li class="duzenle">
                                        <i class="mdi mdi-pen"></i> Düzenle
                                    </li>
                                    <li class="sifre">
                                        <i class="mdi mdi-lock"></i> Şifre Oluştur
                                    </li>
                                    <li class="diger">
                                        <i class="mdi mdi-account-multiple"></i> Bağlatılı Kullanıcılar
                                    </li>
                                    <li class="detay">
                                        <i class="mdi mdi-android-messages"></i> SMS Gönder
                                    </li>
                                    <li class="goruntule">
                                        <i class="mdi mdi-account-group"></i> Kullanıcının Sınavları
                                    </li>
                                    <li v-if="selectIcon === '1'" class="sil">
                                        <i class="mdi mdi-trash-can"></i> Sil
                                    </li>
                                    <li v-else class="aktif">
                                        <i class="mdi mdi-play"></i> Yayına Al
                                    </li>
                                </ul>
                            </el-col>
                        </el-col>
                    </el-row>
                    <el-table
                            border
                            stripe
                            :data="kullaniciList"
                            style="width: 100%">
                        <el-table-column
                                label="Sıra"
                                type="index"
                                width="50">
                        </el-table-column>
                        <el-table-column
                                prop="tip"
                                label="Tip"
                                width="120"
                                :filters="[{ text: 'Veli', value: '0' }, { text: 'Öğretmen', value: '1' },{ text: 'Öğrenci', value:'2' },{ text: 'İdari Personel', value:'3' }]"
                                :filter-method="filterTag"
                                filter-placement="bottom-end">
                            <template slot-scope="scope">
                                <el-tag
                                        :type="scope.row.tip === '1' ? 'primary' : 'success'"
                                        disable-transitions>{{scope.row.tip === '0' ? 'Veli' : (scope.row.tip === '1' ? 'Öğretmen' : (scope.row.tip === '2' ? 'Öğrenci' : 'İdari Personel'))}}
                                </el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="adSoyad"
                                label="İsim Soyisim"
                                width="auto">
                        </el-table-column>
                        <el-table-column
                                prop="tcNo"
                                label="TC No"
                                width="200">
                        </el-table-column>
                        <el-table-column
                                prop="sinif"
                                label="Sınıf"
                                width="100">
                        </el-table-column>
                        <el-table-column
                                label="İşlem"
                                align="right"
                                width="200">
                            <template slot="header" slot-scope="scope">
                                <el-input clearable v-on:clear="clearable" v-model="search" v-on:input="searchMethod"
                                          placeholder="Ara" size="mini"></el-input>
                            </template>
                            <template slot-scope="scope">
                                <el-tooltip content='Bağlatılı Kullanıcılar'
                                            :open-delay="500"
                                            placement="bottom">
                                    <el-button
                                            v-if="scope.row.tip === '0'"
                                            v-on:click="getVeliOgrenciList(scope.row)"
                                            type="text"
                                            class="buttonDetay"
                                            icon="mdi mdi-account-multiple mdi-24px"
                                            style="color: #969696">
                                    </el-button>
                                </el-tooltip>
                                <el-tooltip content='Bağlatılı Kullanıcılar'
                                            :open-delay="500"
                                            placement="bottom">
                                    <el-button
                                            v-if="scope.row.tip === '2'"
                                            v-on:click="getOgrenciVeliList(scope.row)"
                                            type="text"
                                            class="buttonDetay"
                                            icon="mdi mdi-account-multiple mdi-24px"
                                            style="color: #969696">
                                    </el-button>
                                </el-tooltip>
                                <el-tooltip content='Şifre Oluştur'
                                            :open-delay="500"
                                            placement="bottom">
                                    <el-button
                                            v-on:click="sifreOlustur(scope.row)"
                                            type="text"
                                            class="buttonDetay"
                                            icon="mdi mdi-lock mdi-24px"
                                            style="color: #41B883">
                                    </el-button>
                                </el-tooltip>
                                <el-tooltip content='SMS Gönder'
                                            :open-delay="500"
                                            placement="bottom">
                                    <el-button
                                            v-if="scope.row.tip ==='0'"
                                            v-on:click="smsVisible= true; selectionSms=scope.row"
                                            type="text"
                                            class="buttonDetay"
                                            icon="mdi mdi-android-messages mdi-24px">
                                    </el-button>
                                </el-tooltip>
                                <el-tooltip content='Kullanıcının Sınavları'
                                            :open-delay="500"
                                            placement="bottom">
                                    <el-button
                                            v-if="scope.row.tip === '2'"
                                            v-on:click="kullaniciSinavListRoute(scope.row)"
                                            type="text"
                                            class="buttonEdit"
                                            icon="mdi mdi-account-group mdi-24px"
                                            style="color: #606266">
                                    </el-button>
                                </el-tooltip>
                                <el-tooltip :content='$t("src.views.apps.haberler.kategori.liste.duzenle")'
                                            :open-delay="500"
                                            placement="bottom">
                                    <el-button
                                            v-if="(!userDataBatiKoleji.tip || (userDataBatiKoleji.kullaniciId !== scope.row.kullaniciId))"
                                            v-on:click="selectionIndex=scope.$index; selection=scope.row; updateStart(scope.row); "
                                            type="text"
                                            class="buttonEdit"
                                            icon="mdi mdi-pen mdi-24px">
                                    </el-button>
                                </el-tooltip>
                                <el-tooltip :content='$t("src.views.apps.haberler.kategori.liste.sil")'
                                            :open-delay="500"
                                            placement="bottom">
                                    <el-button
                                            v-if="selectIcon==='1' && (!userDataBatiKoleji.tip || (userDataBatiKoleji.kullaniciId !== scope.row.kullaniciId))"
                                            v-on:click="dialogVisible = true; selection=scope.row; selectionIndex=scope.$index"
                                            type="text"
                                            class="buttonDel"
                                            icon="mdi mdi-trash-can mdi-24px">
                                    </el-button>
                                </el-tooltip>
                                <el-tooltip :content='$t("src.views.apps.sayfa.liste.yayindanKaldir")' :open-delay="500"
                                            placement="bottom">
                                    <el-button
                                            v-if="selectIcon==='0' "
                                            @click="kullaniciDurumGuncelle(scope.row,scope.$index,kullaniciList,1)"
                                            type="text"
                                            icon="mdi mdi-play mdi-24px">
                                    </el-button>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                    </el-table>
                    <pagination :total="total" :sizes="sizes" :postPage="postPage" v-on:pagination="handlePagination"></pagination>
                </div>
            </el-col>
            <el-col :lg="6" :md="6" :sm="24" :xs="24">
                <div class="card-base card-shadow--medium demo-box bg-white animated fadeInUp">
                    <el-form status-icon :model="kullaniciForm" :rules="rulesKullanici" ref="kullaniciForm"
                             class="demo-ruleForm">
                        <div>
                            <el-row>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item label='Okul' prop="okulId">
                                        <el-select
                                                :disabled="okulKilit"
                                                size="small"
                                                v-model="kullaniciForm.okulId"
                                                style="width: 100%">
                                            <el-option v-for="item in okulList"
                                                       :key="item.okulId"
                                                       :label="item.okulAdi"
                                                       :value="item.okulId"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item label='Tip'>:
                                        <el-select
                                                size="small"
                                                v-model="kullaniciForm.tip"
                                                style="width: 100%">
                                            <!--<el-option value="0" label="Veli">Veli</el-option>
                                            <el-option value="1" label="Öğretmen">Öğretmen</el-option>-->
                                            <el-option value="2" label="Öğrenci">Öğrenci</el-option>
                                            <!--<el-option value="3" label="İdari Personel">İdari Personel</el-option>-->
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <!--
                                <el-col v-show="kullaniciForm.tip==='2'" :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item label='Veli' prop="ustKullaniciId">
                                        <el-select
                                                clearable
                                                filterable
                                                size="small"
                                                v-model="kullaniciForm.ustKullaniciId"
                                                style="width: 100%">:
                                            <el-option v-for="item in kullaniciTipList"
                                                       :key="item.kullaniciId"
                                                       :label="item.adSoyad"
                                                       :value="item.kullaniciId"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>-->
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item
                                            label='İsim Soyisim'
                                            prop="adSoyad">:
                                        <el-input size="small"
                                                  v-model="kullaniciForm.adSoyad"
                                                  placeholder='İsim Soyisim Giriniz'></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item label='T.C. No' prop="tcNo">:
                                        <el-input
                                                placeholder='T.C. No Giriniz'
                                                size="small" v-mask="'###########'"
                                                v-model="kullaniciForm.tcNo">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col v-show="kullaniciForm.tip==='2'" :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item label='Sınıf' prop="sinif">:
                                        <el-input
                                                placeholder='Sınıf Giriniz'
                                                size="small"
                                                v-model="kullaniciForm.sinif">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col v-show="kullaniciForm.tip==='2'" :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item label='Öğrenci No' prop="ogrNo">:
                                        <el-input
                                                placeholder='Öğrenci No Giriniz'
                                                size="small" v-mask="'####'"
                                                v-model="kullaniciForm.ogrNo">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col v-if="kullaniciForm.tip!=='2'" :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item label='Telefon' prop="telefon">:
                                        <el-input
                                                placeholder='Telefon No Giriniz'
                                                size="small" v-mask="'+90(###)### ## ##'"
                                                v-model="kullaniciForm.telefon">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col v-if="kullaniciForm.tip!=='2'" :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item label='E-Posta' prop="mail">:
                                        <el-input
                                                placeholder='E-Posta Giriniz'
                                                size="small" type="email"
                                                v-model="kullaniciForm.mail">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </div>
                        <el-row class="col-p">
                            <el-form-item>
                                <el-button v-if="btnVisible" size="small" v-on:click="kullaniciTanimlama('kullaniciForm')"
                                           type="primary">
                                    Kullanıcı Tanımla
                                </el-button>
                                <el-button v-else size="small" v-on:click="guncelleme('kullaniciForm')" type="primary">
                                    Kullanıcı Güncelle
                                </el-button>
                                <el-button v-if="!btnVisible" size="small" v-on:click="vazgec('kullaniciForm')">
                                    Vazgeç
                                </el-button>
                            </el-form-item>
                        </el-row>
                    </el-form>
                </div>
            </el-col>
        </el-row>
        <el-dialog
                :title='$t("src.views.apps.haberler.liste.uyari")'
                :visible.sync="dialogVisible"
                width="30%"
                center>
            <span>"{{selection["adSoyad"]}}" kullanıcısının silmek istediğinize emin misiniz?</span>
            <span slot="footer" class="dialog-footer">
                    <el-button icon="el-icon-check"
                               type="success"
                               size="mini"
                               @click="kullaniciDurumGuncelle(selection,selectionIndex,kullaniciList,0), dialogVisible = false"></el-button>
                    <el-button size="mini" type="danger" icon="el-icon-close"
                               @click="dialogVisible = false"></el-button>
                </span>
        </el-dialog>
        <el-dialog
                :title='$t("src.views.apps.haberler.liste.uyari")'
                :visible.sync="smsVisible"
                width="30%"
                center>
            <span>
                <span style="font-size: 14px; text-align: center;display: block">
                    <strong>"{{selectionSms["adSoyad"]}}"</strong> kullanıcısına mesajı göndermek istediğiniz mesajı giriniz
                </span>
                <el-input class="mt-20" placeholder="Mesaj giriniz" type="textarea" :rows="4" v-model="smsIcerik"></el-input>
            </span>
            <span slot="footer" class="dialog-footer">
                    <el-button icon="el-icon-check"
                               type="success"
                               size="mini"
                               @click="veliSmsGonder(), smsVisible = false"></el-button>
                    <el-button size="mini" type="danger" icon="el-icon-close"
                               @click="smsVisible = false"></el-button>
                </span>
        </el-dialog>
        <el-dialog
                :close-on-click-modal="false"
                :title='$t("src.views.apps.haberler.liste.uyari")'
                :visible.sync="sifreVisible"
                width="30%"
                center>
            <span>
                <span style="font-size: 14px; text-align: center;display: block">
                    <strong>{{sifreIsim}}</strong> kullanıcısı için şifre belirleyiniz
                    <br>
                    <el-input v-model="kullaniciSifre" size="small"></el-input>
                </span>
            </span>
            <span slot="footer" class="dialog-footer">
                    <el-button size="mini" type="success" icon="el-icon-check"
                               @click="sifreGonder">Onayla</el-button>
                    <el-button size="mini" type="danger" icon="el-icon-close"
                               @click="sifreVisible = false">Kapat</el-button>
                </span>
        </el-dialog>
        <el-dialog
                title='Veli ile Bağlantılı Kullanıcılar'
                :visible.sync="baglantiliKullaniciListesi"
                width="60%"
                center>
            <span>
                <span style="font-size: 14px; text-align: center;display: block">
                    <el-row>
                        <el-col :lg="24" :md="24" :sm="24" :xs="24">
                            <el-table
                                :data="veliOgrenciListesi"
                                border
                                stripe
                                style="width: 100%">
                                <el-table-column
                                        label="Sıra"
                                        type="index"
                                        width="50">
                                </el-table-column>
                                <el-table-column
                                        prop="adSoyad"
                                        label="İsim Soyisim"
                                        width="auto">
                                </el-table-column>
                                <el-table-column
                                        prop="tcNo"
                                        label="T.C. No"
                                        width="auto">
                                </el-table-column>
                                <el-table-column
                                        prop="ogrNo"
                                        label="Öğrenci No"
                                        width="150">
                                </el-table-column>
                                <el-table-column
                                        prop="sinif"
                                        label="Sınıf"
                                        width="120">
                                </el-table-column>
                                <el-table-column
                                        label="İşlem"
                                        align="right"
                                        width="120">
                                    <template slot-scope="scope">
                                        <el-tooltip content='Kullanıcının Sınavları'
                                                    :open-delay="500"
                                                    placement="bottom">
                                            <el-button
                                                    v-if="scope.row.tip === '2'"
                                                    v-on:click="kullaniciSinavListRoute(scope.row)"
                                                    type="text"
                                                    class="buttonEdit"
                                                    icon="mdi mdi-account-group mdi-24px"
                                                    style="color: #606266">
                                            </el-button>
                                        </el-tooltip>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                    </el-row>
                </span>
            </span>
            <span slot="footer" class="dialog-footer">
                    <el-button size="mini" type="danger" icon="el-icon-close"
                               @click="baglantiliKullaniciListesi = false">Kapat</el-button>
                </span>
        </el-dialog>
        <el-dialog
                title='Öğrencinin Veli Bilgileri'
                :visible.sync="ogrenciVeliVisible"
                width="30%"
                center>
            <span>
                <span style="font-size: 16px !important; display: block">
                    <el-row>
                        <el-col :lg="6" :md="6" :sm="24" :xs="24">
                            <div class="font-weight-600" style="font-size: 16px !important;">
                                Ad Soyad:
                            </div>
                            <div class="font-weight-600" style="font-size: 16px !important;">
                                Telefon No:
                            </div>
                            <div class="font-weight-600" style="font-size: 16px !important;">
                                E-Posta Adresi:
                            </div>
                        </el-col>
                        <el-col :lg="18" :md="18" :sm="24" :xs="24">
                            <div style="font-size: 16px !important;">
                                {{ogrenciVeliListesi.adSoyad}}
                            </div>
                            <div style="font-size: 16px !important;">
                                {{ogrenciVeliListesi.telefon}}
                            </div>
                            <div style="font-size: 16px !important;">
                                {{ogrenciVeliListesi.mail}}
                            </div>
                        </el-col>
                    </el-row>
                </span>
            </span>
            <span slot="footer" class="dialog-footer">
                    <el-button size="mini" type="danger" icon="el-icon-close"
                               @click="ogrenciVeliVisible = false">Kapat</el-button>
                </span>
        </el-dialog>
    </div>
</template>

<script>
    import kullaniciService from '../../../WSProvider/KullaniciService'
    import okulService from '../../../WSProvider/OkulService'
    import controllerService from '../../../WSProvider/ControllerService'
    import notification from '../../../notification'
    import Pagination from '@/components/Pagination.vue'

    let base64 = require('base64_js');
    export default {
        name: "KullaniciTanimlama",
        mounted() {
            if (localStorage.getItem("userDataBatiKoleji")) {
                this.userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
                if (this.userDataBatiKoleji.okulId) {
                    this.okulId = this.userDataBatiKoleji.okulId;
                    this.okulKilit = true;
                } else {
                    this.okulId = '';
                    this.okulKilit = false;
                }
            }
            this.getKullaniciList();
            this.getOkulList();
        },
        components: {
            Pagination
        },
        data() {
            return {
                // pagination
                sizes: 10,
                postPage: 0,
                total: null,

                userDataBatiKoleji: '',
                selectIcon: '1',
                radio: '1',
                okulKilit: false,
                okulId: '',
                search: '',
                okulList: [],
                kullaniciList: [],
                kullaniciTipList: [],
                updateData: [],
                kullaniciFormKlon: [],
                updateKodu: '',
                btnVisible: true,
                selection: '',
                selectionIndex: '',
                selectionSms: '',
                smsIcerik: '',
                smsVisible: false,
                dialogVisible: false,
                sifreVisible: false,
                sifreBilgisi: '',
                sifreIsim: '',
                kullaniciSifre: '',
                veliOgrenciListesi: [],
                baglantiliKullaniciListesi: false,
                ogrenciVeliListesi: '',
                ogrenciVeliVisible: false,
                // okulId,ustKullaniciId,tip,tcNo, ogrNo, sinif, adSoyad, telefon, mail,sifre
                kullaniciForm: {
                    okulId: '',
                    ustKullaniciId: '',
                    tip: '2',
                    tcNo: '',
                    ogrNo: '',
                    sinif: '',
                    adSoyad: '',
                    telefon: '',
                    mail: '',
                    sifre: '',
                },
                rulesKullanici: {
                    baslik: [{
                        required: true,
                        message: "Lütfen Başlık Giriniz",
                        trigger: 'blur'
                    }],
                },
            }
        },
        methods: {
            getVeliOgrenciList(val){
                kullaniciService.veliOgrenciList(val.kullaniciId).then((response) => {
                    if (response.status === 200) {
                        this.veliOgrenciListesi = response.data;
                        this.baglantiliKullaniciListesi = true;
                    } else {
                        notification.Status(response.status, this);
                    }
                });
            },
            getOgrenciVeliList(val){
                kullaniciService.ogrenciVeliList(val.kullaniciId).then((response) => {
                    if (response.status === 200) {
                        this.ogrenciVeliListesi = response.data;
                        this.ogrenciVeliVisible = true
                    } else {
                        notification.Status(response.status, this);
                    }
                });
            },
            sifreOlustur(val){
                this.sifreIsim = val.adSoyad;
                this.sifreVisible = true;
                this.sifreBilgisi = val.kullaniciId;
            },
            sifreGonder(){
                kullaniciService.sifreOlustur(this.sifreBilgisi,this.kullaniciSifre).then((response) => {
                    if (response.status === 200) {
                        this.sifreVisible = false;
                        notification.Status('success', this, 'Kullanıcı şifresi başarıyla değiştirildi!');
                    } else {
                        notification.Status(response.status, this);
                    }
                });
            },
            kullaniciSinavListRoute(val) {
                this.$store.commit('changeOgrenciId', val.kullaniciId);
                this.$router.push({name: 'kullanici-sinavlar'})
            },
            handlePagination(sizes, postPage) {
                this.sizes = sizes;
                this.postPage = postPage;
                this.getKullaniciList();
            },
            clearable() {
                this.kullaniciList = [];
                this.getKullaniciList();
            },
            searchMethod(val) {
                if (val !== '') {
                    var dizi = [];
                    kullaniciService.kullaniciArama(val,this.okulId).then((response) => {
                        if (response.status === 200) {
                            response.data.forEach(function (e) {
                                dizi.push(e);
                            });
                            this.kullaniciList = dizi;
                        } else notification.Status(response.status, this)
                    })
                } else this.clearable();
            },
            veliSmsGonder() {
                kullaniciService.smsGonder(this.selectionSms.telefon, this.smsIcerik).then((response) => {
                    if (response.status === 200) {
                        this.selectionSms = '';
                        this.smsIcerik = '';
                        notification.Status('success', this, 'Mesaj kullanıcıya başarıyla gönderildi.');
                    } else {
                        notification.Status(response.status, this)
                    }
                });
            },
            filterTag(value, row) {
                return row.tip === value;
            },
            handleChange(event) {
                this.selectIcon = event;
                this.getKullaniciList();
            },
            getOkulList() {
                okulService.okulListesi(this.selectIcon, "", "").then((response) => {
                    if (response.status === 200) {
                        this.okulList = response.data;
                        if (this.okulList.length > 0) {
                            if (this.okulId !== '') {
                                this.kullaniciForm.okulId = this.okulId;
                            } else {
                                this.kullaniciForm.okulId = response.data[0].okulId;
                            }
                        }
                    } else {
                        notification.Status(response.status, this)
                    }
                });
            },
            getKullaniciList() {
                let self = this;
                if (!this.userDataBatiKoleji.tip) {
                    kullaniciService.kullaniciListesi(this.selectIcon, this.postPage,this.sizes, this.okulId).then((response) => {
                        if (response.status === 200) {
                            this.kullaniciList = response.data;
                        } else {
                            notification.Status(response.status, this)
                        }
                    });
                } else {
                    kullaniciService.mudurkullaniciList(this.selectIcon, this.postPage,this.sizes).then((response) => {
                        if (response.status === 200) {
                            this.kullaniciList = response.data;
                        } else {
                            notification.Status(response.status, this)
                        }
                    });
                }
            },
            getKullaniciTipListesi(val) {
                if (val === '2') {
                    if (!this.userDataBatiKoleji.tip) {
                        kullaniciService.kullaniciTipListesi(this.selectIcon, "", "", 0).then((response) => {
                            if (response.status === 200) {
                                this.kullaniciTipList = response.data;
                            } else {
                                notification.Status(response.status, this)
                            }
                        });
                    } else {
                        kullaniciService.mudurkullaniciTipList(this.selectIcon, "", "", 0).then((response) => {
                            if (response.status === 200) {
                                this.kullaniciTipList = response.data;
                            } else {
                                notification.Status(response.status, this)
                            }
                        });
                    }
                }
            },
            updateStart(scope) {
                this.kullaniciForm.adSoyad = scope.adSoyad;
                this.kullaniciForm.tip = scope.tip;
                this.kullaniciForm.tcNo = scope.tcNo;
                this.kullaniciForm.telefon = scope.telefon;
                this.kullaniciForm.mail = scope.mail;
                this.kullaniciForm.ustKullaniciId = scope.ustKullaniciId;
                this.kullaniciForm.sinif = scope.sinif;
                this.kullaniciForm.ogrNo = scope.ogrNo;
                this.kullaniciForm.okulId = scope.okulId;
                this.updateKodu = scope.kullaniciId;
                this.kullaniciFormKlon = JSON.stringify(scope)
                this.btnVisible = false;
            },
            vazgec(formName) {
                this.$refs[formName].resetFields();
                this.btnVisible = true;
            },
            kullaniciTanimlama(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (!this.userDataBatiKoleji.tip) {
                        kullaniciService.kullaniciTanimlama(this.kullaniciForm.okulId, this.kullaniciForm.ustKullaniciId, this.kullaniciForm.tip, this.kullaniciForm.tcNo, this.kullaniciForm.ogrNo, this.kullaniciForm.sinif, this.kullaniciForm.adSoyad, this.kullaniciForm.telefon, this.kullaniciForm.mail, this.kullaniciForm.sifre).then((response) => {
                            if (response.status === 200) {
                                this.getKullaniciList();
                                notification.Status('success', this, 'Kullanıcı başarıyla eklendi.');
                                this.$refs[formName].resetFields();
                                this.kullaniciForm.link = '';
                            } else if (response.status === 230) {
                                notification.Status('warning', this, 'Bu bilgiler ile kayıtlı bir kullanıcı zaten mevcut');
                            } else {
                                notification.Status(response.status, this)
                            }
                        });
                        } else {
                            kullaniciService.mudurkullaniciTanimlama(this.kullaniciForm.ustKullaniciId, this.kullaniciForm.tip, this.kullaniciForm.tcNo, this.kullaniciForm.ogrNo, this.kullaniciForm.sinif, this.kullaniciForm.adSoyad, this.kullaniciForm.telefon, this.kullaniciForm.mail, this.kullaniciForm.sifre).then((response) => {
                                if (response.status === 200) {
                                    this.getKullaniciList();
                                    notification.Status('success', this, 'Kullanıcı başarıyla eklendi.');
                                    this.$refs[formName].resetFields();
                                    this.kullaniciForm.link = '';
                                } else if (response.status === 230) {
                                    notification.Status('warning', this, 'Bu bilgiler ile kayıtlı bir kullanıcı zaten mevcut');
                                } else {
                                    notification.Status(response.status, this)
                                }
                            });
                        }
                    }
                })
            },
            guncelleme(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        for (var key in JSON.parse(this.kullaniciFormKlon)) {
                            if (JSON.parse(this.kullaniciFormKlon)[key] !== this.kullaniciForm[key]) {
                                if (this.kullaniciForm[key] || this.kullaniciForm[key] === '') {
                                    var item = {
                                        type: key,
                                        value: this.kullaniciForm[key]
                                    };
                                    this.updateData.push(item);
                                }
                            }

                        }
                        if (!this.userDataBatiKoleji.tip) {
                            kullaniciService.kullaniciGuncelleme(this.updateKodu,this.updateData).then((response) => {
                                if (response.status === 200) {
                                    notification.Status('success', this, 'Kullanıcı başarıyla güncellendi...');
                                    this.$refs[formName].resetFields();
                                    this.btnVisible = true;
                                    this.updateData=[];
                                    this.getOkulList();
                                    this.getKullaniciList()
                                } else if (response.status === 200) {
                                    notification.Status('warning', this, 'Bu bilgiler ile kayıtlı bir kullanıcı zaten mevcut');
                                } else {
                                    notification.Status(response.status, this)
                                }
                            });
                        } else {
                            kullaniciService.mudurkullaniciGuncelle(this.updateKodu,this.updateData).then((response) => {
                                if (response.status === 200) {
                                    notification.Status('success', this, 'Kullanıcı başarıyla güncellendi...');
                                    this.$refs[formName].resetFields();
                                    this.btnVisible = true;
                                    this.updateData=[];
                                    this.getOkulList();
                                    this.getKullaniciList()
                                } else if (response.status === 200) {
                                    notification.Status('warning', this, 'Bu bilgiler ile kayıtlı bir kullanıcı zaten mevcut');
                                } else {
                                    notification.Status(response.status, this)
                                }
                            });
                        }
                    }
                })
            },
            kullaniciDurumGuncelle(selection, index, list, durum) {
                if (!this.userDataBatiKoleji.tip) {
                    kullaniciService.kullaniciDurumGuncelleme(selection.kullaniciId, durum).then((response) => {
                        if (response.status === 200) {
                            notification.Status('success', this, 'Kullanıcı durumu değiştirildi.');
                            list.splice(index, 1);
                        } else {
                            notification.Status(response.status, this)
                        }
                    })
                } else {
                    kullaniciService.mudurkullaniciDurumGuncelleme(selection.kullaniciId, durum).then((response) => {
                        if (response.status === 200) {
                            notification.Status('success', this, 'Kullanıcı durumu değiştirildi.');
                            list.splice(index, 1);
                        } else {
                            notification.Status(response.status, this)
                        }
                    })
                }
            },
        }
    }

</script>


<style lang="scss" scoped>
    .dangerbtn {
        color: #ec205f;
        position: absolute;
        bottom: -12px;
        right: -24px;
    }

    .successbtn {
        color: #13ce66;
        position: absolute;
        top: -12px;
        right: -24px;
    }

    .tabloFirstChild {
        position: fixed;
    }
</style>
