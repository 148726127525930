<template>
    <div class="page-table mainDiv scrollable" id="affix-container">
        <div class="card-base baslikBg animated fadeInUp">
           Okul İşlemleri
        </div>
        <el-row :gutter="10">
            <el-col :lg="18" :md="18" :sm="24" :xs="24">
                <div class="page-header card-base card-shadow--medium animated fadeInUp">
                    <el-row class="mt-0 mb-10">
                        <el-col :lg="16" :md="16" :sm="24" :xs="24">
                            <div>
                                <el-radio-group size="small" v-on:change="handleChange($event)" v-model="radio" fill="#E5F5F9"
                                                text-color="gray">
                                    <el-radio-button label="1">
                                        <i class="mdi mdi-page-next mdi-18px" style="color: #13ce66; margin-right: 5px"></i>
                                        <transition
                                                name="slide-fade">
                                            <label v-if="selectIcon==='1'">Yayındaki Okullar</label>
                                        </transition>
                                    </el-radio-button>
                                    <el-radio-button label="0">
                                        <i class="mdi mdi-page-next mdi-18px" style="color: #ec205f; margin-right: 5px"></i>
                                        <transition
                                                name="slide-fade">
                                            <label v-if="selectIcon==='0'">Pasif Okullar</label>
                                        </transition>
                                    </el-radio-button>
                                </el-radio-group>
                            </div>
                        </el-col>
                        <el-col :lg="8" :md="8" :sm="24" :xs="24">
                            <el-col :lg="24" :md="24" :sm="24" :xs="24">
                                <ul class="islemler">
                                    <li class="duzenle">
                                        <i class="mdi mdi-pen"></i> Düzenle
                                    </li>
                                    <li v-if="selectIcon === '1'" class="sil">
                                        <i class="mdi mdi-trash-can"></i> Sil
                                    </li>
                                    <li v-else class="aktif">
                                        <i class="mdi mdi-play"></i> Yayına Al
                                    </li>
                                </ul>
                            </el-col>
                        </el-col>
                    </el-row>
                    <el-table
                            border
                            stripe
                            :data="okulList"
                            style="width: 100%">
                        <el-table-column
                                label="Sıra"
                                type="index"
                                width="50">
                        </el-table-column>
                        <el-table-column
                                prop="okulAdi"
                                label="Okul Adı"
                                width="auto">
                        </el-table-column>
                        <el-table-column
                                prop="telefon"
                                label="Telefon"
                                width="150">
                        </el-table-column>
                        <el-table-column
                                prop="adres"
                                label="Adres"
                                width="auto">
                        </el-table-column>
                        <el-table-column
                                label="İşlem"
                                align="right"
                                width="150">
                            <template slot-scope="scope">
                                <el-tooltip :content='$t("src.views.apps.haberler.kategori.liste.duzenle")'
                                            :open-delay="500"
                                            placement="bottom">
                                    <el-button
                                            v-on:click="selectionIndex=scope.$index; selection=scope.row; updateStart(scope.row); "
                                            type="text"
                                            class="buttonEdit"
                                            icon="mdi mdi-pen mdi-24px">
                                    </el-button>
                                </el-tooltip>
                                <el-tooltip :content='$t("src.views.apps.haberler.kategori.liste.sil")'
                                            :open-delay="500"
                                            placement="bottom">
                                    <el-button
                                            v-if="selectIcon==='1'"
                                            v-on:click="dialogVisible = true; selection=scope.row; selectionIndex=scope.$index"
                                            type="text"
                                            class="buttonDel"
                                            icon="mdi mdi-trash-can mdi-24px">
                                    </el-button>
                                </el-tooltip>
                                <el-tooltip :content='$t("src.views.apps.sayfa.liste.yayindanKaldir")' :open-delay="500"
                                            placement="bottom">
                                    <el-button
                                            v-if="selectIcon==='0' "
                                            @click="okulDurumGuncelle(scope.row,scope.$index,okulList,1)"
                                            type="text"
                                            icon="mdi mdi-play mdi-24px">
                                    </el-button>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-col>
            <el-col :lg="6" :md="6" :sm="24" :xs="24">
                <div class="card-base card-shadow--medium demo-box bg-white animated fadeInUp">
                    <el-form status-icon :model="okulForm" :rules="rulesOkul" ref="okulForm"
                             class="demo-ruleForm">
                        <div>
                            <el-row>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item
                                            label='Okul Adı'
                                            prop="okulAdi">:
                                        <el-input size="small"
                                                  v-model="okulForm.okulAdi"
                                                  placeholder='Okul Adı Giriniz'></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item
                                            label='Telefon'
                                            prop="telefon">:
                                        <el-input size="small"  v-mask="'+90(###)### ## ##'"
                                                  v-model="okulForm.telefon"
                                                  placeholder='Telefon Giriniz'></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item
                                            label='Adres'
                                            prop="adres">:
                                        <el-input size="small" type="textarea"
                                                  v-model="okulForm.adres"
                                                  :rows="3"
                                                  placeholder='Adres Giriniz'></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item
                                            label='Harita'
                                            prop="iframe">:
                                        <el-input size="small" type="textarea"
                                                  v-model="okulForm.iframe"
                                                  :rows="3"
                                                  placeholder='Harita Giriniz'></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </div>
                        <el-row class="col-p">
                            <el-form-item>
                                <el-button v-if="btnVisible" size="small" v-on:click="okulTanimlama('okulForm')"
                                           type="primary">
                                    Okul Tanımla
                                </el-button>
                                <el-button v-else size="small" v-on:click="guncelleme('okulForm')" type="primary">
                                    Okul Güncelle
                                </el-button>
                                <el-button v-if="!btnVisible" size="small" v-on:click="vazgec('okulForm')">
                                    Vazgeç
                                </el-button>
                            </el-form-item>
                        </el-row>
                    </el-form>
                </div>
            </el-col>
        </el-row>
        <el-dialog
                :title='$t("src.views.apps.haberler.liste.uyari")'
                :visible.sync="dialogVisible"
                width="30%"
                center>
            <span>"{{selection["okulAdi"]}}" okulunu silmek istediğinize emin misiniz?</span>
            <span slot="footer" class="dialog-footer">
                    <el-button icon="el-icon-check"
                               type="success"
                               size="mini"
                               @click="okulDurumGuncelle(selection,selectionIndex,okulList,0), dialogVisible = false"></el-button>
                    <el-button size="mini" type="danger" icon="el-icon-close"
                               @click="dialogVisible = false"></el-button>
                </span>
        </el-dialog>
    </div>
</template>

<script>
    import okulService from '../../../WSProvider/OkulService'
    import controllerService from '../../../WSProvider/ControllerService'
    import notification from '../../../notification'
    import functions from "../../../functions";
    let base64 = require('base64_js');
    export default {
        name: "OkulTanimlama",
        mounted() {
            if (localStorage.getItem("userDataBatiKoleji")) {
                this.userDataBatiKoleji = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBatiKoleji"))));
            }
            this.getOkulList();
        },
        data() {
            return {
                userDataBatiKoleji : '',
                selectIcon: '1',
                radio: '1',
                okulList: [],
                updateKodu: '',
                btnVisible: true,
                selection: '',
                selectionIndex: '',
                dialogVisible: false,
                // okulAdi, telefon,adres,iframe
                okulForm: {
                    okulAdi: '',
                    telefon: '',
                    adres: '',
                    iframe: ''

                },
                rulesOkul: {
                    okulAdi: [{
                        required: true,
                        message: "Lütfen Okul Adı Giriniz",
                        trigger: 'blur'
                    }],
                },
            }
        },
        methods: {
            handleChange(event) {
                this.selectIcon = event;
                this.getOkulList();
            },
            getOkulList() {
                let self = this;
                okulService.okulListesi(this.selectIcon,"","").then((response) => {
                    if (response.status === 200) {
                        this.okulList = response.data;
                    } else {
                        notification.Status(response.status, this)
                    }
                });
            },
            updateStart(scope) {
                this.okulForm.okulAdi = scope.okulAdi;
                this.okulForm.telefon = scope.telefon;
                this.okulForm.adres = scope.adres;
                this.okulForm.iframe = functions.htmlDecode(scope.iframe);
                this.updateKodu = scope.okulId;
                this.btnVisible = false;
            },
            vazgec(formName) {
                this.$refs[formName].resetFields();
                this.btnVisible = true;
            },
            okulTanimlama(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        okulService.okulTanimlama(this.okulForm.okulAdi, this.okulForm.telefon, this.okulForm.adres,this.okulForm.iframe).then((response) => {
                            if (response.status === 200) {
                                this.okulList.push(response.data);
                                notification.Status('success', this, 'Okul başarıyla eklendi.');
                                this.$refs[formName].resetFields();
                            } else if (response.status === 230) {
                                notification.Status('warning', this, 'Bu bilgiler ile kayıtlı bir okul zaten mevcut');
                            } else {
                                notification.Status(response.status, this)
                            }
                        });
                    }
                })
            },
            guncelleme(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        okulService.okulGuncelleme(this.updateKodu, this.okulForm.okulAdi, this.okulForm.telefon, this.okulForm.adres,this.okulForm.iframe).then((response) => {
                            if (response.status === 200) {
                                this.okulList.splice(this.selectionIndex, 1);
                                this.okulList.splice(this.selectionIndex, 0, response.data);
                                notification.Status('success', this, 'Okul başarıyla güncellendi...');
                                this.$refs[formName].resetFields();
                                this.btnVisible = true;
                            } else if (response.status === 200) {
                                notification.Status('warning', this, 'Bu bilgiler ile kayıtlı bir okul zaten mevcut');
                            } else {
                                notification.Status(response.status, this)
                            }
                        });
                    }
                })
            },
            okulDurumGuncelle(selection, index, list, durum) {
                okulService.okulDurumGuncelle(selection.okulId, durum).then((response) => {
                    if (response.status === 200) {
                        notification.Status('success', this, 'Okul durumu değiştirildi.');
                        list.splice(index, 1);
                    } else {
                        notification.Status(response.status, this)
                    }
                })
            },
        }
    }

</script>


<style lang="scss" scoped>
    .dangerbtn {
        color: #ec205f;
        position: absolute;
        bottom: -12px;
        right: -24px;
    }

    .successbtn {
        color: #13ce66;
        position: absolute;
        top: -12px;
        right: -24px;
    }

    .tabloFirstChild {
        position: fixed;
    }
</style>
