import Vue from 'vue'
import CxltToastr from 'cxlt-vue2-toastr'
import 'cxlt-vue2-toastr/dist/css/cxlt-vue2-toastr.css'
import router from './router/index'
import store from './store'

var toastrConfigs = {
    position: 'bottom-right',
    showMethod : 'headShake',
    hideMethod : 'slideOutRight',
};
Vue.use(CxltToastr, toastrConfigs);

var durum = {
    Status: function (message, self, text) {
        // console.log(message);
        switch (message) {
            case 'success':
                self.$toast.success({
                    title: 'Başarılı',
                    message: text,
                    timeOut: 3000,
                });
                break;
            case 'warning':
                self.$toast.warn({
                    title: 'Uyarı!',
                    message: text,
                    timeOut: 5000,
                });
                break;
            case 100:
                self.$toast.warn({
                    title: 'Uyarı!',
                    message: 'Bu işlem için yetkiniz bulunmamaktadır!',
                    timeOut: 5000,
                });
                break;
            case 150:
                self.$toast.info({
                    title: 'Bilgilendirme',
                    message: 'Token Hatası! Login Sayfasına Yönlendiriliyorsunuz!',
                    timeOut: 5000,
                });
                store.commit('setLogout');
                router.push("/login");
                localStorage.removeItem("userDataBatiKoleji");
                break;
            case 210:
                self.$toast.warn({
                    title: 'Uyarı!',
                    message: 'Kullanıcı Adı yada Şifre hatalı!',
                    timeOut: 5000,
                });
                break;
            case 250:
                self.$toast.warn({
                    title: 'Uyarı!',
                    message: 'Geçersiz İşlem!',
                    timeOut: 5000,
                });
                break;
            case 270:
                self.$toast.warn({
                    title: 'Uyarı!',
                    message: 'İşlem yapmak istediğiniz veri pasif!',
                    timeOut: 5000,
                });
                break;
            case 280:
                self.$toast.warn({
                    title: 'Uyarı!',
                    message: 'İşlem yapmak istediğiniz link aynı!',
                    timeOut: 5000,
                });
                break;
            case 305:
                self.$toast.error({
                    title: 'Hata Kodu: 305!',
                    message: 'Eksik Veri!',
                    timeOut: 5000,
                });
                break;
            case 310:
                self.$toast.warn({
                    title: 'Uyarı!',
                    message: 'Link güncelleme esnasında hata oluştu!',
                    timeOut: 5000,
                });
                break;
            case 320:
                self.$toast.warn({
                    title: 'Uyarı!',
                    message: 'SEO güncelleme esnasında hata oluştu!',
                    timeOut: 5000,
                });
                break;
            case 330:
                self.$toast.warn({
                    title: 'Uyarı!',
                    message: 'Resim güncelleme esnasında hata oluştu!',
                    timeOut: 5000,
                });
                break;
            case 340:
                self.$toast.warn({
                    title: 'Uyarı!',
                    message: 'Belge güncelleme esnasında hata oluştu!',
                    timeOut: 5000,
                });
                break;
            case 350:
                self.$toast.warn({
                    title: 'Uyarı!',
                    message: 'Dil güncelleme esnasında hata oluştu!',
                    timeOut: 5000,
                });
                break;
            case 360:
                self.$toast.warn({
                    title: 'Uyarı!',
                    message: 'Herhangi bir değişiklik yapılmadı!',
                    timeOut: 5000,
                });
                break;
            case 380:
                self.$toast.warn({
                    title: 'Uyarı!',
                    message: 'İşlem yapmak istediğiniz veri başka bir yerde kullanılıyor!',
                    timeOut: 5000,
                });
                break;
            case 400:
                self.$toast.error({
                    title: 'Hata Kodu: 400!',
                    message: 'Kullanıcı Bulunamadı',
                    timeOut: 10000,
                });
                break;
            case 410:
                self.$toast.error({
                    title: 'Hata Kodu: 410!',
                    message: 'Veri Bulunamadı',
                    timeOut: 10000,
                });
                break;
            case 404:
                self.$toast.error({
                    title: 'Hata Kodu: 404!',
                    message: 'Method Bulunamadı',
                    timeOut: 10000,
                });
                break;
            case 503:
                self.$toast.error({
                    title: 'Hata Kodu: 503!',
                    message: 'İşlem sırasında bir hata oluştu',
                    timeOut: 10000,
                });
                break;
        }

    },

}
export default durum;
