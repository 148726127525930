<template>
    <div>
        <el-pagination
                background
                layout="sizes,prev, pager, next, jumper, total"
                :total="total"
                @size-change="handleSizeChange"
                @current-change="pageChange"
                :page-sizes="[10, 20, 50, 100]"
                :current-page.sync="page"
                :next-click.sync="page"
                :prev-click.sync="page"
                @next-click="pageChange"
                @prev-click="pageChange">
        </el-pagination>
    </div>
</template>

<script>
    export default {
        name: "Pagination",
        props: ['total'],
        data(){
            return{
                sizes: 10,
                postPage: 0,
                page: 0,
            }
        },
        methods: {
            handleSizeChange(val) {
                this.sizes = val;
                this.$emit('pagination',this.sizes,this.postPage)
            },
            pageChange(val) {
                this.postPage = (val - 1) * this.sizes;
                this.$emit('pagination',this.sizes,this.postPage)
            },

        }
    }
</script>

<style lang="scss" scoped>
    .el-pagination {
        margin-top: 20px;
        text-align: right;
    }
</style>
